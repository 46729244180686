import React from 'react';

import ResourceList from '../ResourceList';

import { all } from '../../customization/list';

const All: React.FC = () => {
  return <ResourceList list={all} />;
};

export default React.memo(All);
