import _findLast from 'lodash/findLast';

type BreakpointMapping = {
  [minWindowWidth: number]: /** unit (nb thumbs) */ number;
};
const breakpointMapping: BreakpointMapping = {
  0: 1.1,
  290: 1.5, // Only usefull when sizeMultiplier makes it >= 2
  340: 2,
  535: 3,
  765: 4,
  1000: 5,
  1225: 6,
};
const breakpoints = Object.keys(breakpointMapping)
  .map(b => +b)
  .sort((a, b) => a - b);

type Size = 'small' | 'medium' | 'big';
const sizeMultiplier: { [k in Size]: number } = {
  small: 0.65,
  medium: 1,
  big: 1.4,
};

// eslint-disable-next-line import/prefer-default-export
export function getUnitsPerRow(
  width: number,
  size: Size = 'medium',
  { leaking = false } = {},
): number {
  // Get biggest matching breakpoint
  const breakpoint = _findLast(breakpoints, b => b <= width) ?? breakpoints[0];

  let unit = breakpointMapping[breakpoint];
  // const mediumUnit = unit;

  unit /= sizeMultiplier[size];
  unit = Math.max(Math.round(unit), 1); // Round for clean display, Min 1

  if (leaking) {
    unit += 0.2;
  }

  // const baseUnit = width / unit;
  // const baseMediumUnit = width / mediumUnit;

  // return { unit, baseUnit, baseMediumUnit };
  return unit;
}

// export function getBaseFontSizeFromBaseUnit(baseUnit) {
//   return baseUnit / 18;
// }
