import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FRAGMENT_FULL_SUFFIX, QueryNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Post as PostType } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import Description from '../Video/Description';
import { getFontAwesomeIconFromMimeType } from '../../utils/fontawesome';
import config from '../../config';
import { getPosterPreserveGif } from '../../utils/poster';

const MAX_HEIGHT = 600;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    playerBloc: {
      display: 'flex',
      flexFlow: 'row nowrap',
      '& > *': {
        flex: '1 1 0%',
      },
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        '& > *': {
          flex: '0 0 auto',
        },
      },
    },
    player: {
      zIndex: 1,
      alignSelf: 'center',
      flexGrow: 0.8,
      margin: 0,
      transition: theme.transitions.create('margin'),
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        margin: '0 10vw',
        flexGrow: 0,
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },

    imageWrapper: {
      cursor: 'pointer',
      maxHeight: MAX_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    imageWrapperCannotDownload: {
      cursor: 'default',
      // pointerEvents: 'none', // Could be a solution but actually make right click download whole page and that includes the image
    },
    image: {
      maxWidth: '100%',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      height: 'auto',
      maxHeight: MAX_HEIGHT,
    },

    iconWrapper: {
      position: 'relative',
      padding: `0 0 ${100 * config.portraitRatio}%`, // Sets the image size
      overflow: 'hidden',
    },
    iconBackground: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
    },
    icon: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      width: '100% !important',
      height: '60%',
      color: theme.palette.augmentColor({
        main: theme.palette.primary.main,
      }).dark,
    },

    description: {
      zIndex: 1,
    },
  }),
);

const Post: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionPost;

  const {
    query: { name: queryName },
    fragments: { full: fragmentFull, name: fragmentName },
  } = resourceInfos;

  const { data, error } = useQuery<QueryNodes<PostType>>(gql`
    query {
      ${queryName}(id: "${id}")  {
        ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
      }
    }
    ${fragmentFull}
  `);
  if (error) {
    console.error(`Error querying post #${id} ${error.message}`);
  }

  const post = data?.[queryName];

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const handleToggleLightbox = useCallback(() => {
    setLightboxIsOpen(!lightboxIsOpen);
  }, [lightboxIsOpen]);

  const [poster, setPoster] = useState<string>();
  useEffect(() => {
    if (post) {
      getPosterPreserveGif(post, {
        height: MAX_HEIGHT,
        portrait: true,
        contain: true,
      }).then(url => {
        if (url) {
          setPoster(url);
        }
      });
    }
  }, [post]);

  const [bigImage, setBigImage] = useState<string>();
  useEffect(() => {
    if (post) {
      getPosterPreserveGif(post).then(url => {
        if (url) {
          setBigImage(url);
        }
      });
    }
  }, [post]);

  const canDownload = useMemo(() => !!post?.download, [post]);
  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!canDownload) event.preventDefault();
    },
    [canDownload],
  );

  if (!post) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.playerBloc}>
        {post && (
          <>
            <div className={classes.player}>
              {poster ? (
                <>
                  <div
                    role="button"
                    onClick={handleToggleLightbox}
                    onKeyPress={handleToggleLightbox}
                    tabIndex={-1}
                    className={clsx(classes.imageWrapper, {
                      [classes.imageWrapperCannotDownload]: !canDownload,
                    })}
                    onContextMenu={handleContextMenu}
                  >
                    <img
                      src={poster}
                      alt="poster"
                      className={classes.image}
                      draggable={canDownload}
                    />
                  </div>
                  {bigImage && lightboxIsOpen && canDownload && (
                    <Lightbox
                      mainSrc={bigImage}
                      onCloseRequest={handleToggleLightbox}
                      imagePadding={50}
                      reactModalStyle={{
                        overlay: { zIndex: theme.zIndex.modal },
                      }}
                    />
                  )}
                </>
              ) : (
                <div
                  className={clsx(classes.iconWrapper, classes.iconBackground)}
                >
                  <FontAwesomeIcon
                    icon={getFontAwesomeIconFromMimeType(
                      post.attachments.edges[0]?.node.type ?? '',
                    )}
                    className={classes.icon}
                  />
                </div>
              )}
            </div>
            <Description resource={post} className={classes.description} />
          </>
        )}
      </div>
      {/* <Suggestions />
          <Comments nid={nid} /> */}
    </div>
  );
};

export default React.memo(Post);
