import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _compact from 'lodash/compact';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  FRAGMENT_FULL_SUFFIX,
  Level,
  QueryAllNodes,
  Status,
} from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import {
  TagEdge,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { MapSelector as GeolibMapSelector, TrackInfos } from 'traction-geolib';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import map from '../../customization/map';
import config from '../../config';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: `rgba(255, 255, 255, 0.5)`,
    },
    mapSelector: {
      width: '100%',
      height: '100%',

      '& .mapboxgl-popup-content': {
        color: theme.palette.text.contrastText,
        padding: theme.spacing(1),
      },
    },
  }),
);

const MapAllLines: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;

  const {
    query: { allName: queryName },
    fragments: { full: fragmentFull, name: fragmentName },
  } = resourceInfos;

  const { data } = useQuery<QueryAllNodes<Video>>(
    gql`
    query {
      ${queryName} {
        edges {
          node {
            ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
          }
        }
      }
    }
    ${fragmentFull}
  `,
    { fetchPolicy: 'cache-only' },
  );

  const handleMapSelectorClick = useCallback(
    (videoKey: NonNullable<Video['videoKey']>) => {
      const video = data?.[queryName].edges?.find(
        v => v.node.videoKey === videoKey,
      )?.node;
      if (video) {
        history.push(`${location.pathname}/${video.id}`);
      }
    },
    [data, history, location.pathname, queryName],
  );

  const videosKeys: TrackInfos[] = useMemo(
    () =>
      _compact(
        data?.[queryName].edges?.reduce<TrackInfos[]>(
          (acc, { node: { videoKey, tags, title, status } }) => {
            const tagEdges = tags.edges as TagEdge[];
            if (
              videoKey &&
              tagEdges.some(({ node }) => node.id === map.tagId) &&
              (config.environment.level !== Level.PROD ||
                status === Status.PUBLISHED)
            ) {
              acc.push([
                videoKey,
                tagEdges.some(
                  ({ node }) =>
                    node.id === '5fc664d4-3a61-4ac2-8a06-a1e4fb5083e6', // Étude de ligne
                )
                  ? 'rgb(1, 126, 204)'
                  : 'red',
                title,
              ]);
            }
            return acc;
          },
          [] as TrackInfos[],
        ) ?? [],
      ),
    [data, queryName],
  );

  return (
    <div className={classes.root}>
      <GeolibMapSelector
        vids={videosKeys}
        onClick={handleMapSelectorClick}
        className={classes.mapSelector}
      />
    </div>
  );
};

export default React.memo(MapAllLines);
