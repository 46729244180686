import _mergeWith from 'lodash/mergeWith';
import { Shadows } from '@material-ui/core/styles/shadows';

import { Classes } from '../types/styles';

export const createInsetShadowsFrom = (shadows: Shadows): Shadows =>
  shadows
    .filter(s => typeof s === 'string')
    .map(shadow => {
      if (shadow === 'none') return 'none';
      const matches = shadow.match(/[\w\s-#]+(\([\d,.]+\))?/g);
      if (matches) {
        return `inset ${matches.join(',inset ')}`;
      }
      return ''; // Should never occur
    }) as Shadows;

export const mergeClasses = (
  classes: Classes,
  otherClasses: Classes,
): Classes =>
  _mergeWith(
    classes,
    otherClasses,
    (objValue: string, srcValue: string) => `${objValue} ${srcValue}`,
  );
