import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => {
  return createStyles({
    contentWrapper: {
      flexGrow: 1,
      padding: theme.spacing(0, 1),
      paddingTop: theme.shape.topPadding ?? 0,
      transition: theme.transitions.create(['background-color', 'padding-top']),
    },
    noPaddingTopWrapper: {
      paddingTop: 0,
    },
    mapWrapper: {
      padding: 0,
      display: 'flex',
      transition: theme.transitions.create('background-color'), // Fix map rendering issue (map background cropped)
    },
    fullscreenWrapper: {
      flex: '1 1 auto',
      position: 'relative',
      overflowX: 'hidden',
      overflowY: 'auto',

      display: 'flex',
      flexFlow: 'column',
    },

    content: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.values.lg,
      paddingBottom: theme.spacing(5),
    },
    mapContent: {
      flexGrow: 1,
      maxWidth: 'unset',
      padding: 0,
    },
    fullscreen: {
      flex: '1 0 100%',
      paddingBottom: 0,

      display: 'flex',
      flexFlow: 'column',
      '& > *': {
        flex: '1 0 100%',
      },
    },
  });
});

type ContentWrapperProps = React.PropsWithChildren<{
  noPaddingTop?: boolean;
  map?: boolean;
  fullscreen?: boolean;
}>;

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  noPaddingTop = false,
  map = false,
  fullscreen = false,
  children,
}: ContentWrapperProps) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.contentWrapper, {
        [classes.noPaddingTopWrapper]: noPaddingTop,
        [classes.mapWrapper]: map,
        [classes.fullscreenWrapper]: fullscreen,
      })}
    >
      <div
        className={clsx(classes.content, {
          [classes.mapContent]: map,
          [classes.fullscreen]: fullscreen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(ContentWrapper, (prevProps, nextProps) => {
  return (
    prevProps.noPaddingTop === nextProps.noPaddingTop &&
    prevProps.map === nextProps.map
  );
});
