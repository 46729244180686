// import React from 'react';
import { Theme } from '@material-ui/core';

// import SelectIcon from './themeComponents/SelectIcon';
// import ExpandIcon from './themeComponents/ExpandIcon';

import {
  primaryColor,
  secondaryColor,
  textColor,
  backgroundColor,
  commonColors,
  specialColors,
  textColorsMixin,
  stateColors,
} from './colors';

import { getTheme } from '../../index';

// import withTheme from './themeComponents/withTheme';

// Avenir font and SNCF icons
import './styles.css';

const getSncfTheme = (): Theme => {
  const SPACING = 8;
  const BORDER_RADIUS = 7;
  const FONT_SIZE = 16;

  const textColors = textColorsMixin(textColor);

  const theme = getTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 1024,
          xl: 1280,
        },
        widths: {
          xs: 310,
          sm: 508,
          md: 696,
          lg: 1000,
          xl: 1044,
        },
      },
      palette: {
        primary: {
          // light and dark entries, if not set, will be generated
          main: primaryColor,
          contrastText: commonColors.white,
        },
        secondary: {
          // light and dark entries, if not set, will be generated
          main: secondaryColor,
          contrastText: commonColors.white,
        },
        common: {
          ...commonColors,
        },
        background: {
          default: backgroundColor,
          white: commonColors.white,
        },
        text: {
          ...textColors,
        },
        specials: {
          ...specialColors,
        },
        ...stateColors,
      },
      typography: {
        fontFamily: 'Avenir',
        fontSize: FONT_SIZE,
      },
      spacing: SPACING,
    },
    {
      withRobotoFont: false,
    },
  );
  theme.mixins = {
    ...(theme.mixins || {}),
    toolbar: {
      [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
        height: 48,
      },
      [theme.breakpoints.down('xs')]: {
        height: 56,
      },
      height: 64,
    },
    navbar: {
      [theme.breakpoints.down('xs')]: {
        height: 0,
        overflow: 'hidden',
      },
      height: 40,
    },
  };
  theme.shape = {
    ...(theme.shape || {}),
    borderRadius: BORDER_RADIUS,
    // inputHeight: 45,
    // sliderHeight: 10,
    // sliderThumbSize: FONT_SIZE + SPACING,
    // cardHeight: 350,
    topPadding: theme.spacing(4),
  };

  // const switchWidth = 48;
  // const switchPadding = theme.spacing(1.5);
  // const switchHeight = switchWidth / 2;
  // const switchButtonSize = switchHeight * (14 / 24);
  // const switchButtonPadding = (switchHeight - switchButtonSize) / 2;

  // const ThemedSelectIcon = withTheme(SelectIcon, theme);
  // const ThemedExpandIcon = withTheme(ExpandIcon, theme);

  theme.props = {
    ...(theme.props || {}),

    MuiTypography: {
      variant: 'body1',
    },

    // MuiInputLabel: {
    //   disableAnimation: true,
    //   shrink: false,
    // },

    // MuiFormControl: {
    //   margin: 'normal',
    // },

    // MuiSelect: {
    //   IconComponent: ThemedSelectIcon,
    // },

    // MuiSwitch: {
    //   disableRipple: true,
    // },

    // MuiExpansionPanel: {
    //   elevation: 0,
    // },

    // MuiExpansionPanelSummary: {
    //   expandIcon: <ThemedExpandIcon />,
    // },

    // MuiButtonBase: {
    //   disableRipple: true,
    // },
  };

  const bodyMargin = {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  };

  theme.overrides = {
    ...(theme.overrides || {}),

    MuiTypography: {
      h1: {
        fontSize: '1.5rem',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '1.5',
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
      },
      h2: {
        fontSize: '1.7rem',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1.5',
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
      },
      h3: {
        fontSize: '1.3rem',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1.2',
        color: theme.palette.text.primary,
      },
      h4: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '1.2',
        color: theme.palette.primary.main,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '1.1',
        color: theme.palette.text.primary,
      },
      subtitle1: {
        fontSize: '0.95rem',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1',
        paddingTop: theme.spacing(0.5),
        color: theme.palette.text.secondary,
        // textTransform: 'capitalize',
      },
      subtitle2: {
        fontSize: '0.95rem',
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1',
        color: theme.palette.text.secondary,
      },
      body1: {
        ...bodyMargin,
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightRegular,
      },
      body2: {
        ...bodyMargin,
        fontSize: '0.9rem',
        fontWeight: theme.typography.fontWeightRegular,
      },
      overline: {
        fontSize: '0.85rem',
        lineHeight: 1,
      },
    },

    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: undefined,
        },
      },
    },

    MuiToolbar: {
      dense: {
        height: 48,
      },
    },

    // MuiFormGroup: {
    //   row: {
    //     justifyContent: 'space-between',
    //   },
    // },

    // MuiFormControl: {
    //   marginNormal: {
    //     marginTop: 0,
    //     marginBottom: theme.spacing(3),
    //   },
    // },

    // MuiInputLabel: {
    //   root: {
    //     color: theme.palette.text.primary,
    //     margin: theme.spacing(0, 0, 1),
    //     fontWeight: theme.typography.fontWeightMedium,
    //     display: 'block',

    //     '&$focused': {
    //       color: theme.palette.text.primary,
    //     },
    //   },
    //   focused: {},
    //   formControl: {
    //     transform: 'initial',
    //     position: 'initial',
    //   },
    // },

    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
        },
      },
      // formControl: {
      //   'label + &': {
      //     marginTop: 0,
      //   },
      // },
    },

    // MuiInputBase: {
    //   root: {
    //     fontSize: 'inherit',
    //     lineHeight: 1.5,
    //     borderRadius: theme.shape.borderRadius,
    //     overflow: 'hidden',
    //     '&::after': {
    //       content: '""',
    //       position: 'absolute',
    //       left: 0,
    //       right: 0,
    //       bottom: 0,
    //       height: 2,
    //       backgroundColor: theme.palette.primary.main,
    //       opacity: 0,
    //       transition: theme.transitions.create('opacity'),
    //     },
    //   },
    //   focused: {
    //     '&::after': {
    //       opacity: 1,
    //     },
    //   },
    //   input: {
    //     position: 'relative',
    //     minHeight: theme.shape.inputHeight,
    //     height: 'unset',
    //     boxSizing: 'border-box',
    //     padding: theme.spacing(1.25, 2.5),
    //     fontWeight: theme.typography.fontWeightMedium,
    //     backgroundColor: theme.palette.background.white,
    //     color: theme.palette.primary.main,

    //     '&::placeholder': {
    //       color: theme.palette.text.hint,
    //       opacity: 1,
    //       transition: 'none',
    //       fontWeight: theme.typography.fontWeightRegular,
    //     },
    //   },
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //   // @ts-ignore
    //   inputSelect: {
    //     paddingRight: theme.shape.inputHeight + theme.spacing(2.5),
    //   },
    // },

    // MuiSelect: {
    //   icon: {
    //     top: 0,
    //     height: '100%',
    //     minWidth: theme.shape.inputHeight,
    //     background: theme.palette.primary.main,
    //   },
    // },

    // MuiSlider: {
    //   root: {
    //     height: theme.shape.sliderHeight,
    //   },
    //   rail: {
    //     height: theme.shape.sliderHeight,
    //     borderRadius: theme.shape.sliderHeight / 2,
    //     backgroundColor: theme.palette.background.white,
    //   },
    //   track: {
    //     height: theme.shape.sliderHeight,
    //   },
    //   thumb: {
    //     width: theme.shape.sliderThumbSize,
    //     height: theme.shape.sliderThumbSize,
    //     marginTop:
    //       -(theme.shape.sliderThumbSize - theme.shape.sliderHeight) / 2,
    //     marginLeft: -theme.shape.sliderThumbSize / 2,
    //   },
    //   mark: {
    //     width: theme.shape.sliderThumbSize,
    //     height: theme.shape.sliderThumbSize,
    //     marginTop:
    //       -(theme.shape.sliderThumbSize - theme.shape.sliderHeight) / 2,
    //     marginLeft: -theme.shape.sliderThumbSize / 2,
    //     borderRadius: '50%',
    //     backgroundColor: theme.palette.background.default,
    //   },
    //   markActive: {
    //     opacity: 0,
    //   },
    //   markLabelActive: {
    //     color: theme.palette.primary.main,
    //   },
    //   valueLabel: {
    //     left: -theme.shape.sliderHeight / 2,
    //     top: theme.shape.sliderHeight / 2,
    //     // transform: `scale(0) translateY(-${theme.shape.sliderHeight}px)`,
    //     transformOrigin: 'center center',
    //     '& > span': {
    //       borderRadius: '50%',
    //     },
    //   },
    // },

    // MuiSwitch: {
    //   root: {
    //     boxSizing: 'content-box',
    //     width: switchWidth,
    //     height: switchHeight,
    //     padding: switchPadding,
    //   },
    //   switchBase: {
    //     top: '50%',
    //     left: switchPadding,
    //     padding: switchButtonPadding,
    //     transform: 'translateY(-50%)',

    //     '&:hover': {
    //       backgroundColor: 'transparent',

    //       '&$colorPrimary': {
    //         color: theme.palette.background.default,
    //       },

    //       '& + $track': {
    //         backgroundColor: theme.palette.primary.main,
    //       },
    //     },

    //     '&.Mui-checked': {
    //       transform: `translateY(-50%) translateX(calc(${switchWidth}px - 100%))`,

    //       '& + $track': {
    //         opacity: 1,
    //       },
    //     },
    //   },
    //   colorPrimary: {
    //     color: theme.palette.primary.main,

    //     '&.Mui-checked': {
    //       color: theme.palette.background.default,
    //     },
    //   },
    //   track: {
    //     opacity: 1,
    //     borderRadius: switchHeight / 2,
    //     backgroundColor: theme.palette.background.default,
    //   },
    //   thumb: {
    //     boxShadow: 'none',
    //     width: switchButtonSize,
    //     height: switchButtonSize,
    //   },
    // },

    // MuiButton: {
    //   root: {
    //     padding: theme.spacing(1, 4),
    //   },
    //   contained: {
    //     '&, &:hover': {
    //       boxShadow: 'none',
    //     },
    //   },
    // },

    // MuiToggleButton: {
    //   root: {
    //     '&.Mui-selected': {
    //       color: theme.palette.primary.contrastText,
    //       backgroundColor: theme.palette.primary.main,

    //       '&:hover:hover': {
    //         color: theme.palette.primary.contrastText,
    //         backgroundColor: theme.palette.primary.dark,
    //       },
    //     },
    //   },
    // },

    // MuiFormControlLabel: {
    //   labelPlacementStart: {
    //     marginLeft: 0,
    //     marginRight: undefined,
    //     /* margin-left: 16px; */
    //     /* margin-right: -11px; */
    //     flexDirection: 'row-reverse',
    //     justifyContent: 'flex-end',
    //   },
    // },

    // MuiExpansionPanel: {
    //   root: {
    //     backgroundColor: 'transparent',

    //     '&:before': {
    //       content: '',
    //     },

    //     '& ~ $root': {
    //       marginTop: theme.spacing(1),
    //       '&$expanded': {
    //         margin: theme.spacing(2, 0),
    //       },
    //     },

    //     '&$expanded': {
    //       margin: undefined,
    //       marginBottom: theme.spacing(2),
    //     },
    //   },
    //   rounded: {
    //     borderRadius: theme.shape.borderRadius,
    //   },
    //   expanded: {},
    // },

    // MuiExpansionPanelSummary: {
    //   root: {
    //     backgroundColor: theme.palette.background.white,
    //     color: theme.palette.primary.main,
    //     borderRadius: theme.shape.borderRadius,
    //     transition: theme.transitions.create(
    //       ['min-height', 'background-color', 'box-shadow'],
    //       { duration: theme.transitions.duration.shortest },
    //     ),

    //     '&$expanded': {
    //       minHeight: undefined,
    //       boxShadow: theme.shadows[1],
    //     },
    //   },
    //   content: {
    //     '&$expanded': {
    //       margin: undefined,
    //     },
    //   },
    //   expanded: {},
    //   expandIcon: {
    //     color: 'inherit',
    //     marginLeft: theme.spacing(1),
    //     '&.Mui-expanded': {
    //       transform: 'rotate(135deg)',
    //     },
    //   },
    // },

    // MuiExpansionPanelDetails: {
    //   root: {
    //     display: 'block',
    //     marginTop: theme.spacing(1),
    //     padding: theme.spacing(2),
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: theme.palette.background.white,
    //   },
    // },
  };

  return theme;
};

// eslint-disable-next-line import/prefer-default-export
export { getSncfTheme };
