import AWS from 'aws-sdk';

import { Asset } from 'phicomas-client';
import { getAwsCredentials } from 'phicomas-client/dist/projects/sncfFormTraction/aws-credentials';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import config from '../config';

const encodeRFC5987ValueChars = (str: string): string =>
  encodeURIComponent(str)
    // Note that although RFC3986 reserves "!", RFC5987 does not, so we do not need to escape it
    .replace(/['()]/g, escape) // i.e., %27 %28 %29
    .replace(/\*/g, '%2A')
    // The following are not required for percent-encoding per RFC5987, so we can allow for a little better readability over the wire: |`^
    .replace(/%(?:7C|60|5E)/g, unescape);

// eslint-disable-next-line import/prefer-default-export
export const getS3DownloadUrl = (
  key: string,
  {
    inline = false,
    asset,
  }: { inline?: boolean; asset?: Pick<Asset, 'filename'> } = {},
): Promise<string> => {
  const { level } = config.environment;
  const { s3: s3Raw } = projectInfos.awsExports;
  const s3 = s3Raw?.[level];
  if (!s3) {
    throw Error('No S3 config detected');
  }
  const credentials = getAwsCredentials(
    projectInfos.awsExports.cognito,
    config.environment,
  );
  const S3 = new AWS.S3({
    apiVersion: s3.apiVersion,
    region: s3.region,
    credentials,
  });
  const params: AWS.S3.Types.GetObjectRequest = {
    Bucket: s3.bucket,
    Key: key,
  };
  if (inline) {
    params.ResponseContentDisposition = 'inline';
  } else if (asset?.filename) {
    params.ResponseContentDisposition = `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(
      asset.filename,
    )}`;
  }

  return new Promise(resolve => {
    S3.getSignedUrl('getObject', params, (error, res) => {
      resolve(res);
    });
  });
};
