import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MutationUpdateNode, QueryNodes } from 'phicomas-client';
import {
  MutationUpdateMeArgs,
  User,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VideoInfo from '../VideoInfo';

import { ReactComponent as SvgEye } from '../../img/svg/eye.svg';
import { ReactComponent as SvgFavorites } from '../../img/svg/favorites.svg';
import { ReactComponent as SvgFavoritesOff } from '../../img/svg/favorites-off.svg';
import { ReactComponent as SvgMap } from '../../img/svg/map-icon.svg';
import { ReactComponent as SvgMapOff } from '../../img/svg/map-icon-off.svg';
import { ReactComponent as SvgDownload } from '../../img/svg/download.svg';
import { ReactComponent as SvgDownloadOff } from '../../img/svg/download-off.svg';

import { QUERY_ME, QUERY_ME_NAME } from '../../gql/customQueries';

import { ClassName } from '../../types/styles';

import routes from '../../customization/routes';
import map from '../../customization/map';
import { MUTATION_UPDATE_ME } from '../../gql/customMutations';

import { Resource, resourceIsVideo } from '../../types/resource';
import { getS3DownloadUrl } from '../../utils/s3-utils';

import config from '../../config/config';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      padding: '0.25em 1em',
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: '0.25em',
    },
    subtitle: {
      fontWeight: 'bold',
    },
    description: {
      lineHeight: '1.2',
    },
    tags: {
      display: 'flex',
      flexFlow: 'row wrap',
      margin: theme.spacing(0.25),
    },
    infos: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: theme.spacing(1, -1, 0),

      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
  }),
);

type DescriptionProps = {
  resource: Resource;
  className?: ClassName;
};

const Description: React.FC<DescriptionProps> = ({
  resource,
  className,
}: DescriptionProps) => {
  const apolloClient = useApolloClient();
  const classes = useStyles();
  const { id, title, createdAt, body, tags, download } = resource;

  const { data: dataMe, error: errorMe, loading: loadingMe } = useQuery<
    QueryNodes<User>
  >(QUERY_ME, { fetchPolicy: 'cache-only' });
  const isFavorite = useMemo(
    () =>
      dataMe?.[QUERY_ME_NAME]?.videoBookmarks.edges.find(
        ({ node: videoBookmark }) => videoBookmark.id === id,
      ) ?? false,
    [dataMe, id],
  );
  const canUpdateMe = !errorMe && !loadingMe;

  const [updateMe] = useMutation<
    MutationUpdateNode<User>,
    MutationUpdateMeArgs
  >(MUTATION_UPDATE_ME.mutation);

  const hasMap = useMemo(
    () => tags.edges.some(({ node }) => node.id === map.tagId),
    [tags],
  );

  const handleClickFavorite = useCallback(() => {
    if (resourceIsVideo(resource)) {
      const me = dataMe?.[QUERY_ME_NAME];
      if (canUpdateMe && me) {
        const connDisconn = isFavorite ? 'disconnect' : 'connect';
        const data: MutationUpdateMeArgs['data'] = {
          videoBookmarks: { [connDisconn]: [id] },
        };
        updateMe({
          variables: { data },
          update: MUTATION_UPDATE_ME.update(apolloClient),
          optimisticResponse: MUTATION_UPDATE_ME.optimisticResponse(me, data, {
            [id]: resource,
          }),
        });
      }
    }
  }, [dataMe, canUpdateMe, isFavorite, id, updateMe, apolloClient, resource]);

  const handleClickDownload = useCallback(async () => {
    if (resourceIsVideo(resource)) {
      const { videoKey } = resource;
      if (videoKey) {
        const link = `${config.formTractionVideoUrl}/${videoKey.slice(
          0,
          2,
        )}/${videoKey.slice(2)}/media.mp4`;
        window.open(link);
      }
    } else {
      const asset = resource.attachments.edges[0]?.node;
      if (asset && asset.key) {
        const link = await getS3DownloadUrl(asset.key, { asset });
        window.open(link);
      }
    }
  }, [resource]);

  const postedBy = false;

  return (
    <div className={clsx(className, classes.root)}>
      <div>
        <Typography variant="h4" classes={{ root: classes.title }}>
          {title}
        </Typography>
        <Typography variant="subtitle1" classes={{ root: classes.subtitle }}>
          {postedBy || createdAt ? `Postée` : ''}
          {postedBy ? ` par ${postedBy}` : ''}
          {createdAt ? ` le ${moment(createdAt).format('DD MMMM YYYY')}` : ''}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.description }}>
          {body}
        </Typography>
      </div>
      <div>
        <div className={classes.infos}>
          {resourceIsVideo(resource) ? (
            <>
              {(resource as Video).videoKey && (
                <VideoInfo
                  Svg={SvgEye}
                  iconPrimaryColored
                  text={(resource as Video).viewCount ?? 0}
                />
              )}
              {hasMap && (
                <VideoInfo
                  Svg={SvgMapOff}
                  SvgOn={SvgMap}
                  to={`${routes.linesMap}/${id}`}
                />
              )}
              <VideoInfo
                Svg={isFavorite ? SvgFavorites : SvgFavoritesOff}
                SvgOn={isFavorite ? SvgFavoritesOff : SvgFavorites}
                onClick={canUpdateMe ? handleClickFavorite : undefined}
                iconPrimaryColored
              />
              {!!download && (
                <VideoInfo
                  Svg={SvgDownloadOff}
                  SvgOn={SvgDownload}
                  onClick={canUpdateMe ? handleClickDownload : undefined}
                  iconPrimaryColored
                />
              )}
            </>
          ) : (
            !!download && (
              <>
                <VideoInfo
                  Svg={SvgDownloadOff}
                  SvgOn={SvgDownload}
                  onClick={canUpdateMe ? handleClickDownload : undefined}
                  iconPrimaryColored
                />
                <Typography>
                  En cas de besoin{' '}
                  <Typography
                    component="a"
                    href="mailto:cellule.management.cift@sncf.fr"
                    target="_blank"
                    color="primary"
                  >
                    contact
                  </Typography>
                </Typography>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Description);
