import _padStart from 'lodash/padStart';

import { Video } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

// eslint-disable-next-line import/prefer-default-export
export function getPresumedDuration(video: Video): number {
  const { duration: presumedDuration } = video;
  if (!presumedDuration) return -1;

  const splitted = presumedDuration.split("'");
  const seconds = +splitted[splitted.length - 1] || 0;
  const minutes = +splitted[splitted.length - 2] || 0;
  const hours = +splitted[splitted.length - 3] || 0;
  return hours * 60 * 60 + minutes * 60 + seconds;
}

const padTime = (n: number | string) => _padStart(`${n}`, 2, '0');

export function secondsToVideoDisplayTime(seconds: number | undefined): string {
  if (!seconds || seconds < 0) {
    return '-:--';
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${padTime(remainingSeconds)}`;
}
