import React from 'react';

type SplashscreenHandlerProps = {
  show: boolean;
  onSeen?: () => void;
};

class SplashscreenHandler extends React.PureComponent<
  SplashscreenHandlerProps
> {
  splashscreenElement: HTMLElement | null;

  constructor(props: SplashscreenHandlerProps) {
    super(props);
    this.splashscreenElement = null;
  }

  componentDidMount(): void {
    const { onSeen, show } = this.props;

    this.splashscreenElement = document.getElementById('splashscreen');

    const elapsedSplashscreenTime =
      Date.now() - (window as any).splashscreenStartTime ?? 0;
    if (onSeen) {
      setTimeout(onSeen, Math.max(1500 - elapsedSplashscreenTime, 1));
    }

    if (show) {
      this.reopen();
    } else {
      this.close();
    }
  }

  componentDidUpdate({ show: wasShown }: SplashscreenHandlerProps): void {
    const { show } = this.props;
    if (wasShown && !show) {
      this.close();
    } else if (!wasShown && show) {
      this.reopen();
    }
  }

  close(): void {
    this.splashscreenElement?.classList.add('hide');
    setTimeout(this.remove.bind(this), 500);
  }

  remove(): void {
    this.splashscreenElement?.classList.add('hidden');
  }

  reopen(): void {
    this.splashscreenElement?.classList.remove('hide', 'hidden');
  }

  render(): React.ReactNode {
    return null;
  }
}

export default SplashscreenHandler;
