import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OAuthRedir from 'phileog-login/dist/OAuthRedir';

import config from './config';

import Logout from './authComponents/Logout';
import AppLogNLoad from './AppLogNLoad';
import Embed from './components/Embed';

import { LOGIN_REDIRECT_LSKEY } from './authComponents/loginRedirect';

const EntryPoint: React.FC = () => {
  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route
        path="/oauth"
        render={props => {
          const redirectTo =
            window.localStorage.getItem(LOGIN_REDIRECT_LSKEY) ?? '/';
          window.localStorage.removeItem(LOGIN_REDIRECT_LSKEY);
          return (
            <OAuthRedir
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              credentials={config.credentials}
              redirect={
                process.env.PUBLIC_URL
                  ? `${process.env.PUBLIC_URL}/#${redirectTo}`
                  : `/#${redirectTo}`
              }
            />
          );
        }}
      />
      <Route path="/embed/:id" component={Embed} />
      {/* AppLogNLoad will not be loaded only for /logout and /oauth routes */}
      <Route component={AppLogNLoad} />
    </Switch>
  );
};

export default React.memo(EntryPoint);
