import { ApolloClient, MutationUpdaterFn } from '@apollo/client';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import {
  MutationCreateNode,
  MutationNode,
  MutationUpdateNode,
  updateStore,
  getNodeUpdateFragment,
  UpdateType,
  NodeInput,
  Node,
  isConnectionInputObject,
  Connection,
  nodeInputToNodeUpdateInfo,
  ID,
  MutationDeleteNode,
} from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import {
  Comment,
  User,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { QUERY_ME, QUERY_ME_NAME } from './customQueries';

type MutationObject<
  Mutation extends MutationNode = MutationNode,
  N extends Node = Node
> = {
  mutation: DocumentNode;
  update: (
    apolloClient: ApolloClient<any>,
    callback?: () => void,
  ) => MutationUpdaterFn<Mutation>;
};
type MutationObjectWithOptimisticData<
  Mutation extends MutationNode = MutationNode,
  N extends Node = Node
> = MutationObject<Mutation, N> & {
  optimisticResponse: (
    originalNode: N,
    data: NodeInput<N>,
    connectedNodes?: { [k in ID]: Node },
  ) => Mutation;
};

const userResourceInfos = projectInfos.resourcesInfos.sncfFormTractionUser;
const videoResourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
const commentResourceInfos =
  projectInfos.resourcesInfos.sncfFormTractionComment;

export const MUTATION_CREATE_ME_NAME = 'usrCreateMe';
export const MUTATION_CREATE_ME: MutationObject<MutationCreateNode<User>> = {
  mutation: gql`
    mutation($data: UserInput!) {
      ${MUTATION_CREATE_ME_NAME}(data: $data) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(userResourceInfos, true)}
  `,

  update: apolloClient => (store, { data }) => {
    if (data) {
      const nodeUpdate = data[MUTATION_CREATE_ME_NAME];
      updateStore(projectInfos.resourcesInfos, nodeUpdate, apolloClient);
      if (nodeUpdate.node) {
        store.writeQuery({
          query: QUERY_ME,
          data: { [QUERY_ME_NAME]: nodeUpdate.node },
        });
      }
    }
  },
};

export const MUTATION_UPDATE_ME_NAME = 'usrUpdateMe';
export const MUTATION_UPDATE_ME: MutationObjectWithOptimisticData<
  MutationUpdateNode<User>,
  User
> = {
  mutation: gql`
    mutation($data: UserInput!) {
      ${MUTATION_UPDATE_ME_NAME}(data: $data) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(userResourceInfos, true)}
  `,

  optimisticResponse: (me, data, connectedNodes) => {
    const updatedNode: typeof me = { ...me };
    (Object.keys(data) as (keyof typeof data)[]).forEach(key => {
      const value = data[key];
      if (value) {
        if (isConnectionInputObject(value)) {
          let newEdges = [...(updatedNode[key] as Connection).edges];
          value.connect?.forEach(connectedId => {
            newEdges.push({
              __typename: 'Edge',
              node: {
                __typename: 'Node', // Can't know more
                ...(connectedNodes?.[connectedId]
                  ? connectedNodes[connectedId]
                  : { id: connectedId, version: -1 }),
              },
            });
          });
          value.disconnect?.forEach(disconnected => {
            newEdges = newEdges.filter(({ node }) => node.id !== disconnected);
          });
          (updatedNode[key] as Connection) = {
            ...(updatedNode[key] as Connection),
            edges: newEdges,
          };
        } else {
          (updatedNode as any)[key] = value;
        }
      }
    });
    const connectionsData = nodeInputToNodeUpdateInfo<User>(data);
    return {
      [MUTATION_UPDATE_ME_NAME]: {
        __typename: 'NodeUpdate',
        id: updatedNode.id,
        node: updatedNode,
        updateInfo: {
          __typename: 'NodeUpdateInfo',
          mutation: UpdateType.UPDATE,
          connect: connectionsData?.connect || [],
          disconnect: connectionsData?.disconnect || [],
        },
      },
    };
  },

  update: apolloClient => (store, { data }) => {
    if (data) {
      const nodeUpdate = data[MUTATION_UPDATE_ME_NAME];
      updateStore(projectInfos.resourcesInfos, nodeUpdate, apolloClient);
    }
  },
};

export const MUTATION_VIEW_VIDEO_NAME = 'usrViewVideo';
export const MUTATION_VIEW_VIDEO: MutationObject<
  MutationUpdateNode<Video>,
  Video
> = {
  mutation: gql`
    mutation($id: ID!) {
      ${MUTATION_VIEW_VIDEO_NAME}(id: $id) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(videoResourceInfos)}
  `,

  update: apolloClient => (store, { data }) => {
    if (data) {
      const nodeUpdate = data[MUTATION_VIEW_VIDEO_NAME];
      updateStore(projectInfos.resourcesInfos, nodeUpdate, apolloClient);
    }
  },
};

export const MUTATION_CREATE_COMMENT_NAME = 'usrCreateVideoComment';
export const MUTATION_CREATE_COMMENT: MutationObject<
  MutationCreateNode<Comment>,
  Comment
> = {
  mutation: gql`
    mutation($id: ID!, $data: CommentInput!) {
      ${MUTATION_CREATE_COMMENT_NAME}(id: $id, data: $data) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(commentResourceInfos)}
  `,

  update: (apolloClient, callback) => (store, { data }) => {
    if (data) {
      const nodeUpdate = data[MUTATION_CREATE_COMMENT_NAME];
      updateStore(projectInfos.resourcesInfos, nodeUpdate, apolloClient).then(
        callback,
      );
    }
  },
};

export const MUTATION_DELETE_COMMENT_NAME = 'usrDeleteComment';
export const MUTATION_DELETE_COMMENT: MutationObject<
  MutationDeleteNode<Comment>,
  Comment
> = {
  mutation: gql`
    mutation($id: ID!) {
      ${MUTATION_DELETE_COMMENT_NAME}(id: $id) {
        ...NodeUpdateFragment
      }
    }
    ${getNodeUpdateFragment(commentResourceInfos)}
  `,

  update: apolloClient => (store, { data }) => {
    if (data) {
      const nodeUpdate = data[MUTATION_DELETE_COMMENT_NAME];
      updateStore(projectInfos.resourcesInfos, nodeUpdate, apolloClient);
    }
  },
};
