import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import { useApolloClient, useMutation } from '@apollo/client';

import { ID, MutationCreateNode } from 'phicomas-client';
import { Comment } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';

import { ForwardedPlayerRef } from 'videog-player';

import { MUTATION_CREATE_COMMENT } from '../../../gql/customMutations';

import { ClassName } from '../../../types/styles';

import profile from '../../../img/svg/profile.svg';
import { ReactComponent as Add } from '../../../img/svg/add.svg';

const TEMP_COMMENT_PREFIX = '#TEMP';
export type TmpComment = Required<Pick<Comment, 'text' | 'time'>> & {
  id: string;
};
export function isTmpComment(
  comment: Comment | TmpComment,
): comment is TmpComment {
  return comment.id.startsWith(TEMP_COMMENT_PREFIX);
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    rootAddComment: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    photo: {
      height: 75,
      flex: '0 1 auto',
    },
    input: {
      flex: '1 0 0%',
      margin: theme.spacing(1, 2),
    },
    addButton: {
      flex: '0 1 auto',
    },
    addSvg: {
      height: '1em',
      width: '1em',
    },
  }),
);

type AddCommentProps = {
  videoId: ID;
  refPlayer: React.RefObject<ForwardedPlayerRef>;
  creating: (tmpComment: TmpComment) => void;
  created: (id: TmpComment['id']) => void;
  className?: ClassName;
};

const AddComment: React.FC<AddCommentProps> = ({
  videoId,
  refPlayer,
  creating,
  created,
  className,
}: AddCommentProps) => {
  const classes = useStyles();

  const apolloClient = useApolloClient();
  const [createComment] = useMutation<MutationCreateNode<Comment>>(
    MUTATION_CREATE_COMMENT.mutation,
  );

  const [inputValue, setInputValue] = useState('');
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(e.target.value);
    },
    [],
  );
  const handleClick = useCallback(() => {
    const currentTime = refPlayer.current?.getCurrentTime();
    const uid = _uniqueId(TEMP_COMMENT_PREFIX);
    const text = inputValue && inputValue.trim();
    if (text) {
      const time = (currentTime && Math.floor(currentTime)) ?? 0;
      creating({ text, time, id: uid });
      createComment({
        variables: {
          id: videoId,
          data: {
            text,
            time,
          },
        },
        update: MUTATION_CREATE_COMMENT.update(apolloClient, () => {
          created(uid);
        }),
      });
    }
    setInputValue('');
  }, [
    apolloClient,
    createComment,
    creating,
    created,
    inputValue,
    refPlayer,
    videoId,
  ]);

  return (
    <div className={clsx(className, classes.rootAddComment)}>
      <img src={profile} alt="profile" className={classes.photo} />
      <TextField
        placeholder="Ajouter un commentaire..."
        className={classes.input}
        multiline
        rows={1}
        rowsMax={3}
        onChange={handleChange}
        value={inputValue}
      />
      <IconButton className={classes.addButton} onClick={handleClick}>
        <Add className={classes.addSvg} />
      </IconButton>
    </div>
  );
};

export default React.memo(AddComment);
