import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() => {
  return createStyles({
    mapRoot: {
      height: '100%',
    },
  });
});

type MapFullWrapperProps = React.PropsWithChildren<any>;
const MapFullWrapper: React.FC<MapFullWrapperProps> = ({
  children,
}: MapFullWrapperProps) => {
  const classes = useStyles();

  return <div className={classes.mapRoot}>{children}</div>;
};

export default React.memo(MapFullWrapper);
