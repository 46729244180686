import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FRAGMENT_BASE_SUFFIX, QueryAllNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    chipRoot: {
      height: 'unset',
      overflow: 'hidden',
    },
    chipLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline',
    },
  }),
);

type TagChipProps = {
  onRemove: () => void;
  tags?: Array<Tag['id']>;
};

const TagChip: React.FC<TagChipProps> = ({
  onRemove,
  tags: tagsId = [],
}: TagChipProps) => {
  const classes = useStyles();

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionTag;
  const {
    query: { allName: queryName },
    fragments: { base: fragmentBase, name: fragmentName },
  } = resourceInfos;

  const { data } = useQuery<QueryAllNodes<Tag>>(
    gql`
    query {
      ${queryName}  {
        edges {
          node {
            ...${fragmentName}${FRAGMENT_BASE_SUFFIX}
          }
        }
      }
    }
    ${fragmentBase}
  `,
    { fetchPolicy: 'cache-only' },
  );

  const tags = useMemo(() => {
    if (!data || !data[queryName]) return [];
    return data[queryName].edges
      .filter(({ node: { id } }) => tagsId.includes(id))
      .map(({ node: tag }) => tag)
      .sort((t1, t2) => (t1.weight ?? 999) - (t2.weight ?? 999));
  }, [data, queryName, tagsId]);

  const handleClickDelete = useCallback(() => {
    onRemove();
  }, [onRemove]);

  const multiTags = tags.length > 1;

  if (tags.length === 0) return null;

  return (
    <Tooltip
      title={
        <Typography variant="body2" color="inherit">
          {multiTags ? tags.map(t => t.name).join(', ') : tags[0].name}
        </Typography>
      }
      placement="bottom"
    >
      <Chip
        color="primary"
        onDelete={handleClickDelete}
        variant="outlined"
        label={multiTags ? `${tags.length} tags` : tags[0].name}
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
      />
    </Tooltip>
  );
};

export default React.memo(TagChip);
