/* eslint-disable import/prefer-default-export */

/**
 * This function is same as PHP's nl2br() with default parameters.
 */
export function nl2br(str: string, replaceMode = true, isXhtml = true): string {
  var breakTag = isXhtml ? '<br />' : '<br>';
  var replaceStr = replaceMode ? `$1${breakTag}` : `$1${breakTag}$2`;
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}
