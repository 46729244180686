import React, { useCallback, useMemo, useState } from 'react';
import _groupBy from 'lodash/groupBy';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { FRAGMENT_BASE_SUFFIX, QueryAllNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles<Theme>(theme => {
  const arrowFontSize = 18;
  // const arrowMargin = 12;
  // const arrowWidth = arrowFontSize + arrowMargin * 2;
  return createStyles({
    root: {
      maxWidth: 800,
      margin: '0 auto',
      columns: '2 300px',
      columnGap: theme.spacing(1),
      columnRule: `1px solid ${theme.palette.divider}`,
      columnFill: 'balance',
      padding: theme.spacing(0.5, 1, 2),
      color: theme.palette.text.contrastText,
    },
    parent: {
      padding: theme.spacing(0, 1),
      columnBreakInside: 'avoid',
      overflow: 'hidden',
      '& > *': {
        maxWidth: '100%',
      },
    },
    arrow: {
      marginLeft: theme.spacing(1),
      fontSize: arrowFontSize,
      transform: 'scaleY(1)',
      transition: theme.transitions.create('transform'),
      '&.flip': {
        transform: 'scaleY(-1)',
      },
    },
    parentContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
      overflow: 'hidden',
      maxHeight: 1500,
      transformOrigin: 'top',
      transform: 'scaleY(1)',
      transition: theme.transitions.create(['max-height', 'transform']),
      '&.is-collapsed': {
        maxHeight: 0,
        transform: 'scaleY(0)',
      },
    },
    formControlLabel: {
      margin: 0,
      '&.subTag': {
        paddingLeft: theme.spacing(4),
      },
    },
    checkbox: {
      padding: theme.spacing(0.25),
      marginRight: theme.spacing(1),
      color: theme.palette.specials.sncfLightGrey,
      '&.indeterminate': {
        color: theme.palette.primary.light,
      },
    },
    tagLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 0,
    },
    tagText: {
      display: 'inline',
    },
  });
});

type TagsHierarchyProps = {
  selected?: Array<Tag['id']>;
  onSelect: (tagIds: Array<Tag['id']>) => void;
  onDeselect: (tagIds: Array<Tag['id']>) => void;
  filteredTag?: Tag['id'] | null;
};

const TagsHierarchy: React.FC<TagsHierarchyProps> = ({
  selected = [],
  onSelect,
  onDeselect,
  filteredTag,
}: TagsHierarchyProps) => {
  const classes = useStyles();

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionTag;
  const {
    query: { allName: queryName },
    fragments: { base: fragmentBase, name: fragmentName },
  } = resourceInfos;

  const { data } = useQuery<QueryAllNodes<Tag>>(
    gql`
    query {
      ${queryName}  {
        edges {
          node {
            ...${fragmentName}${FRAGMENT_BASE_SUFFIX}
          }
        }
      }
    }
    ${fragmentBase}
  `,
    { fetchPolicy: 'cache-only' },
  );

  /** With selected info + sorted by weight */
  const tags = useMemo(() => {
    if (!data || !data[queryName]) return [];
    return data[queryName].edges
      .map(({ node: rawTag }) => {
        return {
          ...rawTag,
          selected: selected.includes(rawTag.id),
          parent: rawTag.parent || 'root',
        };
      })
      .sort((t1, t2) => (t1.weight ?? 999) - (t2.weight ?? 999));
  }, [data, queryName, selected]);
  const filteredTags = filteredTag
    ? tags.filter(tag => tag.parent === filteredTag || tag.id === filteredTag)
    : tags;
  const groupedTags = useMemo(() => _groupBy(filteredTags, 'parent'), [
    filteredTags,
  ]);

  /** Change selected state (=> prop) */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const id = event.target.value;
      let children: Array<Tag['id']> = [];
      if (typeof groupedTags[id] !== 'undefined') {
        children = groupedTags[id].map(({ id: childrenId }) => childrenId);
      }
      if (checked) {
        onSelect([id, ...children]);
      } else {
        onDeselect([id, ...children]);
      }
    },
    [onDeselect, onSelect, groupedTags],
  );

  /** Collapsing tree branches */
  const [collapsedParents, setCollapsedParents] = useState<
    { [k in Tag['id']]: boolean }
  >({});
  const handleToggleCollapsedParent = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const { id = '' }: { id?: Tag['id'] } = event.currentTarget.dataset;
      setCollapsedParents({ ...collapsedParents, [id]: !collapsedParents[id] });
    },
    [collapsedParents],
  );

  return (
    <div className={classes.root}>
      {groupedTags.root?.map(parentTag => {
        const {
          id: parentId,
          name: parentName,
          selected: parentSelected,
        } = parentTag;
        const isCollapsed = collapsedParents[parentId];
        const children = groupedTags[parentId];
        const hasChildren = Array.isArray(children) && children.length > 0;
        const hasChildrenChecked =
          !parentSelected &&
          hasChildren &&
          children.some(({ selected: childSelected }) => childSelected);
        return (
          <FormGroup
            key={`tagslist-parenttag-${parentId}`}
            className={classes.parent}
          >
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parentId}
                    checked={parentSelected}
                    indeterminate={hasChildrenChecked}
                    onChange={handleChange}
                    color="primary"
                    classes={{
                      root: clsx(classes.checkbox, {
                        indeterminate: hasChildrenChecked,
                      }),
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    color="inherit"
                    classes={{ root: classes.tagText }}
                  >
                    {parentName}
                  </Typography>
                }
                classes={{
                  root: classes.formControlLabel,
                  label: classes.tagLabel,
                }}
              />
              {hasChildren && (
                <IconButton
                  className={clsx(classes.arrow, { flip: !isCollapsed })}
                  color="secondary"
                  onClick={handleToggleCollapsedParent}
                  data-id={parentId}
                  component="span"
                >
                  <i className="sncf-icons-arrow-down" />
                </IconButton>
              )}
            </div>
            {hasChildren && (
              <div
                className={clsx(classes.parentContent, {
                  'is-collapsed': isCollapsed,
                })}
              >
                {children?.map(subTag => (
                  <FormControlLabel
                    key={`tagslist-tag-${subTag.id}`}
                    control={
                      <Checkbox
                        value={subTag.id}
                        checked={subTag.selected}
                        onChange={handleChange}
                        color="primary"
                        classes={{ root: classes.checkbox }}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        color="inherit"
                        classes={{ root: classes.tagText }}
                      >
                        {subTag.name}
                      </Typography>
                    }
                    classes={{
                      root: clsx(classes.formControlLabel, 'subTag'),
                      label: classes.tagLabel,
                    }}
                  />
                ))}
              </div>
            )}
          </FormGroup>
        );
      })}
    </div>
  );
};

export default React.memo(TagsHierarchy);
