import { Environment, getEnvironmentLevel, Instance } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { getAwsCredentials } from 'phicomas-client/dist/projects/sncfFormTraction/aws-credentials';
import { RefreshableCredentials } from 'phileog-login';

type Config = {
  environment: Environment;
  credentials: RefreshableCredentials;
  s3Bucket: string;
  formTractionVideoUrl: string;
  thumbRatio: number;
  portraitRatio: number;
  defaultPoster: string;
  sneakpickSize: number;
};

const environment: Environment = {
  instance: Instance.FRONT,
  level: getEnvironmentLevel(),
};

const awsCredentials = getAwsCredentials(
  projectInfos.awsExports.cognito,
  environment,
);

const config: Config = {
  environment,

  credentials: awsCredentials,

  // S3
  s3Bucket: 'media.traction.tv',

  // Videos
  formTractionVideoUrl: 'https://d2a5gu4n9jw406.cloudfront.net',

  // Thumbs / Carousel
  sneakpickSize: 14,
  thumbRatio: 16 / 9,
  portraitRatio: 4 / 3,
  defaultPoster: 'thumb_010.jpg', // FIXME: REMOVE AND USE video.config in place
};

export default config;
