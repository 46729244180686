import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _merge from 'lodash/merge';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { FRAGMENT_BASE_SUFFIX, QueryAllNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import ResourceList from '../ResourceList';

import { parseSearch } from '../../utils/search';
import { search } from '../../customization/list';

const useStylesResume = makeStyles<Theme>(theme =>
  createStyles({
    root: { margin: theme.spacing(0, 0, 1) },
    wrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    title: {
      marginRight: theme.spacing(2),
    },
  }),
);

type SummaryProps = {
  title: React.ReactNode;
  description?: React.ReactNode;
  nbResources: number;
};
const Summary: React.FC<SummaryProps> = ({
  title,
  nbResources,
  description,
}: SummaryProps) => {
  const classes = useStylesResume();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
        <Typography variant="subtitle2">{nbResources} résultats</Typography>
      </div>
      {description && (
        <Typography className={classes.description} variant="h3">
          {description}
        </Typography>
      )}
    </div>
  );
};

const Search: React.FC = () => {
  const { search: locationSearch } = useLocation();
  const searchQS = useMemo(() => parseSearch(locationSearch), [locationSearch]);
  const mergedSearch = useMemo(() => _merge({}, search, searchQS), [searchQS]);

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionTag;
  const {
    query: { allName: queryName },
    fragments: { base: fragmentBase, name: fragmentName },
  } = resourceInfos;

  const { data } = useQuery<QueryAllNodes<Tag>>(
    gql`
    query {
      ${queryName}  {
        edges {
          node {
            ...${fragmentName}${FRAGMENT_BASE_SUFFIX}
          }
        }
      }
    }
    ${fragmentBase}
  `,
    { fetchPolicy: 'cache-only' },
  );

  const descriptionProps = useMemo(() => {
    const allTags = data?.[queryName].edges?.map(({ node }) => node);
    const { title, terms, tags } = mergedSearch;
    const description: string[] = [];
    if (terms) {
      description.push(`"${terms}"`);
    }
    if (allTags?.length && tags.length) {
      const tagsNames: string[] = [];
      tags.forEach(tag => {
        const matchingTag = allTags.find(t => t.id === tag);
        if (matchingTag && matchingTag.name) {
          tagsNames.push(matchingTag.name);
        }
      });
      if (tagsNames.length) {
        description.push(`dans ${tagsNames.join(', ')}`);
      }
    }
    return {
      title,
      description: description.join(' '),
    };
  }, [data, mergedSearch, queryName]);

  return (
    <ResourceList
      list={mergedSearch}
      Description={Summary}
      descriptionProps={descriptionProps}
    />
  );
};

export default React.memo(Search);
