import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import config from '../config';

/**
 * Important : LOGIN_ENV traction-dev (FID dev) only works with a local proxy
 * run `node proxy.js` from devops/oauth directory
 *
 * To use SncfLogin, the app must define the callback url route (/oauth)
 */

const Logout: React.FC<void> = () => {
  const history = useHistory();
  useEffect(() => {
    (async () => {
      await config.credentials.logout();
      console.info('Logout redirects to /');
      history.push('/');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Typography>Logging out...</Typography>;
};

export default Logout;
