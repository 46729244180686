import React from 'react';
import { ObservableQuery, ApolloQueryResult } from '@apollo/client';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import _isEqual from 'lodash/isEqual';

import {
  FRAGMENT_BASE_SUFFIX,
  FRAGMENT_FULL_SUFFIX,
  pageInfo,
  QueryAllNodes,
} from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { ResourceKey } from 'phicomas-client/dist/projects/sncfFormTraction/projectKeys';

export type PreloadedResourcesInfos = {
  ready: boolean;
  loaded: boolean;
};

type SimpleResource = ResourceKey;
type ComplexResource = {
  resource: SimpleResource;
  full?: boolean;
  async?: boolean;
};

export type PreloadResourcesProps = WithApolloClient<{
  resources: Array<ComplexResource | SimpleResource>;
  wait?: boolean;
  children: (i: PreloadedResourcesInfos) => React.ReactNode;
}>;

type PreloadResourcesState = {
  ready: Array<boolean>;
  loaded: Array<boolean>;
};

class PreloadResources extends React.Component<
  PreloadResourcesProps,
  PreloadResourcesState
> {
  static batchLoad = 100;

  observableQueries: Array<ObservableQuery<QueryAllNodes>>;

  handleQueriesResult: Array<
    ({ data }: ApolloQueryResult<QueryAllNodes>) => void
  >;

  constructor(props: PreloadResourcesProps) {
    super(props);

    const { resources, wait } = this.props;

    const readyState = Array(resources.length).fill(false);

    this.observableQueries = [];
    this.handleQueriesResult = [];
    resources.forEach((r, index: number) => {
      const { async } = this.getResource(index);
      readyState[index] = async;
    });

    this.state = {
      ready: readyState,
      loaded: Array(resources.length).fill(false),
    };

    if (!wait) {
      this.launchQueries();
    }
  }

  componentDidMount() {
    const { wait } = this.props;
    if (!wait) {
      // Because it updates the state, component needs to be mounted
      this.readQueries();
    }
  }

  shouldComponentUpdate(nextProps: PreloadResourcesProps) {
    const { resources } = this.props;
    if (!_isEqual(nextProps.resources, resources)) {
      throw new Error('PreloadedResources resources should NOT change');
    }
    return true;
  }

  componentDidUpdate({ wait: wasWaiting = false }) {
    const { wait } = this.props;
    if (wasWaiting && !wait) {
      this.launchQueries();
      this.readQueries();
    }
  }

  getResource(index: number): ComplexResource {
    const { resources } = this.props;
    const r = resources[index];
    const { resource, full = false, async = false } =
      typeof r === 'string' ? { resource: r } : r;
    return { resource, full, async };
  }

  launchQueries() {
    const { resources, client } = this.props;
    if (client) {
      resources.forEach((r, index: number) => {
        const { resource, full } = this.getResource(index);
        this.handleQueriesResult[index] = this.handleQueryResult.bind(
          this,
          index,
        );

        const resourceInfos = projectInfos.resourcesInfos[resource];

        const {
          query: { allName: queryName },
          fragments: { [full ? 'full' : 'base']: fragment, name: fragmentName },
        } = resourceInfos;
        const fragmentSuffix = full
          ? FRAGMENT_FULL_SUFFIX
          : FRAGMENT_BASE_SUFFIX;

        this.observableQueries[index] = client.watchQuery<QueryAllNodes>({
          query: gql`
        query allResource($after: ID, $first: Int = 0) {
          ${queryName}(after: $after, first: $first) @connection(key: "${queryName}") {
            edges {
              node {
                ...${fragmentName}${fragmentSuffix}
              }
            }
            ...${pageInfo.name}
          }
        }
        ${fragment}
        ${pageInfo.fragment}
      `,
          variables: { first: PreloadResources.batchLoad },
          errorPolicy: 'all',
          fetchPolicy: 'cache-first',
        });
        // Call to subscribe() needed for observableQuery to be initialized
        // We could use subscribe's callback, but it would mean that any update to
        // the observed query would trigger it, and that could be unintentionnal
        this.observableQueries[index].subscribe(() => {});
      });
    }
  }

  readQueries() {
    this.observableQueries.forEach((observableQuery, index) =>
      observableQuery.result().then(this.handleQueriesResult[index]),
    );
  }

  handleQueryResult(index: number, { data }: ApolloQueryResult<QueryAllNodes>) {
    this.setState(state => {
      const newReady = [...state.ready];
      newReady[index] = true;
      return { ...state, ready: newReady };
    });

    const { resource } = this.getResource(index);
    const resourceInfos = projectInfos.resourcesInfos[resource];
    const {
      query: { allName: queryName },
    } = resourceInfos;

    const { hasNextPage, endCursor } = data?.[queryName].pageInfo ?? {};
    if (data && hasNextPage && endCursor) {
      this.observableQueries[index]
        .fetchMore({
          variables: { after: endCursor },
          updateQuery: (prev, { fetchMoreResult: next }) => ({
            ...(prev ?? {}),
            [queryName]: {
              ...(prev?.[queryName] ?? {}),
              edges: [
                ...(prev?.[queryName].edges ?? []),
                ...(next?.[queryName].edges ?? []),
              ],
              pageInfo: next?.[queryName].pageInfo ??
                prev?.[queryName].pageInfo ?? {
                  hasNextPage: false,
                  hasPreviousPage: false,
                },
            },
          }),
        })
        .then(this.handleQueriesResult[index]);
    } else {
      this.setState(state => {
        const newLoaded = [...state.loaded];
        newLoaded[index] = true;
        return { ...state, loaded: newLoaded };
      });
    }
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { ready, loaded } = this.state;
    return children({
      ready: ready.every(r => r),
      loaded: loaded.every(l => l),
    });
  }
}

export default withApollo<PreloadResourcesProps>(PreloadResources);
