import React from 'react';
import cx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { backgroundImage?: string }>(theme =>
  createStyles({
    app: {
      display: 'flex',
      flexFlow: 'column nowrap',
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      backgroundImage: ({ backgroundImage }) =>
        backgroundImage ? `url("${backgroundImage}")` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',

      position: 'relative',
      overflow: 'hidden',
    },
    fullHeight: {
      height: '100vh',
      '& > *': {
        flex: '0 0 auto',
      },
    },
  }),
);

type AppWrapperProps = React.PropsWithChildren<{
  backgroundImage?: string;
  fullHeight?: boolean;
}>;

const AppWrapper: React.FC<AppWrapperProps> = ({
  backgroundImage,
  fullHeight = false,
  children,
}: AppWrapperProps) => {
  const classes = useStyles({ backgroundImage });

  return (
    // Double wrap for IE bug on flex and min-height
    <div
      className={cx(classes.app, {
        [classes.fullHeight]: fullHeight,
      })}
    >
      <div
        className={cx(classes.app, {
          [classes.fullHeight]: fullHeight,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default AppWrapper;
