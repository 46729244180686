import React from 'react';
import Helmet from 'react-helmet';

import ResourceList from '../ResourceList/ResourceList';

import { List } from '../../customization/list';

type ResourceListPageProps = {
  list: List;
};

const ResourceListPage: React.FC<ResourceListPageProps> = ({
  list,
}: ResourceListPageProps) => {
  const { title } = list;

  return (
    <>
      <Helmet>
        <title>{title} | FormTraction</title>
      </Helmet>
      <ResourceList list={list} />
    </>
  );
};

export default React.memo(ResourceListPage);
