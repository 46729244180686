import { XOR } from '../types/utils';
import { SearchState } from '../utils/search';

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type Filter = Partial<SearchState> &
  // Either both or none
  XOR<{
    orderBy: string[];
    order: Order[];
  }>;
