import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import 'moment/locale/fr'; // Moment will use globaly the loaded locale

import { ApolloProvider } from '@apollo/client';
import { getProjectApolloClient } from 'phicomas-client/dist/projects/sncfFormTraction/apolloClient';

import {
  CssBaseline,
  MuiThemeProvider,
  getSncfTheme,
} from './theme/custom/sncf';
import { init as initGA } from './lib/GA/ReactGA';

import EntryPoint from './EntryPoint';

// Slick carousel
import './lib/slick-carousel/slick.css';
import './lib/slick-carousel/slick-theme.css';

import './index.css';

import * as serviceWorker from './serviceWorker';
import config from './config';

// Material-UI
const muiTheme = getSncfTheme();

// Apollo client
const client = getProjectApolloClient(config.environment);

const render = (Component: typeof EntryPoint): void => {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          <HashRouter basename="">
            <Component />
          </HashRouter>
        </MuiThemeProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

if (process.env.REACT_APP_ENV === 'prod') {
  initGA();
}

render(EntryPoint);

if (module.hot) {
  module.hot.accept('./EntryPoint', async () => {
    const { default: NextApp } = await import('./EntryPoint');
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
