import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, WindowScroller, GridCellRenderer } from 'react-virtualized';
import { QueryNodes } from 'phicomas-client';
import { User } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';

import useDimensions from '../../hooks/use-dimensions';
import ResourceThumb from './ResourceThumb';

import { getUnitsPerRow } from '../../utils/videoList';
import { ClassName } from '../../types/styles';
import config from '../../config';
import { QUERY_ME, QUERY_ME_NAME } from '../../gql/customQueries';
import { Resource } from '../../types/resource';

const THUMB_PADDING = 0.5;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      margin: theme.spacing(-THUMB_PADDING),
    },
    grid: {
      outline: 'none',
    },
    thumb: {
      padding: theme.spacing(THUMB_PADDING),
    },
  }),
);

type ResourceListGridProps = {
  resources: Resource[] | undefined;
  className?: ClassName;
};

const ResourceListGrid: React.FC<ResourceListGridProps> = ({
  resources,
  className = '',
}: ResourceListGridProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const { data: dataMe } = useQuery<QueryNodes<User>>(QUERY_ME, {
    fetchPolicy: 'cache-only',
  });
  const favorites = useMemo(
    () =>
      dataMe?.[QUERY_ME_NAME]?.videoBookmarks.edges.map(
        ({ node: videoBookmark }) => videoBookmark.id,
      ) ?? [],
    [dataMe],
  );

  const [rootRef, { width }] = useDimensions<HTMLDivElement>();

  const nbCols = useMemo(() => (width ? getUnitsPerRow(width, 'medium') : 1), [
    width,
  ]);
  const nbRows = useMemo(
    () =>
      resources
        ? Math.floor(resources.length / nbCols) +
          (resources.length % nbCols ? 1 : 0)
        : 0,
    [nbCols, resources],
  );

  if (!resources) return null;

  const cellRenderer: GridCellRenderer = ({
    /* eslint-disable react/prop-types */
    key, // Unique key within array of cells
    rowIndex, // Vertical (row) index of cell
    columnIndex, // Horizontal (column) index of cell
    // isScrolling, // The Grid is currently being scrolled
    // isVisible, // This cell is visible within the grid (eg it is not an overscanned cell)
    // parent, // Reference to the parent Grid (instance)
    // vvv MUST be passed through to the rendered cell element.
    style, // Style object to be applied to cell (to position it);
    /* eslint-enable react/prop-types */
  }): JSX.Element | null => {
    // const descriptionSizeUnit = baseUnit / 5.5; // Space allocated to description
    const resource = resources?.[rowIndex * nbCols + columnIndex];
    if (!resource) return null;
    return (
      <ResourceThumb
        key={key}
        resource={resource}
        isFavorite={favorites.includes(resource.id)}
        // sizeUnit={descriptionSizeUnit}
        className={classes.thumb}
        style={{ ...style }} // , top: style.top ?? 0 + 10, width: baseUnit - 10
        // withoutInnerPadding
        // spaced
      />
    );
  };
  const thumbWidth = (width ?? 0) / nbCols - theme.spacing(THUMB_PADDING) * 2;
  const thumbTitleHeight = 52 + theme.spacing(1);
  const thumbInfoHeight = 32 + theme.spacing(1);
  const descriptionSizeUnit = thumbTitleHeight + thumbInfoHeight;

  return (
    // Wrapping div because we manipulate margin of the wrapper class
    <div className={className}>
      <div ref={rootRef} className={classes.wrapper}>
        {width && (
          <WindowScroller>
            {({
              height,
              // width: windowWidth,
              isScrolling,
              // onChildScroll,
              scrollTop,
              registerChild,
            }) => (
              <Grid
                ref={registerChild}
                key={`Grid-${nbCols}-${width}`}
                autoHeight // Works with the WindowScroller
                autoWidth // Works with the WindowScroller
                isScrolling={isScrolling} // (override) Works with the WindowScroller
                height={height} // Info to get how many rows are visible vs virtualized
                scrollTop={
                  scrollTop - 100 /* because of too early disappearing */
                } // From WindowScroller
                width={width}
                cellRenderer={cellRenderer}
                columnCount={nbCols}
                columnWidth={width / nbCols}
                rowCount={nbRows}
                rowHeight={thumbWidth / config.thumbRatio + descriptionSizeUnit}
                className={classes.grid}
              />
            )}
          </WindowScroller>
        )}
      </div>
    </div>
  );
};

export default React.memo(ResourceListGrid);
