import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import { ClassName } from '../types/styles';

const useStyles = makeStyles<
  Theme,
  { src: string; low: string; srcInstant?: boolean }
>(() =>
  createStyles({
    image: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    contained: {
      backgroundSize: 'contain',
    },
    imageLow: {
      backgroundImage: ({ low }) => (low ? `url("${low}")` : 'none'),
      filter: 'blur(5px)',
      transform: 'scale(1.1)',
      opacity: ({ low }) => (low ? 1 : 0),
    },
    imageSrc: {
      backgroundImage: ({ src }) => (src ? `url("${src}")` : 'none'),
      opacity: ({ src }) => (src ? 1 : 0),
      transition: ({ low, srcInstant }) =>
        !low || srcInstant ? 'none' : 'opacity 0.6s ease',
    },
  }),
);

type SmartBackgroundProps = React.PropsWithChildren<{
  src: string;
  low?: string;
  contained?: boolean;
  className?: ClassName;
}>;

const SmartBackground = React.forwardRef<HTMLDivElement, SmartBackgroundProps>(
  (
    {
      src: srcRaw,
      low: lowRaw,
      contained,
      className,
      children,
    }: SmartBackgroundProps,
    ref,
  ) => {
    const [src, setSrc] = useState(lowRaw ? '' : srcRaw);
    const [srcInstant, setSrcInstant] = useState(false);
    const [low, setLow] = useState('');

    const classes = useStyles({ src, low, srcInstant });

    const [fetching, setFetching] = useState(false);

    const imgSrcRef = useRef(new Image());
    const imgLowRef = useRef(new Image());

    const handleImgSrcLoad = useCallback(() => {
      setSrc(imgSrcRef.current.src);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [srcRaw]);

    const handleImgLowLoad = useCallback(() => {
      setLow(imgLowRef.current.src);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [srcRaw]);

    const cleanUpRef = useRef<Array<() => void>>([]);
    useEffect(() => {
      const cleanUp = () => {
        cleanUpRef.current.forEach(fnc => fnc());
      };

      if (lowRaw) {
        const imgSrc = imgSrcRef.current;
        imgSrc.src = srcRaw;
        if (imgSrc.complete) {
          // all good
          setSrc(srcRaw);
          setSrcInstant(true); // Alreay fully loaded and ready: no transition
          return cleanUp;
        }
        // now start fetching
        imgSrc.addEventListener('load', handleImgSrcLoad);
        cleanUpRef.current.push(() =>
          imgSrc.removeEventListener('load', handleImgSrcLoad),
        );

        const imgLow = imgLowRef.current;
        imgLow.src = lowRaw;
        if (imgLow.complete) {
          setLow(lowRaw);
          return cleanUp;
        }
        imgLow.addEventListener('load', handleImgLowLoad);
        cleanUpRef.current.push(() =>
          imgLow.removeEventListener('load', handleImgLowLoad),
        );

        setFetching(true);
      }
      return cleanUp;
    }, [srcRaw, lowRaw, handleImgSrcLoad, handleImgLowLoad]);

    const showBackground = useMemo(() => fetching || src || low, [
      fetching,
      low,
      src,
    ]);

    return (
      <div className={className} ref={ref}>
        {showBackground ? (
          <>
            <div
              className={clsx(classes.image, classes.imageLow, {
                [classes.contained]: contained,
              })}
            />
            <div
              className={clsx(classes.image, classes.imageSrc, {
                [classes.contained]: contained,
              })}
            />
          </>
        ) : null}
        {children}
      </div>
    );
  },
);

export default React.memo(SmartBackground);
