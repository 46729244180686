import {
  continueWatching,
  favorites,
  // all,
  lineStudies,
  lineVideos,
  lastPublished,
} from './list';

export default [
  continueWatching,
  favorites,
  /* all , */
  lineStudies,
  lineVideos,
  lastPublished,
];
