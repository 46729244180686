import gql from 'graphql-tag';
import { FRAGMENT_FULL_SUFFIX, pageInfo } from 'phicomas-client';

import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';

const {
  fragments: { full: fragment, name: fragmentName },
} = projectInfos.resourcesInfos.sncfFormTractionUser;
const fragmentSuffix = FRAGMENT_FULL_SUFFIX;

export const QUERY_ME_NAME = 'me';
export const QUERY_ME = gql`
  query {
    ${QUERY_ME_NAME} {
      ...${fragmentName}${fragmentSuffix}
    }
  }
  ${fragment}
`;

export const QUERY_USR_COMMENTS_NAME = 'usrComments';
export const QUERY_USR_COMMENTS = gql`
  query ${QUERY_USR_COMMENTS_NAME}($after: ID, $first: Int = 0) {
    usrMe {
      id
      comments(after: $after, first: $first) @connection(key: "comments") {
        edges {
          node {
            id
            text
            time
            video @connection(key: "video") {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
        ...${pageInfo.name}
      }
    }
  }
  ${pageInfo.fragment}
`;
