/* eslint-disable react/jsx-props-no-spreading */

import React, { PropsWithChildren } from 'react';

type ConditionnalWrapperProps = React.PropsWithChildren<{
  values: {
    [k: string]: boolean;
  };
  components: {
    [k: string]: {
      Component: React.ComponentType<PropsWithChildren<any>>;
      props?: any;
    };
  };
}>;

const ConditionnalWrapper: React.FC<ConditionnalWrapperProps> = ({
  values,
  components,
  children,
}: ConditionnalWrapperProps) => {
  const componentKey = Object.keys(components).find(
    key => values[key] && typeof components[key] === 'object',
  );
  const component =
    typeof componentKey === 'undefined'
      ? { Component: React.Fragment, props: {} }
      : components[componentKey];
  const { Component, props = {} } = component;

  return <Component {...props}>{children}</Component>;
};

export default ConditionnalWrapper;
