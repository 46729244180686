import React, { useCallback, useEffect, useState } from 'react';
import { LoginType, useAuth } from 'phileog-login';
import { Redirect } from 'react-router-dom';

import config from '../config';
import LogModal from './LogModal';

import { LOGIN_REDIRECT_LSKEY } from './loginRedirect';

/**
 * Important : LOGIN_ENV traction-dev (FID dev) only works with a local proxy
 * run `node proxy.js` from devops/oauth directory
 *
 * To use SncfLogin, the app must define the callback url route (/oauth)
 */

type LoginProps = {
  previousUrl?: string | null;
};

const Login: React.FC<LoginProps> = ({ previousUrl }: LoginProps) => {
  const auth = useAuth(config.credentials);
  const { user, loading, error } = auth;

  const [waiting, setWaiting] = useState(false);
  const handleClick = useCallback(async () => {
    setWaiting(true);
    await config.credentials.login(LoginType.POPUP);
    setWaiting(false);
  }, [setWaiting]);

  useEffect(() => {
    if (!config.credentials.cognito) {
      if (previousUrl) {
        window.localStorage.setItem(LOGIN_REDIRECT_LSKEY, previousUrl);
      }
      // we never logged-in. try redirect
      config.credentials.login(LoginType.REDIRECT);
    }
  }, [previousUrl]);

  if (!user) {
    return config.credentials.cognito ? (
      <LogModal
        onClick={handleClick}
        error={error}
        loading={waiting || loading}
      />
    ) : null;
  }
  const redirectTo = previousUrl || '/';
  console.info('Login redirects to', redirectTo);
  return <Redirect to={redirectTo} />;
};

export default Login;
