import color from 'color';

import {
  // primaryColor as primaryColorBase,
  // secondaryColor as secondaryColorBase,
  commonColors as commonColorsBase,
  specialColors as specialColorsBase,
  // textColorsMixin as textColorsMixinBase,
} from '../../colors';

// const commonColorsBase = {
//   lightWhite: 'rgb(239, 239, 239)',
//   mediumWhite: 'rgb(247, 247, 247)',
//   hardWhite: 'rgb(255, 255, 255)',

//   darkGrey: 'rgb(62, 62, 62)',

//   lightBlack: 'rgb(44, 44, 44)',
//   mediumBlack: 'rgb(22, 22, 22)',
//   hardBlack: 'rgb(0, 0, 0)',
// };

const commonColors = {
  ...commonColorsBase,
  white: commonColorsBase.mediumWhite,
  black: commonColorsBase.mediumBlack,
};

const specialColors = {
  ...specialColorsBase,
  lightGrey: 'rgb(218, 218, 218)',
  danger: 'rgb(205, 0, 55)',
};
const stateColors = {
  error: {
    main: specialColors.sncfRed,
  },
  warning: {
    main: specialColors.sncfOrange,
  },
  info: {
    main: specialColors.sncfBlue,
  },
  success: {
    main: specialColors.sncfGreen,
  },
};

const primaryColor = specialColors.sncfBlue2;
const secondaryColor = 'rgb(224, 82, 6)';
const textColor = commonColors.white;
const backgroundColor = commonColors.black;

const textColorsMixin = (
  base: string,
): {
  primary: string;
  secondary: string;
  disabled: string;
  hint: string;
  contrastText: 'placeholder';
} => ({
  primary: base,
  secondary: color(base).alpha(0.7).string(),
  hint: color(base).alpha(0.55).string(),
  disabled: color(base).alpha(0.4).string(),
  contrastText: 'placeholder', // Generated based on primaryColor
});

export {
  primaryColor,
  secondaryColor,
  textColor,
  backgroundColor,
  commonColors,
  specialColors,
  textColorsMixin,
  stateColors,
};
