import { useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash/debounce';

export function windowScrolledTop(): boolean {
  return window.scrollY === 0;
  /*
  (window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop) > 0
  */
}

// eslint-disable-next-line import/prefer-default-export
export function useIsScrolledTop(): boolean {
  const [isScrolledTop, setIsScrolledTop] = useState(windowScrolledTop());
  const handleWindowScroll = useCallback(() => {
    setIsScrolledTop(windowScrolledTop());
  }, []);
  useEffect(() => {
    const debouncedHandleWindowScroll = _debounce(handleWindowScroll, 50, {
      leading: true,
    });
    window.addEventListener('scroll', debouncedHandleWindowScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', debouncedHandleWindowScroll);
    };
  }, [handleWindowScroll]);
  return isScrolledTop;
}
