import React from 'react';
import clsx from 'clsx';

import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import {
  lineVideos,
  lineStudies,
  continueWatching,
  List,
  favorites,
} from './list';
import routes from './routes';

import Home from '../components/pages/Home';
// import Map from '../components/pages/Map';

import { ReactComponent as MapLogo } from '../img/svg/map-logo.svg';

type SvgLink = Required<Pick<List, 'shortTitle' | 'title' | 'url' | 'tags'>> & {
  Svg: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};
type TagsButton = Pick<List, 'title'> & {
  /** Open tags (search) on click  */
  openTags: true;
};
type TagButton = Pick<List, 'title'> & {
  /** Open tags (search) on click  */
  openTag: Tag['id'];
};

type Navigation = (List | SvgLink | TagsButton | TagButton) & {
  /** Route param used by component */
  param?: string;
  /** Highlight tab only if exact match */
  exact?: boolean;
  component?: React.FC;
  icon?: React.ReactElement;
};

export function isSvgLink(navigation: Navigation): navigation is SvgLink {
  return typeof (navigation as SvgLink).Svg !== 'undefined';
}

export function isTagsButton(navigation: Navigation): navigation is TagsButton {
  return (navigation as TagsButton).openTags === true;
}
export function isTagButton(navigation: Navigation): navigation is TagButton {
  return typeof (navigation as TagButton).openTag === 'string';
}

export function isList(navigation: Navigation): navigation is List {
  return (
    !isSvgLink(navigation) &&
    !isTagsButton(navigation) &&
    !isTagButton(navigation)
  );
}

export const home: Navigation = {
  title: 'Accueil',
  url: routes.home,
  component: Home,
};

export const navigationContinueWatching: Navigation = {
  ...continueWatching,
};

export const navigationFavorites: Navigation = {
  ...favorites,
  title: 'Favoris',
};

export const navigationLineVideos: Navigation = {
  title: lineVideos.title,
  icon: <i className={clsx('sncf-icons-arrow-down', 'sncf-icons-size-18px')} />,
  openTag: lineVideos.tags?.[0],
};

export const navigationLineStudies: Navigation = {
  title: lineStudies.title,
  icon: <i className={clsx('sncf-icons-arrow-down', 'sncf-icons-size-18px')} />,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  openTag: lineStudies.tags![0],
};

export const tags: Navigation = {
  title: 'Ressources',
  icon: <i className={clsx('sncf-icons-arrow-down', 'sncf-icons-size-18px')} />,
  openTags: true,
};

export const signalisationTag: Navigation = {
  title: 'Signalisation',
  icon: <i className={clsx('sncf-icons-arrow-down', 'sncf-icons-size-18px')} />,
  openTag: '5fc6688d-2bda-405f-ab11-831a0775f8e9',
};

export const videoPedaTag: Navigation = {
  title: 'Ressources pédagogiques',
  icon: <i className={clsx('sncf-icons-arrow-down', 'sncf-icons-size-18px')} />,
  openTag: '5fc66ad2-3cc3-44e0-b4cd-1b0c13e9eb1f',
};

const USE_MAP = false;
export const linesMap: Navigation = {
  title: 'Carte',
  Svg: MapLogo,
  url: USE_MAP ? routes.linesMap : undefined,
  tags: USE_MAP ? undefined : ['5fcdf7aa-3a8f-4e54-acc8-d0e0213ce5c4'],
  exact: USE_MAP ? undefined : true,
  // Now statically defined in App.tsx
  // /*
  // component: Map,
  // param: 'id',
  // */
};

export default [
  home,
  // linesMap, // ⚠️ re-enable when the map works
  // navigationContinueWatching,
  // navigationFavorites,
  navigationLineVideos,
  navigationLineStudies,
  // favorites,
  // tags,
  signalisationTag,
  videoPedaTag,
] as Navigation[];
