import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { ID, MutationUpdateNode, QueryNodes } from 'phicomas-client';
import {
  MutationUpdateMeArgs,
  User,
  VideoWatch,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import _isEqual from 'lodash/isEqual';
import _throttle from 'lodash/throttle';
import moment from 'moment';

import { QUERY_ME, QUERY_ME_NAME } from '../gql/customQueries';
import { usePrevious } from '../hooks/use-previous';
import { MUTATION_UPDATE_ME } from '../gql/customMutations';

const UPLOAD_SAVE_INTERVAL_DELAY = 30000;
const TTL_MONTH = 3;

export const TIME = 't';
export const COMPLETED = 'c';
export const VIEWED_DATE = 'd';

export type VideoTimeInfos = {
  [TIME]?: number;
  [COMPLETED]?: boolean;
  [VIEWED_DATE]: string;
};
type VideosTimeInfos = {
  [k in ID]?: VideoTimeInfos;
};
type VideoTimesState = {
  videos: VideosTimeInfos;
  loading: boolean;
};
type VideoTimePayload = { id: ID } & Omit<VideoTimeInfos, typeof VIEWED_DATE> &
  Required<Pick<VideoTimeInfos, typeof TIME>>;

const LOCAL_STORAGE_KEY = 'videoTimes-v2';
function setLocalstorageVideoTimesState(value: VideosTimeInfos) {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  } catch (error) {
    console.error('Error setting videoTimes localstorage value', error);
  }
}
function getLocalstorageVideoTimesState(
  fallbackValue: VideosTimeInfos,
): VideosTimeInfos {
  try {
    const item = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (item) {
      return JSON.parse(item);
    }
    setLocalstorageVideoTimesState(fallbackValue);
    return fallbackValue;
  } catch (error) {
    console.error('Error getting videoTimes localstorage value', error);
    return fallbackValue;
  }
}

function getLastVideoTimeIdFromState({
  videos,
}: VideoTimesState): undefined | ID {
  /* eslint-disable no-param-reassign */
  return Object.keys(videos).reduce<undefined | ID>((acc, id) => {
    if (!acc) return id;
    const idDate = (videos[id] as VideoTimeInfos)[VIEWED_DATE];
    const accDate = (videos[acc] as VideoTimeInfos)[VIEWED_DATE];
    return idDate > accDate ? id : acc;
  }, undefined);
  /* eslint-enable no-param-reassign */
}

export enum VideoTimesActionTypes {
  'INITIALIZE' = 'INITIALIZE',
  'INITIALIZED' = 'INITIALIZED',
  'SET' = 'SET',
  'COMPLETED' = 'COMPLETED',
}
type Action =
  | { type: VideoTimesActionTypes.INITIALIZE; payload: VideoWatch[] }
  | { type: VideoTimesActionTypes.INITIALIZED }
  | { type: VideoTimesActionTypes.SET; payload: VideoTimePayload }
  | {
      type: VideoTimesActionTypes.COMPLETED;
      payload: Pick<VideoTimePayload, 'id'>;
    };

const moment3Month = moment().endOf('day').subtract(TTL_MONTH, 'months');

function reducer(
  contextState: VideoTimesState,
  action: Action,
): VideoTimesState {
  const state = {
    ...contextState,
    // In case of multi tab concurrency, always fetch the last localstorage value
    videos: getLocalstorageVideoTimesState(contextState.videos),
  };
  let newState = state;

  switch (action.type) {
    case VideoTimesActionTypes.INITIALIZE:
      newState = {
        ...state,
        videos: action.payload.reduce<VideoTimesState['videos']>(
          (acc, videoWatch) => {
            if (moment(videoWatch.updatedAt).isAfter(moment3Month)) {
              acc[videoWatch.id] = {
                [TIME]: videoWatch.progress,
                [COMPLETED]: videoWatch.completed,
                [VIEWED_DATE]: videoWatch.updatedAt,
              };
            }
            return acc;
          },
          {},
        ),
        loading: false,
      };
      break;
    case VideoTimesActionTypes.INITIALIZED:
      newState = {
        ...state,
        loading: false,
      };
      break;
    case VideoTimesActionTypes.SET:
      if (
        state.videos[action.payload.id]?.[TIME] !== action.payload[TIME] ||
        state.videos[action.payload.id]?.[COMPLETED] === true
      ) {
        newState = {
          ...state,
          videos: {
            ...state.videos,
            [action.payload.id]: {
              ...state.videos[action.payload.id],
              [TIME]: action.payload[TIME],
              [COMPLETED]: false, // Being viewed means not completed
              [VIEWED_DATE]: new Date().toISOString(),
            },
          },
        };
      }
      break;
    case VideoTimesActionTypes.COMPLETED:
      newState = {
        ...state,
        videos: {
          ...state.videos,
          [action.payload.id]: {
            ...state.videos[action.payload.id],
            [COMPLETED]: true,
            [VIEWED_DATE]: new Date().toISOString(),
          },
        },
      };
      break;
    default:
      throw new Error(
        `action type ${(action as Action).type} unkown in videoTimes reducer`,
      );
  }
  setLocalstorageVideoTimesState(newState.videos);
  return newState;
}

const DEFAULT_STATE: VideoTimesState = {
  videos: {},
  loading: true,
};
function initialize(
  defaultValue: VideoTimesState = DEFAULT_STATE,
): VideoTimesState {
  return {
    ...defaultValue,
    videos: getLocalstorageVideoTimesState(defaultValue.videos),
  };
}

export const VideoTimesContext = React.createContext<
  [VideoTimesState, React.Dispatch<Action>]
>(undefined as any); // VideoTimesContext should not be used outside of provider

type VideoTimesContextProviderProps = React.PropsWithChildren<any>;
export const VideoTimesContextProvider: React.FC<VideoTimesContextProviderProps> = ({
  children,
}: VideoTimesContextProviderProps) => {
  const apolloClient = useApolloClient();
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE, initialize);
  const prevState = usePrevious(state);

  const { data: dataMe, error: errorMe, loading: loadingMe } = useQuery<
    QueryNodes<User>
  >(QUERY_ME, {
    fetchPolicy: 'cache-only',
  });
  const canUpdateMe = !errorMe && !loadingMe;
  const hasMe = !!dataMe?.[QUERY_ME_NAME];

  const [updateMe] = useMutation<
    MutationUpdateNode<User>,
    MutationUpdateMeArgs
  >(MUTATION_UPDATE_ME.mutation);

  // A state have been saved into `me`
  const lastWatched = dataMe?.[QUERY_ME_NAME]?.lastWatched;
  const hasLastWatched = lastWatched !== null;
  const [hasASavedState, setHasASavedState] = useState(hasLastWatched);
  useEffect(() => {
    if (hasLastWatched) setHasASavedState(true);
  }, [hasLastWatched]);

  // The state have been initialized with `me`
  useEffect(() => {
    if (state.loading) {
      if (typeof lastWatched !== 'undefined') {
        if (hasASavedState) {
          dispatch({
            type: VideoTimesActionTypes.INITIALIZE,
            payload: lastWatched,
          });
        } else {
          dispatch({
            type: VideoTimesActionTypes.INITIALIZED,
          });
        }
      }
    }
  }, [dataMe, hasASavedState, state, lastWatched]);

  const saveStateOnMe = useCallback(
    (stateToSave: typeof state) => {
      if (canUpdateMe && hasMe) {
        updateMe({
          variables: {
            data: {
              lastWatched: Object.keys(stateToSave.videos).map(id => {
                const videoTimeInfos = stateToSave.videos[id] as VideoTimeInfos;
                return {
                  id,
                  updatedAt: videoTimeInfos[VIEWED_DATE],
                  progress: Math.floor(videoTimeInfos[TIME] ?? 0),
                  completed: videoTimeInfos[COMPLETED],
                };
              }),
            },
          },
          update: MUTATION_UPDATE_ME.update(apolloClient),
        });
      }
    },
    [apolloClient, canUpdateMe, hasMe, updateMe],
  );

  const requireSaveStateOnMe = useMemo(
    () =>
      _throttle(saveStateOnMe, UPLOAD_SAVE_INTERVAL_DELAY, {
        leading: false,
      }),
    [saveStateOnMe],
  );

  // Check for required update on `me`
  useEffect(() => {
    if (!state.loading) {
      if (!hasASavedState) {
        // Initialize DB
        setHasASavedState(true);
        requireSaveStateOnMe(state);
        requireSaveStateOnMe.flush();
      } else if (
        prevState &&
        !prevState.loading && // If it was loading, then prevState.videos is not to be trusted
        !_isEqual(prevState.videos, state.videos) // Only update when there have been a change
      ) {
        requireSaveStateOnMe(state);
        const lastVideoTimeId = getLastVideoTimeIdFromState(state);
        if (
          lastVideoTimeId &&
          (state.videos[lastVideoTimeId] as VideoTimeInfos)[COMPLETED]
        ) {
          requireSaveStateOnMe.flush();
        }
      }
    }
    return () => requireSaveStateOnMe.cancel();
  }, [
    apolloClient,
    hasASavedState,
    prevState,
    requireSaveStateOnMe,
    state,
    updateMe,
  ]);

  return (
    <VideoTimesContext.Provider value={[state, dispatch]}>
      {children}
    </VideoTimesContext.Provider>
  );
};

export const VideoTimesContextConsumer = VideoTimesContext.Consumer;
