import React from 'react';
import { useParams } from 'react-router-dom';

import MapSmallWrapper from '../Map/MapSmallWrapper';
import MapFullWrapper from '../Map/MapFullWrapper';
import MapLine from '../Map/MapLine';
import MapAllLines from '../Map/MapAllLines';

const Map: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      {typeof id === 'string' ? (
        <MapSmallWrapper>
          <MapLine id={id} />
        </MapSmallWrapper>
      ) : (
        <MapFullWrapper>
          <MapAllLines />
        </MapFullWrapper>
      )}
    </>
  );
};

export default React.memo(Map);
