import React from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import backgroundImage from '../../img/svg/map-background.svg';

const useStyles = makeStyles<Theme>(theme => {
  // const height = 600;
  return createStyles({
    mapRoot: {
      height: '100%',
      backgroundImage: `url("${backgroundImage}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center bottom',
      backgroundAttachment: 'fixed',
      padding: theme.spacing(2),

      display: 'flex',
      justifyContent: 'center',
    },
    mapContent: {
      height: '75%',
      minHeight: '60vh',
      maxHeight: 'calc(100% - 200px)',
      width: '100%',
      maxWidth: '200vh',
      // width: `calc(100% - ${theme.spacing(2) * 2}px)`, // Relative to AppContent
      // position: 'absolute',
      // top: '50%',
      // transform: `translateY(-50%)`,
      border: `5px solid ${theme.palette.common.black}`,
      borderRadius: theme.shape.borderRadius,

      // [`@media (max-height: 900px)`]: {
      //   height,
      //   // width: '100%',
      //   // position: 'relative',
      //   // top: '0',
      //   // transform: `translateY(0)`,
      //   border: `5px solid ${theme.palette.common.black}`,
      //   borderRadius: theme.shape.borderRadius,
      // },
    },
  });
});

type MapSmallWrapperProps = React.PropsWithChildren<any>;
const MapSmallWrapper: React.FC<MapSmallWrapperProps> = ({
  children,
}: MapSmallWrapperProps) => {
  const classes = useStyles();

  return (
    <div className={classes.mapRoot}>
      <div className={classes.mapContent}>{children}</div>
    </div>
  );
};

export default React.memo(MapSmallWrapper);
