import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

const Forbidden: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <p>
          Vous n&rsquo;avez pas accès à ce site.
          <br />
          Contacter les responsables via cette boite mail&nbsp;:&nbsp;
          <a href="mailto:cellule.management.cift@sncf.fr">
            cellule.management.cift@sncf.fr
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default Forbidden;
