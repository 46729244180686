export default {
  home: '/home',
  video: '/video',
  post: '/post',
  search: '/search',
  all: '/all',
  linesMap: '/map',
  continueWatching: '/continue-watching',
  favorites: '/favorites',
};
