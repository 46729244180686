import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography } from '@material-ui/core';

import { ForwardedPlayerRef } from 'videog-player';
import { GeoSync, loadGeoSync } from 'traction-geolib';
import {
  Comment,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { useComments } from './use-comments';

import AddComment, { isTmpComment, TmpComment } from './Comments/AddComment';

import { ReactComponent as Play } from '../../img/svg/play-comment.svg';
import { ReactComponent as Pause } from '../../img/svg/pause-comment.svg';

import { ClassName } from '../../types/styles';
import { nl2br } from '../../utils/text';
import DeleteComment from './Comments/DeleteComment';
import { secondsToVideoDisplayTime } from '../../utils/video';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    rootComments: {
      display: 'flex',
      flexFlow: 'column nowrap',

      '& > *': {
        flex: '0 0 auto',
      },
    },
    listWrapper: {
      flex: '1 1 auto',
      overflow: 'hidden',
      display: 'flex',
      flexFlow: 'column',
      marginTop: theme.spacing(2),
    },
    listTitle: {
      marginBottom: theme.spacing(2),
    },
    list: {
      overflowY: 'auto',
      scrollSnapType: 'y',
    },
    commentContainer: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0.5, 1, 0.5, 2),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      scrollMarginTop: 0,
      scrollSnapAlign: 'start',

      '&:first-child': {
        marginTop: 0,
      },
    },
    isActive: {
      backgroundColor: theme.palette.primary.main,
      '& $svg': {
        color: theme.palette.primary.contrastText,
      },
    },
    adding: {
      opacity: 0.8,
      '& $svg': {
        color: 'inherit',
      },
    },
    deleting: {
      display: 'none',
    },
    comment: {
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
    },
    commentInfos: {
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    button: {
      border: '1px solid grey',
      borderRadius: '0',
      padding: theme.spacing(1),
      margin: '8px 0px 8px 8px',
    },
    svg: {
      width: 15,
      height: 15,
      color: theme.palette.primary.main,
    },
  }),
);

type CommentsProps = {
  video: Video;
  refPlayer: React.RefObject<ForwardedPlayerRef>;
  className?: ClassName;
};

const Comments: React.FC<CommentsProps> = ({
  video,
  refPlayer,
  className,
}: CommentsProps) => {
  const classes = useStyles();

  const { id: videoId } = video;

  const { comments: commentsRaw } = useComments({ videoId });

  const [creating, setCreating] = useState<TmpComment[]>([]);
  const handleCreating = useCallback((tmpComment: TmpComment) => {
    setCreating(creatingTmp => [...creatingTmp, tmpComment]);
  }, []);
  const handleCreated = useCallback((idToRemove: TmpComment['id']) => {
    setCreating(creatingTmp =>
      creatingTmp.filter(({ id }) => id !== idToRemove),
    );
  }, []);
  const [deleting, setDeleting] = useState<string[]>([]);

  const handleDeleting = useCallback((idToDelete: Comment['id']) => {
    setDeleting(deletingTmp => [...deletingTmp, idToDelete]);
  }, []);
  const handleDeleted = useCallback((idToDelete: Comment['id']) => {
    setDeleting(deletingTmp => deletingTmp.filter(id => id !== idToDelete));
  }, []);

  const comments = useMemo(() => {
    return [...(commentsRaw ?? []), ...creating]
      .filter(com => !deleting.includes(com.id))
      .sort((comA, comB) => comA.time - comB.time);
  }, [commentsRaw, creating, deleting]);

  const [geoSync, setGeoSync] = useState<GeoSync>();
  // Load geoSync
  useEffect(() => {
    if (video.videoKey) {
      loadGeoSync(video.videoKey).then(resGeoSync => {
        setGeoSync(resGeoSync);
      });
    }
  }, [video.videoKey]);

  const [playing, setPlaying] = useState<boolean>();
  const [currentTime, setCurrentTime] = useState<number>();
  // Check and set playing state
  useEffect(() => {
    refPlayer.current?.currentTime$.subscribe({
      next: setCurrentTime,
    });
    refPlayer.current?.isPlaying$.subscribe({
      next: setPlaying,
    });
  }, [refPlayer]);

  const [activeCommentIndex, setActiveCommentIndex] = useState<number>();
  // Check and change state of active comment
  useEffect(() => {
    if (currentTime) {
      setActiveCommentIndex(
        comments?.findIndex((comment, index) => {
          const nextComment = comments[index + 1];
          return (
            comment.time <= currentTime &&
            (!nextComment || nextComment.time > currentTime)
          );
        }) ?? undefined,
      );
    }
  }, [comments, currentTime]);
  // Scroll to comment on change
  useEffect(() => {
    var activeCommentEl = document.getElementById(
      `comment-${activeCommentIndex}`,
    );
    activeCommentEl?.scrollIntoView();
  }, [activeCommentIndex]);

  const handleClickGo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { time } = e.currentTarget.dataset;
      if (typeof time !== 'undefined') {
        refPlayer.current?.setCurrentTime(+time);
      }
    },
    [refPlayer],
  );

  const handleClickPlayPause = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { time, commentIndex } = e.currentTarget.dataset;
      const isActive =
        typeof commentIndex !== 'undefined' &&
        activeCommentIndex === +commentIndex;

      if (!isActive && typeof time !== 'undefined') {
        refPlayer.current?.setCurrentTime(+time);
      }
      if (isActive && playing) {
        refPlayer.current?.pause();
      } else {
        refPlayer.current?.play();
      }
    },
    [activeCommentIndex, playing, refPlayer],
  );

  const deleteCommentClasses = useMemo(
    () => ({ root: classes.button, svg: classes.svg }),
    [classes],
  );

  return (
    <div className={clsx(className, classes.rootComments)}>
      <AddComment
        videoId={videoId}
        refPlayer={refPlayer}
        creating={handleCreating}
        created={handleCreated}
      />
      <div className={classes.listWrapper}>
        <Typography variant="h3" className={classes.listTitle}>
          Liste de mes commentaires :
        </Typography>
        <div className={classes.list}>
          {comments?.map((comment, index) => {
            const { id, text, time } = comment;
            const isBeingAdded = isTmpComment(comment);
            return (
              <div
                key={id}
                id={`comment-${index}`}
                className={clsx(classes.commentContainer, {
                  [classes.isActive]: index === activeCommentIndex,
                  [classes.adding]: isBeingAdded,
                })}
              >
                <Typography
                  dangerouslySetInnerHTML={{ __html: nl2br(text) }}
                  className={classes.comment}
                />
                <div className={classes.commentInfos}>
                  <Button
                    variant="text"
                    onClick={handleClickGo}
                    data-time={time}
                    disabled={isBeingAdded}
                  >
                    {geoSync ? (
                      <>Pk&nbsp;:&nbsp;{geoSync.setTime(time * 1000).pk}</>
                    ) : (
                      <>{secondsToVideoDisplayTime(time)}</>
                    )}
                  </Button>
                  <IconButton
                    onClick={handleClickPlayPause}
                    data-time={time}
                    data-comment-index={index}
                    className={classes.button}
                    disabled={isBeingAdded}
                  >
                    {index === activeCommentIndex && playing ? (
                      <Pause className={classes.svg} />
                    ) : (
                      <Play className={classes.svg} />
                    )}
                  </IconButton>
                  <DeleteComment
                    id={id}
                    disabled={isBeingAdded}
                    deleting={handleDeleting}
                    deleted={handleDeleted}
                    classes={deleteCommentClasses}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Comments);
