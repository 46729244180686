import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export function getFontAwesomeIconFromMimeType(
  mimeType: string,
): IconDefinition {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  const iconClasses: { [k in string]?: IconDefinition } = {
    // Media
    image: faFileImage,
    audio: faFileAudio,
    video: faFileVideo,
    // Documents
    'application/pdf': faFilePdf,
    'application/msword': faFileWord,
    'application/vnd.ms-word': faFileWord,
    'application/vnd.oasis.opendocument.text': faFileWord,
    'application/vnd.openxmlformats-officedocument.wordprocessingml': faFileWord,
    'application/vnd.ms-excel': faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml': faFileExcel,
    'application/vnd.oasis.opendocument.spreadsheet': faFileExcel,
    'application/vnd.ms-powerpoint': faFilePowerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml': faFilePowerpoint,
    'application/vnd.oasis.opendocument.presentation': faFilePowerpoint,
    'text/plain': faFileAlt,
    'text/html': faFileCode,
    'application/json': faFileCode,
    // Archives
    'application/gzip': faFileArchive,
    'application/zip': faFileArchive,
  };

  const mimeTypeFirstPart = mimeType.split('/')[0];
  return iconClasses[mimeType] || iconClasses[mimeTypeFirstPart] || faFile;
}
