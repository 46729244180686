import qs from 'qs';
import _castArray from 'lodash/castArray';
import _compact from 'lodash/compact';
import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

export type SearchState = {
  terms: string;
  tags: Array<Tag['id']>;
};

const DEFAULT_SEARCH: SearchState = {
  tags: [],
  terms: '',
};

const MAX_PATTERN_LENGTH = 32;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const stringifySearch = (search: any): string =>
  qs.stringify(search, { arrayFormat: 'repeat' });

const parseSearch = (search: string): SearchState => {
  if (!search) return DEFAULT_SEARCH;
  const {
    terms: searchedTermsString = '',
    tags: searchedTagsIds = [],
    ...rest
  } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  if (typeof searchedTermsString !== 'string')
    throw new Error('Searched terms are not a string');

  let searchedTerms = searchedTermsString;
  searchedTerms = searchedTerms.replace(/\s\s+/g, ' '); // Remove all "useless" spaces
  const searchedTagsArray = _castArray(searchedTagsIds);
  const searchedTagsFlat = searchedTagsArray.flatMap(tid => `${tid}`);
  const searchedTags = _compact(searchedTagsFlat);

  return { ...rest, tags: searchedTags, terms: searchedTerms };
};

export { DEFAULT_SEARCH, MAX_PATTERN_LENGTH, stringifySearch, parseSearch };
