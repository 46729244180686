import React, { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoginType, useAuth } from 'phileog-login';
import Color from 'color';

import { Video } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { LOGIN_REDIRECT_LSKEY } from '../../authComponents/loginRedirect';
import config from '../../config';
import EmbedVideo from './EmbedVideo';

type AuthUser = ReturnType<typeof useAuth>['user'];
function isLogged(authUser: AuthUser): authUser is NonNullable<AuthUser> {
  return authUser !== null;
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      perspective: '800px',
      perspectiveOrigin: 'center',
      transformStyle: 'preserve-3d',
      zIndex: theme.zIndex.modal,
    },
    loginModal: {
      padding: theme.spacing(8),
      backgroundColor: Color(theme.palette.background.paper)
        .alpha(0.98)
        .toString(),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',

      animation: '.2s $slide-up',
      transformOrigin: 'left',
    },
    text: {
      whiteSpace: 'nowrap',
    },
    '@keyframes slide-up': {
      from: {
        opacity: 0,
        transform: 'translate3d(-25%, 0, 0) rotateY(75deg)',
      },

      to: {
        opacity: 1,
        transform: 'translate3d(0, 0, 0) rotateX(0deg)',
      },
    },
  }),
);

const Embed: React.FC = () => {
  const classes = useStyles();
  const { pathname, search, hash } = useLocation();
  const { id } = useParams<{ id: Video['id'] }>();

  const { user, loading: userLoading } = useAuth(config.credentials);
  const logged = isLogged(user);

  // Use logging state to prevent poor re-renderings between call to REDIRECT and actual redirection
  const [logging, setLogging] = useState(false);
  const handleClickLogin = useCallback(async () => {
    if (!userLoading && !logged && !logging) {
      setLogging(true); // If we're here, we will be redirected down the road, so state will be reinitialized on the way back, no need to `setLogging(false)` anywhere
      window.localStorage.setItem(
        LOGIN_REDIRECT_LSKEY,
        `${pathname}${search}${hash}`,
      );
      await config.credentials.login(LoginType.POPUP);
      setLogging(false);
    }
  }, [userLoading, logged, pathname, search, hash, logging]);

  if (userLoading) return null;

  if (!logged)
    return (
      <div className={classes.wrapper}>
        <div className={classes.loginModal}>
          <Typography color="secondary" className={classes.text}>
            Merci de vous connecter à FormTraction.TV
          </Typography>
          <Button
            onClick={handleClickLogin}
            variant="contained"
            color="primary"
            disabled={logging}
          >
            {!logging ? 'Me connecter' : 'Connexion en cours...'}
          </Button>
        </div>
      </div>
    );

  return <EmbedVideo id={id} />;
};

export default Embed;
