import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, Typography } from '@material-ui/core';

import ConditionnalWrapper from '../lib/wrappers/ConditionnalWrapper';
import { XOR } from '../types/utils';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapperLink: {
      display: 'inline-flex',
    },
    wrapperButton: {
      padding: 0,
      minWidth: 0,
    },
    root: {
      display: 'inline-flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${fade(theme.palette.primary.light, 0.5)}`,
    },
    rootWithText: {
      minWidth: 60,
    },
    rootWithIconOn: {
      '&:hover': {
        cursor: 'pointer',
        '& $icon': {
          opacity: 0,
        },
        '& $iconOn': {
          opacity: 1,
        },
      },
    },
    iconOn: {
      position: 'absolute',
      opacity: 0,
    },
    icon: {
      height: '1em',

      '&:not(:last-child)': {
        marginRight: '0.2em',
      },

      '&$iconPrimaryColored': {
        color: `${theme.palette.primary.main}`,
      },
    },
    iconPrimaryColored: {},
    text: {
      margin: 0,
      lineHeight: 1,

      // Override MUI
      '&, &:last-child': {
        marginBottom: '-0.1em',
      },
    },
  }),
);

type SvgType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;
type VideoInfoProps = {
  Svg: SvgType;
  SvgOn?: SvgType;
  text?: string | number;
  iconPrimaryColored?: boolean;
} & XOR<{ to?: string }, { onClick?: () => void }>;

const VideoInfo: React.FC<VideoInfoProps> = ({
  Svg,
  SvgOn,
  text,
  iconPrimaryColored = false,
  to,
  onClick,
}: VideoInfoProps) => {
  const classes = useStyles();
  return (
    <ConditionnalWrapper
      values={{
        link: typeof to !== 'undefined',
        button: typeof onClick !== 'undefined',
      }}
      components={{
        link: {
          Component: Link,
          props: { to, className: classes.wrapperLink },
        },
        button: {
          Component: Button,
          props: { onClick, className: classes.wrapperButton },
        },
      }}
    >
      <div
        className={clsx(classes.root, {
          [classes.rootWithText]: typeof text !== 'undefined',
          [classes.rootWithIconOn]: typeof SvgOn !== 'undefined',
        })}
      >
        {SvgOn && (
          <SvgOn
            className={clsx(classes.icon, classes.iconOn, {
              [classes.iconPrimaryColored]: iconPrimaryColored,
            })}
          />
        )}
        <Svg
          className={clsx(classes.icon, {
            [classes.iconPrimaryColored]: iconPrimaryColored,
          })}
        />
        {typeof text !== 'undefined' && (
          <Typography className={classes.text}>{text}</Typography>
        )}
      </div>
    </ConditionnalWrapper>
  );
};

export default React.memo(VideoInfo);
