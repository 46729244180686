import React, { useCallback, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useAuth } from 'phileog-login';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { FRAGMENT_FULL_SUFFIX, QueryAllNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import {
  Tag,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import {
  Theme,
  makeStyles,
  createStyles,
  fade,
} from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// import Avatar from '../Avatar';

import navigation, { isTagButton } from '../../customization/navigation';

import mylogo from '../../img/svg/mylogo.svg';
import { getListUrl } from '../../customization/list';
import config from '../../config';
import {
  VideoTimeInfos,
  VideoTimesContext,
  VIEWED_DATE,
} from '../../context/videoTimes';
import routes from '../../customization/routes';
import { OPEN_SEARCH_TAGS } from '../Searchbar/Searchbar';

const NB_HISTORY_SHOWN = 5;

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100vh',
      width: '400px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.down('xs')]: {
        width: `calc(100vw - ${theme.spacing(2)}px)`,
        maxWidth: '95vw',
      },
    },
    toolbarContent: {
      ...(theme.mixins.toolbar as Record<string, CSSProperties>),
      flex: '0 0 auto',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0 ${theme.spacing(2)}px`,
    },
    content: {
      flex: '1 1 0%',
      overflow: 'auto',
      padding: `0 ${theme.spacing(2)}px`,
    },
    userName: {
      fontWeight: theme.typography.fontWeightBold,
    },
    notifySwitcher: {
      flex: '0 0 auto',
      padding: `0 ${theme.spacing(3)}px 0 0`,
      display: 'flex',
      justifyContent: 'center',
    },
    menuButton: {
      opacity: '0.4',
      transition: theme.transitions.create('opacity'),
      '&:hover': {
        opacity: '1',
      },
    },
    logo: {
      height: +(theme.mixins.toolbar.height ?? 0) - theme.spacing(2),
    },
    profile: {
      display: 'flex',
      flexFlow: 'row noWrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    gravatar: {
      marginRight: theme.spacing(1),
    },
    divider: {
      height: '2px',
      backgroundColor: fade(theme.palette.primary.contrastText, 0.4),
      borderRadius: 1,
      '&.small': {
        marginRight: '70%',
      },
      '&.medium': {
        marginRight: '50%',
      },
      '&.dark': {
        backgroundColor: fade(theme.palette.common.black, 0.5),
      },
    },
    listItem: {
      margin: '0.75em 0',
    },
    divButton: {
      cursor: 'pointer',
    },
    listItemTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    },
    listHistoryItem: {
      margin: theme.spacing(0.0, 0, 0.0, 1),
    },
    listHistoryItemTitle: {
      fontWeight: 'normal',
    },
  }),
);

type MenuProps = {
  open: boolean;
  toggleMenu: () => void;
};

const Menu: React.FC<MenuProps> = ({ open, toggleMenu }: MenuProps) => {
  const classes = useStyles();

  const { user } = useAuth(config.credentials);

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
  const {
    query: { allName: queryName },
    fragments: { full: fragmentFull, name: fragmentName },
  } = resourceInfos;
  const { data } = useQuery<QueryAllNodes<Video>>(
    gql`
      query {
        ${queryName}  {
          edges {
            node {
              ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
            }
          }
        }
      }
      ${fragmentFull}
    `,
    { fetchPolicy: 'cache-only' },
  );

  const [{ videos: videoTimesVideos }] = useContext(VideoTimesContext);
  const videosTimesToShow = useMemo(() => {
    if (!data) {
      return [];
    }
    return Object.keys(videoTimesVideos)
      .sort((aId, bId) => {
        const aTime = (videoTimesVideos[aId] as VideoTimeInfos)[VIEWED_DATE];
        const bTime = (videoTimesVideos[bId] as VideoTimeInfos)[VIEWED_DATE];
        return aTime > bTime ? -1 : aTime < bTime ? 1 : 0; // eslint-disable-line no-nested-ternary
      })
      .slice(0, NB_HISTORY_SHOWN)
      .reduce<
        {
          title: string;
          id: string;
        }[]
      >((acc, vId) => {
        const video = data[queryName].edges.find(({ node }) => node.id === vId);
        const title = video?.node.title;
        if (title) {
          acc.push({
            title,
            id: vId,
          });
        }
        return acc;
      }, []);
  }, [data, queryName, videoTimesVideos]);

  const handleNavigate = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);

  const handleClickArrowDownFiltered = useCallback(
    (tag?: Tag['id']) => {
      PubSub.publish(OPEN_SEARCH_TAGS, { tag });
      toggleMenu();
    },
    [toggleMenu],
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      classes={{ paper: classes.contentWrapper }}
    >
      <div className={classes.toolbarContent}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={toggleMenu}
        >
          <i className="sncf-icons-menu-burger sncf-icons-size-20px" />
        </IconButton>
        <img src={mylogo} alt="Mon Mat TV" className={classes.logo} />
      </div>
      <div className={classes.content}>
        <div className={classes.listItem}>
          <div className={classes.profile}>
            {user && (
              <>
                {/* <Avatar className={classes.gravatar} /> */}
                <Typography
                  variant="body1"
                  color="inherit"
                  className={classes.userName}
                >
                  {user.sub}
                </Typography>
              </>
            )}
          </div>
        </div>
        <Divider classes={{ root: clsx(classes.divider, 'medium', 'dark') }} />
        {navigation.map(nav => (
          <div key={nav.title} className={classes.listItem}>
            {isTagButton(nav) ? (
              <div
                role="button"
                tabIndex={-1}
                onClick={() => handleClickArrowDownFiltered(nav.openTag)}
                onKeyPress={() => handleClickArrowDownFiltered(nav.openTag)}
                className={classes.divButton}
              >
                <Typography
                  variant="body1"
                  color="inherit"
                  classes={{ root: classes.listItemTitle }}
                >
                  {nav.title}
                </Typography>
              </div>
            ) : (
              <Link to={getListUrl(nav)} onClick={handleNavigate}>
                <Typography
                  variant="body1"
                  color="inherit"
                  classes={{ root: classes.listItemTitle }}
                >
                  {nav.title}
                </Typography>
              </Link>
            )}
          </div>
        ))}
        {videosTimesToShow.length > 0 && (
          <>
            <Divider classes={{ root: clsx(classes.divider, 'small') }} />
            <Typography className={classes.listItemTitle}>
              Mon historique
            </Typography>
            {videosTimesToShow.map(videoInfos => (
              <div
                key={videoInfos.id}
                className={clsx(classes.listItem, classes.listHistoryItem)}
              >
                <Link
                  to={`${routes.video}/${videoInfos.id}`}
                  onClick={handleNavigate}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    classes={{
                      root: clsx(
                        classes.listItemTitle,
                        classes.listHistoryItemTitle,
                      ),
                    }}
                  >
                    {videoInfos.title}
                  </Typography>
                </Link>
              </div>
            ))}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default React.memo(Menu);
