import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import clsx from 'clsx';

import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ClickAwayListener, PropTypes } from '@material-ui/core';

import Popover from '@material-ui/core/Popover';
import Fade from '@material-ui/core/Fade';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import TagsHierarchy from './TagsHierarchy';
import TagChip from './TagChip';

import { useDebounce } from '../../hooks/use-debounce';
import { useUpdateEffect } from '../../hooks/use-update-effect';

import {
  SearchState,
  DEFAULT_SEARCH,
  stringifySearch,
  parseSearch,
} from '../../utils/search';

import routes from '../../customization/routes';

export const OPEN_SEARCH_TAGS = 'SEARCHBAR/OPEN_SEARCH_TAGS';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    searchContainer: {
      flex: '1 1 0%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-end',
      },
    },
    searchWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        flex: '1 1 0%',
        justifyContent: 'flex-end',
      },
    },
    search: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      color: 'rgba(0, 0, 0, 0.87)',
      overflow: 'hidden',
      transition: theme.transitions.create([
        'background-color',
        'width',
        'max-width',
      ]),
      width: '37vw',
      maxWidth: 600,
      [theme.breakpoints.down('xs')]: {
        minWidth: 0,
        maxWidth: 0,
      },
      '&$expanded': {
        width: `${37 * 1.5}vw`,
        maxWidth: 700,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
    },
    expanded: {},
    // ##### Search Input
    inputWrapper: {
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      border: `1px solid ${fade(theme.palette.common.black, 0.15)}`,
      // borderTopLeftRadius: theme.shape.borderRadius,
      // borderBottomLeftRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      transition: theme.transitions.create('border-radius'),
    },
    inputTags: {
      flex: '0 1 auto',
      maxWidth: '75%',
      padding: theme.spacing(0, 1),
      overflow: 'hidden',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      '&.multi': {
        flexShrink: 0,
      },
    },
    inputTagsRecap: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
    },
    inputRoot: {
      flex: '1 1 auto',
      color: theme.palette.text.contrastText,
    },
    inputInput: {
      paddingTop: theme.spacing(0.5),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
    },
    unfoldIcon: {
      padding: theme.spacing(0.5),
      margin: `0 ${theme.spacing(1)}px`,
      transform: 'scaleY(1)',
      transition: theme.transitions.create('transform'),
      '&.flip': {
        transform: 'scaleY(-1)',
      },
    },
    // ##### Search Button
    searchButton: {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      boxSizing: 'border-box',
      boxShadow: 'none',
      borderRadius: 0,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      transition: theme.transitions.create([
        'padding-left',
        'padding-right',
        'opacity',
      ]),
      [`${theme.breakpoints.down('xs')}`]: {
        '&:not(.search-open)': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      zIndex: theme.zIndex.modal + 1,
    },

    // ##### TagsList
    tagsPaper: {
      width: '100%',
      maxHeight: 'unset',
      maxWidth: 'unset',
      backgorundColor: theme.palette.specials.sncfLightGrey,
    },
    closeTags: {
      position: 'absolute',
      right: theme.spacing(1),
    },

    // ##### Search Magnifier
    searchToggler: {
      maxWidth: 50,
      overflow: 'hidden',
      transition: theme.transitions.create([
        'max-width',
        'padding-left',
        'padding-right',
      ]),
      [`${theme.breakpoints.up('sm')}`]: {
        maxWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    unfoldSearchHidden: {
      maxWidth: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

type SearchbarProps = {
  onExpandToggle?: (isExpanded: boolean) => void;
  onPopoverToggle?: (isOpen: boolean) => void;
  contrastColor?: PropTypes.Color;
  popoverAnchor: HTMLDivElement | null;
};

const Searchbar: React.FC<SearchbarProps> = ({
  onExpandToggle = () => {},
  onPopoverToggle = () => {},
  contrastColor = 'default',
  popoverAnchor,
}: SearchbarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ isReplaced: boolean }>();
  const { state: { isReplaced } = {}, pathname } = location;

  const refInput = useRef<HTMLInputElement>(null);
  const focusInput = useCallback(() => {
    setTimeout(() => {
      if (refInput.current) {
        refInput.current.focus();
      }
    }, 0);
  }, []);

  /* Expanded from focus */
  const [focusExpanded, setFocusExpanded] = useState(false);
  const handleClickAway = useCallback(() => {
    setFocusExpanded(false);
  }, [setFocusExpanded]);
  const handleClickIn = useCallback(() => {
    setFocusExpanded(true);
  }, [setFocusExpanded]);

  /* Tags open */
  const [tagsOpen, setTagsOpen] = useState(false);
  const [filteredTag, setFilteredTag] = useState<null | Tag['id']>(null);
  const handleToggleTags = useCallback(() => {
    setTagsOpen(!tagsOpen);
  }, [tagsOpen]);
  const handleCloseTags = useCallback(() => {
    setTagsOpen(false);
    focusInput();
    setFilteredTag(null);
  }, [focusInput]);
  useEffect(() => {
    onPopoverToggle(tagsOpen);
  }, [onPopoverToggle, tagsOpen]);
  const openTagsSubscriber = useCallback(
    (message, { tag }: { tag?: Tag['id'] } = {}) => {
      setTagsOpen(true);
      if (tag) {
        setFilteredTag(tag);
      }
    },
    [],
  );
  useEffect(() => {
    PubSub.subscribe(OPEN_SEARCH_TAGS, openTagsSubscriber);
    return () => PubSub.unsubscribe(OPEN_SEARCH_TAGS);
  });

  /* Expanded from focus or tags open */
  const expanded = useMemo(() => focusExpanded || tagsOpen, [
    focusExpanded,
    tagsOpen,
  ]);
  useEffect(() => {
    onExpandToggle(expanded);
  }, [onExpandToggle, expanded]);

  /* Search state */
  const [search, setSearch] = useState<SearchState>(DEFAULT_SEARCH);

  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch({ ...search, terms: event.target.value });
    },
    [search],
  );

  /** On location change, check if there is a search */
  const { search: locationSearchRaw } = location;
  const locationSearch = parseSearch(locationSearchRaw);
  const refPreviousLocationSearch = useRef<typeof locationSearch>(null);
  useEffect(() => {
    if (
      !_isEqual(refPreviousLocationSearch.current, locationSearch) && // Changed location
      !_isEqual(search, locationSearch) // Changed search (compared to location's)
    ) {
      setSearch(locationSearch);
    }
    (refPreviousLocationSearch as React.MutableRefObject<
      typeof locationSearch
    >).current = locationSearch;
  }, [locationSearch, search]);

  /** On page change */
  const isOnSearchPage = !!matchPath(pathname, { path: routes.search });
  useEffect(() => {
    if (!isOnSearchPage) {
      setSearch(DEFAULT_SEARCH);
    }
  }, [isOnSearchPage]);

  /** Submit */
  const wasReplaced = useRef(isReplaced);
  useEffect(() => {
    wasReplaced.current = isReplaced;
  }, [isReplaced]);
  const refHistory = useRef(history);
  useEffect(() => {
    refHistory.current = history;
  }, [history]);
  const submitSearch = useCallback(
    (
      submittedSearch: SearchState,
      { keepFocus = false, replace = false } = {},
    ) => {
      const { tags, terms } = submittedSearch;

      let searchString = '';
      const searchState = { isReplaced: replace };
      if (tags.length === 0) {
        if (terms === '') {
          if (replace) {
            searchString = routes.search;
          } else {
            searchString = routes.search;
            searchState.isReplaced = false;
          }
        } else {
          searchString = `${routes.search}?${stringifySearch({ terms })}`;
        }
      } else if (terms === '') {
        searchString = `${routes.search}?${stringifySearch({ tags })}`;
      } else {
        searchString = `${routes.search}?${stringifySearch({ tags, terms })}`;
      }

      if (!keepFocus) {
        setFocusExpanded(false);
      }

      // Only replace if previously replaced (=> first replace is not one)
      if (replace && wasReplaced.current) {
        refHistory.current.replace(searchString, searchState);
      } else {
        refHistory.current.push(searchString, searchState);
      }
    },
    [],
  );

  const handleClickSubmitSearch = useCallback(() => {
    submitSearch(search);
    handleCloseTags();
  }, [submitSearch, search, handleCloseTags]);

  const handleSearchInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        submitSearch(search, { keepFocus: true });
        event.preventDefault();
      }
    },
    [submitSearch, search],
  );

  const handleSelectTag = useCallback(
    (tagIds: Array<Tag['id']>) => {
      setSearch({ ...search, tags: _uniq([...search.tags, ...tagIds]) });
    },
    [search],
  );
  const handleDeselectTag = useCallback(
    (tagIds: Array<Tag['id']>) => {
      setSearch({
        ...search,
        tags: search.tags.filter(tid => !tagIds.includes(tid)),
      });
    },
    [search],
  );
  const handleDeselectAllTags = useCallback(() => {
    setSearch({ ...search, tags: [] });
  }, [search]);

  const debouncedSearch = useDebounce(search, 200);
  useUpdateEffect(() => {
    if (!_isEqual(debouncedSearch, locationSearch)) {
      submitSearch(debouncedSearch, { keepFocus: true, replace: true });
    }
  }, [debouncedSearch]);

  return (
    <div className={classes.searchContainer}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.searchWrapper}>
          <div
            className={clsx(classes.search, {
              [classes.expanded]: expanded,
            })}
            onClickCapture={handleClickIn}
          >
            <div className={classes.inputWrapper}>
              {!tagsOpen ? (
                <>
                  {search.tags && search.tags.length > 0 && (
                    <div
                      className={clsx(classes.inputTags, {
                        multi: search.tags.length > 1,
                      })}
                    >
                      <TagChip
                        onRemove={handleDeselectAllTags}
                        tags={search.tags}
                      />
                    </div>
                  )}
                  <InputBase
                    inputRef={refInput}
                    placeholder="Rechercher"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleSearchInputChange}
                    onKeyPress={handleSearchInputKeyPress}
                    value={search.terms || ''}
                  />
                </>
              ) : (
                <Typography
                  variant="body2"
                  color="inherit"
                  classes={{ root: classes.inputTagsRecap }}
                >
                  {search.tags.length} thème{search.tags.length > 1 ? 's' : ''}{' '}
                  séléctionné{search.tags.length > 1 ? 's' : ''}
                </Typography>
              )}
              <IconButton
                className={clsx(classes.unfoldIcon, { flip: tagsOpen })}
                color="primary"
                onClick={handleToggleTags}
              >
                <i className="sncf-icons-arrow-down sncf-icons-size-18px" />
              </IconButton>
              {popoverAnchor && (
                <Popover
                  open={tagsOpen}
                  anchorEl={popoverAnchor}
                  onClose={handleCloseTags}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  TransitionComponent={Fade}
                  disablePortal
                  marginThreshold={0}
                  elevation={0}
                  PaperProps={{
                    square: true,
                    classes: { root: classes.tagsPaper },
                    style: {
                      height: `calc(100vh - ${popoverAnchor.clientHeight}px)`,
                      willChange: 'transform',
                    },
                  }}
                >
                  <IconButton
                    className={classes.closeTags}
                    color="default"
                    onClick={handleCloseTags}
                  >
                    <i className="sncf-icons-close sncf-icons-size-20px" />
                  </IconButton>
                  <TagsHierarchy
                    selected={search.tags}
                    onSelect={handleSelectTag}
                    onDeselect={handleDeselectTag}
                    filteredTag={filteredTag}
                  />
                </Popover>
              )}
            </div>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={clsx(classes.searchButton, {
                'search-open': expanded,
              })}
              onClick={handleClickSubmitSearch}
            >
              Ok
            </Button>
          </div>
          <IconButton
            className={classes.searchToggler}
            color={contrastColor}
            onClick={handleClickIn}
            classes={{
              root: clsx({ [classes.unfoldSearchHidden]: expanded }),
            }}
          >
            <i className="sncf-icons-search sncf-icons-size-20px" />
          </IconButton>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default React.memo(
  Searchbar,
  (prevProps: SearchbarProps, nextProps: SearchbarProps) => {
    return (
      prevProps.contrastColor === nextProps.contrastColor &&
      prevProps.popoverAnchor === nextProps.popoverAnchor
    );
  },
);
