import React from 'react';
import clsx from 'clsx';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from '../../types/styles';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    resetButton: {
      background: 'none',
      border: 0,
    },
    arrow: {
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      zIndex: 1,
      '&::before': {
        color: theme.palette.primary.main,
        opacity: 0.45,
        transition: theme.transitions.create('opacity'),
      },
      '&:hover': {
        '&::before': {
          opacity: 1,
        },
      },
      '&:focus': {
        '&::before': {
          opacity: 0.45,
        },
      },
      opacity: 1,
      transition: theme.transitions.create('opacity'),
    },
    arrowPrev: {
      left: 0,
    },
    arrowNext: {
      right: 0,
    },
    showOnHover: {
      zIndex: -1,
      opacity: 0,
    },
    hide: {
      zIndex: '-1 !important' as any, // Do not stay over the slide
      opacity: '0 !important',
    },
  }),
);

type ArrowProps = {
  direction: 'left' | 'right';
  hideInactive?: boolean;
  alwaysVisible?: boolean;
  classes?: Classes;
  // react-slick arrow props
  className?: string;
  onClick?: () => void;
};
const Arrow = React.forwardRef<HTMLButtonElement, ArrowProps>(
  (
    {
      direction,
      hideInactive = false,
      alwaysVisible = false,
      classes: customClasses,
      className = '',
      onClick = () => {},
    }: ArrowProps,
    ref,
  ) => {
    const classes = useStyles();
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        ref={ref}
        className={clsx(
          classes.resetButton,
          className,
          classes.arrow,
          'sncf-icons-size-50px',
          {
            'sncf-icons-arrow-prev': direction === 'left',
            [classes.arrowPrev]: direction === 'left',
            'sncf-icons-arrow-next': direction === 'right',
            [classes.arrowNext]: direction === 'right',
            [classes.hide]:
              hideInactive && className.includes('slick-disabled'),
            [classes.showOnHover]: !alwaysVisible,
          },
          customClasses?.root,
        )}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={-1}
        type="button"
      />
    );
  },
);

type ArrowDirectionProps = Omit<ArrowProps, 'direction'>;
/* eslint-disable react/jsx-props-no-spreading */
export const ArrowPrev = React.forwardRef<
  HTMLButtonElement,
  ArrowDirectionProps
>((props: ArrowDirectionProps, ref) => (
  <Arrow ref={ref} {...props} direction="left" />
));
export const ArrowNext = React.forwardRef<
  HTMLButtonElement,
  ArrowDirectionProps
>((props: ArrowDirectionProps, ref) => (
  <Arrow ref={ref} {...props} direction="right" />
));
