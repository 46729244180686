import React, { useCallback, useMemo } from 'react';
import PubSub from 'pubsub-js';
import clsx from 'clsx';
import Color from 'color';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory, useLocation } from 'react-router-dom';

import { Tag } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { useIsScrolledTop } from '../../hooks/use-is-scrolled-top';

import navigation, {
  isList,
  isSvgLink,
  isTagButton,
  isTagsButton,
} from '../../customization/navigation';
import { getListUrl } from '../../customization/list';

import { ClassName } from '../../types/styles';
import { OPEN_SEARCH_TAGS } from '../Searchbar/Searchbar';

const useStyles = makeStyles<
  Theme,
  { onHomePage: boolean; isScrolledTop: boolean }
>(theme => {
  const paddingVertical = 6;
  const svgPaddingVertical = 4;
  const svgCalculatedExtraHeight = paddingVertical * 2 - svgPaddingVertical * 2;
  const navbarHeight = +(theme.mixins.navbar.height ?? 0);
  return createStyles({
    root: {
      zIndex: 1,
      backgroundColor: ({ onHomePage }) =>
        Color(theme.palette.background.paper)
          .alpha(onHomePage ? 0.9 : 1)
          .toString(),
      ...theme.mixins.navbar,
      opacity: ({ isScrolledTop }) => (isScrolledTop ? 1 : 0.3),
      transition: theme.transitions.create('opacity'),
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
    },
    tabsRoot: {
      minHeight: 0,
      height: navbarHeight,
      color: theme.palette.text.contrastText, // For scroll buttons
    },
    tabRoot: {
      display: 'block',
      minWidth: 0,
      minHeight: 0,
      maxWidth: 'unset',
      height: navbarHeight,
      color: theme.palette.text.contrastText,
      padding: `${paddingVertical}px 12px`,
    },
    tabRootIcon: {
      '& .MuiTab-wrapper > *:first-child': {
        margin: 0,
      },
    },
    tabLabel: {
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.05em',
    },
    tabLabelText: {
      paddingTop: 3, // Center text vertically
    },
    tabLabelSvg: {
      height: `calc(100% + ${svgCalculatedExtraHeight}px)`,
      display: 'inline-block',
      margin: `-${svgCalculatedExtraHeight / 2}px 0`,
    },
    tabLabelIcon: {
      display: 'flex',
      flexFlow: 'row-reverse nowrap',
    },
    tabIcon: {
      color: theme.palette.primary.main,
      padding: theme.spacing(0, 1, 0, 2),
    },
    svg: {
      height: '100%',
    },
    indicator: {
      transform: 'scaleX(0.2) translateY(-5px)',
    },
  });
});

type NavigationProps = {
  isOnHomePage?: boolean;
  className?: ClassName;
};

const Navigation: React.FC<NavigationProps> = ({
  isOnHomePage = false,
  className = '',
}: NavigationProps) => {
  const isScrolledTop = useIsScrolledTop();
  const classes = useStyles({ onHomePage: isOnHomePage, isScrolledTop });
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const route = `${pathname}${search}`;
  const pathnameSplited = pathname.split('/').slice(0, 2);
  const simpleRoute = pathnameSplited.join('/');

  const tabValue = useMemo(
    () =>
      navigation.reduce<string | false>((acc, nav) => {
        if (acc !== false) return acc;
        if (isTagsButton(nav) || isTagButton(nav)) return false;

        const url = getListUrl(nav);
        if (
          (nav.exact && route === url) ||
          (!nav.exact && (route.startsWith(url) || url.startsWith(simpleRoute)))
        ) {
          return url;
        }
        return false;
      }, false),
    [route, simpleRoute],
  );

  const handleChangeTab = useCallback(
    (e, newUrl) => {
      push(newUrl);
    },
    [push],
  );

  const handleClickArrowDown = useCallback(() => {
    PubSub.publish(OPEN_SEARCH_TAGS);
  }, []);
  const handleClickArrowDownFiltered = useCallback((tag?: Tag['id']) => {
    PubSub.publish(OPEN_SEARCH_TAGS, { tag });
  }, []);

  const tabsClasses = {
    root: classes.tabRoot,
    wrapper: classes.tabLabel,
  };
  const tabTextClasses = {
    ...tabsClasses,
    wrapper: clsx(tabsClasses.wrapper, classes.tabLabelText),
  };
  const tabSvgClasses = {
    ...tabsClasses,
    wrapper: clsx(tabsClasses.wrapper, classes.tabLabelSvg),
  };
  return (
    <Paper
      classes={{
        root: clsx(classes.root, className),
      }}
      elevation={0}
      square
      component="nav"
    >
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        indicatorColor="primary"
        variant="scrollable"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
        }}
      >
        {navigation.map(nav => {
          const tabProps: Parameters<typeof Tab>['0'] = {};
          tabProps.classes = {};

          if (isList(nav)) {
            tabProps.key = nav.title;
            tabProps.value = getListUrl(nav);
            tabProps.label = nav.shortTitle ?? nav.title;
            tabProps.classes = tabTextClasses;
          } else if (isSvgLink(nav)) {
            tabProps.key = nav.title;
            if (nav.url) tabProps.value = nav.url;
            else if (nav.tags) tabProps.value = getListUrl(nav);
            tabProps.label = (
              <nav.Svg title={nav.title} className={classes.svg} />
            );
            tabProps.classes = tabSvgClasses;
          } else if (isTagsButton(nav)) {
            tabProps.key = nav.title;
            tabProps.label = nav.title;
            tabProps.classes = tabTextClasses;
            tabProps.onClick = handleClickArrowDown;
          } else if (isTagButton(nav)) {
            tabProps.key = nav.title;
            tabProps.label = nav.title;
            tabProps.classes = tabTextClasses;
            tabProps.onClick = handleClickArrowDownFiltered.bind(
              undefined,
              nav.openTag,
            );
          }
          if (nav.icon) {
            tabProps.icon = <span className={classes.tabIcon}>{nav.icon}</span>;
            tabProps.classes = {
              ...tabProps.classes,
              root: clsx(tabProps.classes.root, classes.tabRootIcon),
              wrapper: clsx(tabProps.classes.wrapper, classes.tabLabelIcon),
            };
          }

          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Tab {...tabProps} />;
        })}
      </Tabs>
    </Paper>
  );
};

export default React.memo(Navigation, (prevProps, nextProps) => {
  return (
    prevProps.isOnHomePage === nextProps.isOnHomePage &&
    prevProps.className === nextProps.className
  );
});
