import React, { useCallback } from 'react';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: theme.zIndex.modal,
    },
    backdrop: {},
    root: {
      width: '100%',
      maxWidth: 400,
      height: '100%',
      maxHeight: 300,
      padding: theme.spacing(4),
      backgroundColor: fade(theme.palette.background.paper, 0.9),
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      minWidth: 200,
    },
    error: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

type LogModalProps = {
  onClick?: () => void;
  error?: string | null;
  loading?: boolean;
};

const LogModal: React.FC<LogModalProps> = ({
  onClick,
  error,
  loading = false,
}: LogModalProps) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  if (error) {
    console.warn('Login error', error);
  }

  return (
    <div className={classes.wrapper}>
      <Backdrop className={classes.backdrop} open />
      <div className={classes.root}>
        {loading ? (
          <CircularProgress variant="indeterminate" color="secondary" />
        ) : (
          onClick && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              className={classes.button}
            >
              {error ? <>Me reconnecter</> : <>Me connecter</>}
            </Button>
          )
        )}
        {error && (
          <Typography color="error" className={classes.error}>
            Vous avez été déconnecté, merci de vous reconnecter.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LogModal;
