import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import VideogPlayer from 'videog-player';
import { FRAGMENT_FULL_SUFFIX, QueryNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Video } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import SplashscreenHandler from '../../SplashscreenHandler';
import { getVideoPosterImageogConfig } from '../../utils/poster';
import config from '../../config';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    player: {
      width: '100vw',
      height: '100vh',
    },
  }),
);

type EmbedVideoProps = {
  id: Video['id'];
};

const EmbedVideo: React.FC<EmbedVideoProps> = ({ id }: EmbedVideoProps) => {
  const classes = useStyles();

  const videoResourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
  const {
    query: { name: videoQueryName },
    fragments: { full: videoFragmentFull, name: videoFragmentName },
  } = videoResourceInfos;

  const { data, error, loading } = useQuery<QueryNodes<Video>>(
    gql`
      query {
        ${videoQueryName}(id: "${id}")  {
          ...${videoFragmentName}${FRAGMENT_FULL_SUFFIX}
        }
      }
      ${videoFragmentFull}
    `,
  );
  if (error) {
    console.error(`Error querying video #${id} ${error.message}`);
  }

  const video = data?.[videoQueryName];

  return (
    <>
      <SplashscreenHandler show={!video && loading} />
      {video?.videoKey && (
        <VideogPlayer
          serverUrl={config.formTractionVideoUrl}
          id={video.videoKey}
          presumedDuration={video.duration}
          posterImageogConfig={getVideoPosterImageogConfig(video)}
          posterImageogOptions={{
            resize: {
              width: Math.ceil(600),
              height: Math.ceil(600 / config.thumbRatio),
              fit: 'cover',
            },
          }}
          preload="auto"
          className={classes.player}
          forceMuted={video.mute}
          minBitrate={2000}
        />
      )}
    </>
  );
};

export default EmbedVideo;
