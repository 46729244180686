/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useUpdateEffect(fn: Function, inputs: Array<any>): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
