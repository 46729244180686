import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import Slider, { Settings } from 'react-slick';
import { QueryNodes } from 'phicomas-client';
import { User } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import useDimensions from '../../hooks/use-dimensions';

import { ArrowNext, ArrowPrev } from '../carousel/Arrows';
import ResourceThumb from './ResourceThumb';

import { getUnitsPerRow } from '../../utils/videoList';

import { getListUrl, List } from '../../customization/list';
import config from '../../config';
import { QUERY_ME, QUERY_ME_NAME } from '../../gql/customQueries';
import { Resource } from '../../types/resource';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',

      '& + &': {
        marginTop: theme.spacing(4),
      },
      // '& .slick-slider': {
      //   padding: '1.5em 0.5em 0',
      //   marginTop: '-1.5em',
      // },
      // '&:hover': {
      //   '& .slick-arrow': {
      //     opacity: 1,
      //     zIndex: 1,
      //   },
      //   '& $dots': {
      //     opacity: 1,
      //     zIndex: 1,
      //   },
      // },
    },
    titleWrapper: {
      alignSelf: 'flex-start',
      display: 'inline-block',
      marginBottom: theme.spacing(1),
      zIndex: 1,
    },
    disableLink: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    title: {
      display: 'inline-flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',

      fontSize: '1.2em',

      '&:hover $titleLinkText': {
        padding: '0 0.25em',
      },
      '&:hover $titleLink': {
        maxWidth: '300px',
      },
    },
    titleText: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      textTransform: 'uppercase',
    },
    titleLink: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(1.5),
      color: theme.palette.primary.main,
      lineHeight: 'inherit',
      maxWidth: '1em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('max-width'),
    },
    titleLinkText: {
      flex: '1 1 auto',
      overflow: 'hidden',
      padding: '0',
      fontSize: '0.7em',
      lineHeight: 'inherit',
      textDecoration: 'underline',
      transition: theme.transitions.create('padding'),
    },
    titleLinkArrow: {
      height: '08%',
      fontSize: '1em',
    },
    sliderWrapper: {
      position: 'relative',
      '& .slick-slider': {
        position: 'initial', // For arrow positionning
      },
      '& .slick-list': {
        margin: theme.spacing(-1), // Goes with thumb padding
        maskImage:
          'linear-gradient(to right, transparent, #fff 5%, #fff 95%, transparent)',
        maskSize: '110%',
        maskPosition: 'left',
        transition: theme.transitions.create(['mask-position']),
      },
      '& .slick-track': {
        marginLeft: 'unset', // No center (when only few slides)
        marginRight: 'unset', // No center (when only few slides)
      },
    },
    sliderWrapperFadeEnd: {
      '& .slick-list': {
        maskPosition: 'right',
      },
    },
    sliderWrapperFadeNone: {
      '& .slick-list': {
        maskPosition: 'center',
      },
    },
    sliderWrapperFadeBegining: {
      '& .slick-list': {
        maskPosition: 'left',
      },
    },
    thumb: {
      padding: theme.spacing(1),
    },
    arrow: {
      // top calculated once thumbs are loaded
      transform: 'translateY(-50%)',
    },
  }),
);

type ResourceListCarouselProps = List & {
  resources: Resource[] | undefined;
};

const ResourceListCarousel: React.FC<ResourceListCarouselProps> = ({
  resources,
  ...list
}: ResourceListCarouselProps) => {
  const classes = useStyles();

  const { title } = list;
  const url = getListUrl(list);

  const { data: dataMe } = useQuery<QueryNodes<User>>(QUERY_ME, {
    fetchPolicy: 'cache-only',
  });
  const favorites = useMemo(
    () =>
      dataMe?.[QUERY_ME_NAME]?.videoBookmarks.edges.map(
        ({ node: videoBookmark }) => videoBookmark.id,
      ) ?? [],
    [dataMe],
  );

  const [setRootRef, { width }] = useDimensions<HTMLDivElement>();

  const unitsPerRow = useMemo(
    () => (width ? getUnitsPerRow(width, 'medium', { leaking: true }) : false),
    [width],
  );
  const ceiledUnitsPerRow = useMemo(() => Math.ceil(unitsPerRow || 1), [
    unitsPerRow,
  ]);

  const thumbImageRef = useRef<HTMLDivElement>(null);
  const arrowLeftRef = useRef<HTMLButtonElement>(null);
  const arrowRightRef = useRef<HTMLButtonElement>(null);

  const [lastSlide, setLastSlide] = useState(
    unitsPerRow >= (resources?.length ?? 0),
  );
  useEffect(() => {
    setLastSlide(unitsPerRow >= (resources?.length ?? 0));
  }, [unitsPerRow, resources?.length]);
  const [firstSlide, setFirstSlide] = useState(true);
  const [displayedSlides, setDisplayedSlides] = useState<
    [first: number, last: number]
  >([0, ceiledUnitsPerRow - 1]);
  useEffect(() => {
    setDisplayedSlides([0, ceiledUnitsPerRow - 1]);
  }, [ceiledUnitsPerRow]);

  const onLoaded = useCallback(() => {
    const thumbImageEl = thumbImageRef.current;
    const arrowLeftEl = arrowLeftRef.current;
    const arrowRightEl = arrowRightRef.current;
    if (thumbImageEl && arrowLeftEl && arrowRightEl) {
      let thumbWidth: number;
      if ('getComputedStyle' in window) {
        thumbWidth = +window
          .getComputedStyle(thumbImageEl)
          .getPropertyValue('width')
          .slice(0, -2); // Value is the string "XXXpx"
      } else {
        // IE
        thumbWidth = +(thumbImageEl as any).currentStyle.width.slice(0, -2);
      }

      arrowLeftEl.style.top = `${thumbWidth / config.thumbRatio / 2}px`;
      arrowRightEl.style.top = `${thumbWidth / config.thumbRatio / 2}px`;
    }
  }, []);

  const NB_SLIDES = resources?.length ?? 0;

  const beforeChange = useCallback(
    (slideShownIndex: number, slideShownIndexAfter: any) => {
      if (unitsPerRow) {
        const slideShown = slideShownIndexAfter + 1;
        const extraSlidesShown = unitsPerRow - 1;

        setLastSlide(slideShown + extraSlidesShown >= NB_SLIDES);
        setFirstSlide(slideShownIndexAfter === 0);

        const directionNext = slideShownIndex < slideShownIndexAfter;

        const smartSlideIndex = directionNext
          ? slideShownIndex
          : slideShownIndexAfter;

        setDisplayedSlides([
          Math.max(0, smartSlideIndex),
          Math.min(
            (resources?.length ?? 0) - 1,
            smartSlideIndex + (ceiledUnitsPerRow - 1) + (ceiledUnitsPerRow - 1),
          ),
        ]);
      }
    },
    [unitsPerRow, NB_SLIDES, resources?.length, ceiledUnitsPerRow],
  );

  const settings: Settings = useMemo(() => {
    if (!unitsPerRow) {
      return {};
    }
    return {
      // dots: themeSize !== 'xs',
      // dotsClass: classes.dots,
      arrows: true,
      prevArrow: (
        <ArrowPrev
          ref={arrowLeftRef}
          hideInactive
          alwaysVisible
          classes={{ root: classes.arrow }}
        />
      ),
      nextArrow: (
        <ArrowNext
          ref={arrowRightRef}
          hideInactive
          alwaysVisible
          classes={{ root: classes.arrow }}
        />
      ),
      draggable: false,
      swipe: true,
      autoplay: false,
      speed: 300,
      initialSlide: 0,
      slidesToShow: unitsPerRow,
      slidesToScroll: Math.floor(unitsPerRow),
      infinite: false,
      onInit: onLoaded,
      beforeChange,
    };
  }, [unitsPerRow, beforeChange, onLoaded, classes.arrow]);

  const haveLink = useMemo(() => typeof url !== 'undefined', [url]);

  return (
    <div className={classes.root} ref={setRootRef}>
      {title && (
        <div className={classes.title}>
          <Link
            to={url || '#'}
            className={clsx(classes.titleWrapper, {
              [classes.disableLink]: !haveLink,
            })}
          >
            <div className={classes.title}>
              <Typography
                variant="h5"
                color="inherit"
                classes={{ root: classes.titleText }}
              >
                {title}
              </Typography>
              {haveLink && (
                <div className={classes.titleLink}>
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    classes={{ root: classes.titleLinkText }}
                  >
                    Voir tout
                  </Typography>
                  <i
                    className={clsx(
                      'sncf-icons-arrow-next',
                      classes.titleLinkArrow,
                    )}
                  />
                </div>
              )}
            </div>
          </Link>
        </div>
      )}
      {resources && unitsPerRow && (
        <div
          className={clsx(classes.sliderWrapper, {
            [classes.sliderWrapperFadeEnd]: !lastSlide,
            [classes.sliderWrapperFadeNone]: firstSlide && lastSlide,
            [classes.sliderWrapperFadeBegining]: !firstSlide && lastSlide,
          })}
        >
          <Slider
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...settings}
          >
            {resources.map((resource, index) => (
              <ResourceThumb
                imageRef={index === 0 ? thumbImageRef : null}
                key={resource.id}
                resource={resource}
                isFavorite={favorites.includes(resource.id)}
                className={classes.thumb}
                shown={
                  index >= displayedSlides[0] && index <= displayedSlides[1]
                }
              />
            ))}
            {url && resources.length >= config.sneakpickSize && (
              <ResourceThumb
                linkUrl={url}
                linkTitle="Voir tout"
                className={classes.thumb}
              />
            )}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default memo(ResourceListCarousel, (prevProps, nextProps) => {
  return (
    prevProps.title === nextProps.title &&
    prevProps.url === nextProps.url &&
    prevProps.resources === nextProps.resources
  );
});
