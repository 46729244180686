import React from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation,
  matchPath,
} from 'react-router-dom';

import ContentWrapper from './lib/wrappers/ContentWrapper';

import Header from './components/structure/Header';
import Navigation from './components/structure/Navigation';
import Footer from './components/structure/Footer';

import ResourceListPage from './components/pages/ResourceListPage';
import Video from './components/pages/Video';
import Post from './components/pages/Post';
import All from './components/pages/All';
import Search from './components/pages/Search';
import Legal from './components/pages/Legal';
import Map from './components/pages/Map';

import navigation, {
  home,
  isList,
  isTagsButton,
  isTagButton,
} from './customization/navigation';
import routes from './customization/routes';
import { getListUrl } from './customization/list';
import { VideoTimesContextProvider } from './context/videoTimes';

type AppProps = {
  loading: boolean;
};

const App: React.FC<AppProps> = ({ loading }: AppProps) => {
  const { pathname } = useLocation();
  const homeUrl = getListUrl(home);
  const mapUrl = `${routes.linesMap}/:id?`;
  const isOnHomePage = !!matchPath(pathname, { path: homeUrl });
  const isOnMapPage = !!matchPath(pathname, { path: mapUrl });
  const isOnVideoPage = !!matchPath(pathname, { path: routes.video });

  const subRoute = navigation.map(nav => {
    let url = getListUrl(nav);
    if (nav.param) {
      url += `/:${nav.param}?`;
    }
    if (isList(nav)) {
      return (
        <Route
          key={nav.title}
          path={url}
          component={() =>
            nav.component ? <nav.component /> : <ResourceListPage list={nav} />
          }
        />
      );
    }
    if (isTagsButton(nav) || isTagButton(nav)) {
      return null;
    }
    // return <Route key={nav.url} path={url} component={nav.component} />;
    return null;
  });
  // console.log(subRoute.map(r => r?.props.path));

  return (
    <VideoTimesContextProvider>
      <Header onHomePage={isOnHomePage} loading={loading} />
      <Navigation isOnHomePage={isOnHomePage} />
      <ContentWrapper
        noPaddingTop={isOnHomePage}
        map={isOnMapPage}
        fullscreen={isOnVideoPage}
      >
        <Switch>
          {subRoute}
          <Route path={mapUrl} component={Map} />
          <Route path={routes.all} component={All} />
          <Route path={`${routes.video}/:id`} component={Video} />
          <Route path={`${routes.post}/:id`} component={Post} />
          <Route path={routes.search} component={Search} />
          <Route path="/legal" component={Legal} />
          <Redirect to={routes.home} />
        </Switch>
      </ContentWrapper>
      <Footer />
    </VideoTimesContextProvider>
  );
};

export default React.memo(App);
