import {
  Post,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

export type Resource = Video | Post;

export function resourceIsVideo(resource: Resource): resource is Video {
  return (
    (resource as Video).__typename === 'Video' ||
    typeof (resource as Video).videoKey !== 'undefined'
  );
}
