/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Typography from '@material-ui/core/Typography';

const Legal = () => (
  <div>
    <Typography variant="h3">Mentions légales</Typography>

    <Typography variant="h4">PRÉSENTATION</Typography>

    <Typography variant="body1">
      Les présentes Conditions Générales d'Utilisation ont pour objet de définir
      les conditions d'accès et d'utilisation de « Formtractiontv », site de
      SNCF Voyageurs accessible depuis&nbsp;:
    </Typography>
    <ul>
      <li>
        <Typography variant="body1">
          l'intranet depuis un ordinateur SNCF via
          https://formtractiontv.sncf.fr
        </Typography>
      </li>
      <li>
        <Typography variant="body1">
          l'extranet depuis tout ordinateur, smartphone ou tablette en utilisant
          vos codes de session Windows via https://formtractiontv.sncf.fr
        </Typography>
      </li>
    </ul>
    <Typography variant="body1">(ci-après le « Site »).</Typography>
    <Typography variant="body1">
      Est défini comme Utilisateur du Site (ci-après l'« Utilisateur ») toute
      personne se connectant au Site. Le Site est édité par SNCF Voyageurs,
      Société Anonyme dont le régime résulte des articles L. 2141-1 et suivants
      du Code des transports, immatriculé au Registre du Commerce et des
      Sociétés de Bobigny sous le numéro 519 037 584 / Siège social : 9 rue Jean
      Philippe RAMEAU, 93200 Saint Denis.
    </Typography>
    <Typography variant="body1">(ci-après « SNCF Voyageurs »).</Typography>

    <Typography variant="h4">COMITÉ ÉDITORIAL</Typography>

    <Typography variant="body1">Directeur de publication :</Typography>
    <ul>
      <li>
        <Typography variant="body1">
        Guillaume Keruzoret, Directeur du Centre d’Ingénierie Formation Traction (CIFT)<br />
        SNCF Voyageurs, Direction Industrielle, Direction de la Traction, Direction d’Offre Formation et Prospective (T-FP)
        </Typography>
      </li>
    </ul>

    <Typography variant="h4">
      CONCEPTION, DÉVELOPPEMENT, HÉBERGEMENT ET MAINTENANCE{' '}
    </Typography>

    <Typography variant="body1">
      Le Site a été conçu par l'agence PHILEOG ELEVEN – 34 rue Camille Pelletan,
      92120 Montrouge. SNCF Voyageurs se réserve le droit de modifier ou de
      mettre à jour à tout moment les présentes Conditions Générales
      d'Utilisation et sans accord préalable de l'Utilisateur. Il est donc
      conseillé à l'Utilisateur de se référer régulièrement à la dernière
      version des Conditions Générales d'Utilisation disponible en permanence
      sur le Site.
    </Typography>

    <Typography variant="h4">OBJET</Typography>

    <Typography variant="body1">
      Le Site Formtractiontv est une webTV interne ouverte à tous les agents de
      SNCF Voyageurs qui a pour objet de relayer les actions de formation pour
      faire progresser la sécurité. C'est un site de vidéos en libre-service
      destinées à un usage et une diffusion interne exclusivement.
    </Typography>
    <Typography variant="h4">ACCÈS</Typography>

    <Typography variant="body1">
      L'accès au Site se fait à partir d'un ordinateur, d'un téléphone ou d'un
      terminal nomade connectés à un réseau de télécommunication selon les
      protocoles de communication utilisés sur le réseau Intranet / Extranet.
    </Typography>

    <Typography variant="h4">DISPONIBILITÉ DES SERVICES</Typography>

    <Typography variant="body1">
      Le Site est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force
      majeure ou d'événement hors du contrôle de SNCF Voyageurs.
      <br />
      Toutefois, une interruption pour raison de maintenance technique ou de
      mise à jour nécessaire au bon fonctionnement du site et des matériels
      afférents, ou pour toute autre raison, peut être décidée par SNCF
      Voyageurs, cet accès en tout ou partie du Site pouvant être suspendu sans
      avis préalable de l'Utilisateur. SNCF Voyageurs n'est tenue à aucune
      obligation de résultat concernant l'accessibilité technique au site. SNCF
      Voyageurs n'est en aucun cas responsable des interruptions et des
      conséquences qui peuvent en découler.
    </Typography>

    <Typography variant="h4">NOTICE LÉGALE</Typography>

    <Typography variant="body1">
      Afin de permettre le bon fonctionnement du Site, certaines contraintes
      techniques doivent être respectées par l‘Utilisateur, celles-ci étant
      susceptibles d'être modifiées.
      <br />
      L'Utilisateur est tenu de respecter les indications techniques suivantes
      afin de pouvoir accéder au Site et d'utiliser les services proposés de
      manière optimale. Il s'interdit toute action susceptible d'entraver ou de
      perturber le bon fonctionnement ou l'accessibilité technique du Site. La
      configuration minimum requise pour l'accès au Site est la suivante :
    </Typography>
    <ul>
      <li>
        <Typography variant="body1">Ecran : 1024 X 768 minimum</Typography>
      </li>
      <li>
        <Typography variant="body1">
          Navigateurs minimum : IE 11 avec Windows 8 minimum, Safari, Mozilla
          Firefox ver. 45 minimum, Chrome.
        </Typography>
      </li>
      <li>
        <Typography variant="body1">
          Terminaux nomades : IOS et Android.
        </Typography>
      </li>
    </ul>
    <Typography variant="body1">
      L'Utilisateur s'engage à accéder au Site en utilisant un matériel ne
      contenant pas de virus.
    </Typography>

    <Typography variant="h4">UTILISATION</Typography>

    <Typography variant="body1">
      L'utilisation du Site ainsi que des vidéos est réservée à un usage
      strictement professionnel et interne à SNCF Voyageurs. L'Utilisateur
      s'interdit de diffuser les vidéos en externe.
      <br />
      Pour toute demande d'utilisation et diffusion en externe, contacter :
    </Typography>
    <ul>
      <li>
        <Typography variant="body1">
        Guillaume Keruzoret, Directeur du Centre d’Ingénierie Formation Traction (CIFT)<br />
        SNCF VOYAGEURS - DIRECTION INDUSTRIELLE – DIRECTION DE LA TRACTION - DIRECTION D’OFFRE FORMATION & PROSPECTIVE<br /> 
        268 avenue du Président Wilson - 93210 LA PLAINE SAINT DENIS
        </Typography>
      </li>
    </ul>

    <Typography variant="h4">DONNÉES PERSONNELLES </Typography>

    <Typography variant="body1">
      L'accès au Site se fait grâce aux identifiants habituellement utilisés sur
      le site intranet et extranet de SNCF, sans aucune autre spécificité.
      <br />
      Ces identifiants sont obtenus au travers du Référentiel des Identités
      Digitales de la SNCF avec le nom, le prénom, le CP et l'adresse mail SNCF
      de l'Utilisateur pour permettre la gestion des habilitations au Site dans
      le cadre de l'intérêt légitime du responsable de traitement.
      <br />
      Ce traitement est sous la responsabilité de SNCF Voyageurs, Direction
      Traction.
      <br />
      Les données sont conservées tant que l'Utilisateur à accès au Site, et
      seul l'Utilisateur et les administrateurs y ont accès.
      <br />
      A travers ce Site, SNCF Voyageurs ne collecte aucune autre donnée
      personnelle sur l'Utilisateur.
      <br />
      Pour information, ce Site utilise un dispositif de tracking qui est
      uniquement destiné à analyser l'audience et les parcours de navigation. Il
      est exclusivement réservé à mieux comprendre les usages du site, et ce
      sans relevés nominatifs.
      <br />
      Toute personne concernée par un traitement de données à caractère
      personnel dispose, dans les conditions et limites prévues par la
      règlementation, du droit de demander au responsable du traitement l'accès
      à ses données à caractère personnel, la rectification ou l'effacement de
      celles-ci, la limitation du traitement la concernant, ainsi que du droit
      de définir des directives sur le sort de ses données après sa mort, du
      droit de s'opposer au traitement de ses données, du droit à la
      portabilité.
    </Typography>
    <Typography variant="body1">
      Vous pouvez exercer vos droits en justifiant de votre identité si
      nécessaire et en vous adressant à :<br />
      <a href="mailto:dpo-SAVoyageurs-industriel@sncf.fr">
        dpo-SAVoyageurs-industriel@sncf.fr
      </a>
    </Typography>
    <Typography variant="body1">
      Par ailleurs, si vous considérez que le traitement vous concernant
      constitue une violation de la règlementation, vous disposez du droit
      d'introduire une réclamation auprès de la CNIL ou de l'autorité de
      contrôle de l'Etat où se trouve votre résidence habituelle, votre lieu de
      travail, ou le lieu où la violation aurait été commise.
    </Typography>
  </div>
);

export default Legal;
