import imageog, { ImageogConfig, ImageogOptions } from 'imageog';
import awsExports from 'phicomas-client/dist/projects/sncfFormTraction/apolloClient/aws-exports';
import {
  Asset,
  Post,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { getS3DownloadUrl } from './s3-utils';

import config from '../config';
import { resourceIsVideo } from '../types/resource';

export function getVideoPosterImageogConfig(
  video: Pick<Video, 'videoKey' | 'poster'>,
): ImageogConfig | undefined {
  if (!video.videoKey || !video.poster) {
    return undefined;
  }
  const videoPoster = video.poster.edges?.[0]?.node;
  if (videoPoster && videoPoster.key) {
    const bucket = awsExports.s3?.[config.environment.level]?.bucket;
    if (bucket) {
      return {
        bucket,
        key: videoPoster.key,
        keyIsSplitted: true,
      };
    }
  }
  return {
    bucket: config.s3Bucket,
    key: video.videoKey,
    name: config.defaultPoster,
  };
}

export function getPostPosterImageogConfig(
  post: Pick<Post, 'poster'>,
): ImageogConfig | undefined {
  const videoPoster = post.poster.edges?.[0]?.node;
  if (videoPoster && videoPoster.key) {
    const bucket = awsExports.s3?.[config.environment.level]?.bucket;
    if (bucket) {
      return {
        bucket,
        key: videoPoster.key,
        keyIsSplitted: true,
      };
    }
  }
  return undefined;
}

type GetPosterOptions = {
  width?: number;
  height?: number;
  withoutEnlargement?: boolean;
  portrait?: boolean;
  contain?: boolean;
};
export function getPoster(
  resource: Post | Video,
  {
    width,
    height,
    withoutEnlargement = false,
    portrait = false,
    contain = false,
  }: GetPosterOptions = {},
): string | undefined {
  const imageogConfig = resourceIsVideo(resource)
    ? getVideoPosterImageogConfig(resource)
    : getPostPosterImageogConfig(resource);
  if (!imageogConfig) return undefined;

  const imageogOptions: ImageogOptions = {
    resize: {
      withoutEnlargement,
      fit: contain ? 'inside' : 'cover',
    },
  };
  if (withoutEnlargement) {
    imageogOptions.resize.withoutEnlargement = true;
  }
  if (width) {
    imageogOptions.resize.width = Math.ceil(width);
    if (!height) {
      imageogOptions.resize.height = portrait
        ? Math.ceil(width * config.portraitRatio)
        : Math.ceil(width / config.thumbRatio);
    }
  }
  if (height) {
    imageogOptions.resize.height = Math.ceil(height);
    if (!width) {
      imageogOptions.resize.width = portrait
        ? Math.ceil(height / config.portraitRatio)
        : Math.ceil(height * config.thumbRatio);
    }
  }

  return imageog(imageogConfig, imageogOptions);
}

export async function getPosterPreserveGif(
  resource: Post | Video,
  options: GetPosterOptions = {},
): Promise<string | void> {
  const asset: Asset | undefined = resource.poster.edges[0]?.node;
  if (asset && asset.type === 'image/gif' && asset.key) {
    const link = await getS3DownloadUrl(asset.key, { asset });
    return link;
  }
  return Promise.resolve(getPoster(resource, options));
}
