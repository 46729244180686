import { useState, useLayoutEffect, useCallback } from 'react';
import _debounce from 'lodash/debounce';

export type DimensionObject = Partial<{
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}>;

export type UseDimensionsHook<T extends HTMLElement> = [
  ref: React.Ref<T>,
  dimension: DimensionObject,
  node: T | null,
];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

export function getDimensionObject<T extends HTMLElement>(
  node: T,
): DimensionObject {
  const rect = node.getBoundingClientRect();

  const top = 'x' in rect ? rect.x : (rect as any).top;
  const left = 'y' in rect ? rect.y : (rect as any).left;

  return {
    width: rect.width,
    height: rect.height,
    top,
    left,
    x: top,
    y: left,
    right: rect.right,
    bottom: rect.bottom,
  };
}

function useDimensions<T extends HTMLElement>(
  { liveMeasure = true }: UseDimensionsArgs = {},
  extraDeps: any[] = [],
): UseDimensionsHook<T> {
  const [dimensions, setDimensions] = useState<DimensionObject>({});
  const [node, setNode] = useState<T | null>(null);

  const ref = useCallback(nodeToRef => {
    setNode(nodeToRef);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject<T>(node)),
        );
      measure();

      if (liveMeasure) {
        const debouncedMesure = _debounce(measure, 100);
        window.addEventListener('resize', debouncedMesure);

        return () => {
          window.removeEventListener('resize', debouncedMesure);
        };
      }
    }
    return () => {};
  }, [liveMeasure, node, ...extraDeps]); // eslint-disable-line react-hooks/exhaustive-deps

  return [ref, dimensions, node];
}

export default useDimensions;
