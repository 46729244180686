import color from 'color';
import teal from '@material-ui/core/colors/teal';

const commonColorsBase = {
  lightWhite: 'rgb(239, 239, 239)',
  mediumWhite: 'rgb(247, 247, 247)',
  hardWhite: 'rgb(255, 255, 255)',

  darkGrey: 'rgb(62, 62, 62)',

  lightBlack: 'rgb(44, 44, 44)',
  mediumBlack: 'rgb(22, 22, 22)',
  hardBlack: 'rgb(0, 0, 0)',
};

const commonColors = {
  ...commonColorsBase,
  white: commonColorsBase.mediumWhite,
  black: commonColorsBase.mediumBlack,
};

const specialColorsBase = {
  phileogRaspberry: 'rgb(165, 17, 64)',
  phileogGrey: 'rgb(87, 87, 89)',

  sncfLightGrey: 'rgb(224, 225, 221)',
  sncfDarkGrey: 'rgb(62, 61, 64)', // Mat-TV
  sncfRed: 'rgb(203, 0, 68)', // Mat-TV
  sncfPurple: 'rgb(172, 5, 105)', // Electrification Paris-Troyes
  sncfOrange: 'rgb(239, 92, 41)', // Electrification Paris-Troyes
  sncfOrange2: 'rgb(234, 91, 26)', // Recrutement
  sncfGreen: 'rgb(134, 188, 37)', // Recrutement
  sncfBlue: 'rgb(2, 148, 218)', // Recrutement
  sncfBlue2: 'rgb(68,170,226)', // FormTraction

  sephoraRed: 'rgb(213, 0, 50)',
  sephoraPink: 'rgb(255, 114, 154)',

  pharmaTeal: teal[800],

  yammerBlue: 'rgb(0, 136, 206)',

  axaGis: 'rgb(35,157,161)',
};

const specialColors = {
  ...specialColorsBase,

  phileogPrimary: specialColorsBase.phileogRaspberry,
  phileogSecondary: specialColorsBase.phileogGrey,

  sephoraPrimary: specialColorsBase.sephoraRed,

  pharmaPrimary: specialColorsBase.pharmaTeal,
};

const primaryColor = specialColors.axaGis;
const secondaryColor = commonColors.darkGrey;

const textColorsMixin = (
  base: string,
): {
  primary: string;
  secondary: string;
  disabled: string;
  hint: string;
  contrastText: 'placeholder';
} => ({
  primary: color(base).alpha(0.87).string(),
  secondary: color(base).negate().alpha(0.54).string(),
  hint: color(base).alpha(0.46).string(),
  disabled: color(base).alpha(0.38).string(),
  contrastText: 'placeholder', // Generated based on primaryColor
});

export {
  primaryColor,
  secondaryColor,
  commonColors,
  specialColors,
  textColorsMixin,
};
