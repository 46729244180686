import _findLast from 'lodash/findLast';
import _merge from 'lodash/merge';
import memoizeOne from 'memoize-one';

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { createInsetShadowsFrom } from './utils';
import {
  primaryColor,
  secondaryColor,
  commonColors,
  specialColors,
  textColorsMixin,
} from './colors';

import { DeepPartial } from '../types/utils';

declare global {
  interface Window {
    MUI_THEME: Theme;
  }
}

let fontFamily = 'Helvetica, Arial, sans-serif';

function loadRobotoFont(): void {
  if (typeof window !== 'undefined') {
    const fonts = {
      google: {
        families: ['Roboto:300,400,500', 'Exo 2:600'],
      },
    };
    // For SSR
    // eslint-disable-next-line global-require
    import('webfontloader').then(WebFontLoader => {
      WebFontLoader.load(fonts);
    });
  }
}

function augmentTheme(muiTheme: Theme): void {
  const { contrastText } = muiTheme.palette.augmentColor({
    main: muiTheme.palette.text.primary,
  });
  muiTheme.palette.text.contrastText = contrastText;

  if (!muiTheme.breakpoints.widths) {
    muiTheme.breakpoints.widths = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
  }
  muiTheme.breakpoints.keys.forEach(key => {
    if (!muiTheme.breakpoints.widths[key]) {
      muiTheme.breakpoints.widths[key] = muiTheme.breakpoints.values[key];
    }
  });

  muiTheme.breakpoints.getName = (
    width: number,
    {
      keys = muiTheme.breakpoints.keys,
      values = muiTheme.breakpoints.values,
    } = muiTheme.breakpoints,
  ): Breakpoint => _findLast(keys, key => values[key] <= width) as Breakpoint;

  muiTheme.transitions.duration.instant = 50;
  muiTheme.transitions.duration.long = 400;

  muiTheme.insetShadows = createInsetShadowsFrom(muiTheme.shadows);
}

interface GetThemeOptions {
  withRobotoFont?: boolean;
}

/**
 * Memoized function to set the Material-UI lib theme
 * http://www.material-ui.com/#/customization/themes
 *    Load custom fonts
 *    Apply custom styles (colors mainly)
 * @return  {object}  muiTheme
 * @author Sylvain Pont
 */
const getTheme = memoizeOne(
  (
    theme: DeepPartial<Theme> = {},
    { withRobotoFont = true }: GetThemeOptions = {},
  ) => {
    if (withRobotoFont) {
      // Roboto always here as backup font
      loadRobotoFont();
      fontFamily = `Roboto, ${fontFamily}`;
    }
    if (theme.typography && theme.typography.fontFamily) {
      fontFamily = `${theme.typography.fontFamily}, ${fontFamily}`;
    }

    const muiTheme: Theme = createMuiTheme(
      _merge(
        {
          // breakpoints: {
          //   widths: { // Max widths according to breakpoints
          //     xs: 310,
          //     sm: 508,
          //     md: 696,
          //     lg: 1000,
          //     xl: 1044,
          //   },
          // },
          palette: {
            primary: {
              // light and dark entries, if not set, will be generated
              main: commonColors.hardWhite,
              contrastText: commonColors.mediumBlack,
              primary: primaryColor,
            },
            secondary: {
              // light and dark entries, if not set, will be generated
              main: secondaryColor,
              contrastText: commonColors.mediumBlack,
            },
            common: {
              ...commonColors,
            },
            background: {
              default: primaryColor,
              // light: commonColors.mediumWhite,
              // dark: commonColors.mediumBlack,
            },
            text: textColorsMixin(commonColors.hardWhite),
            specials: {
              ...specialColors,
            },
          },
          spacing: 8,
          shape: {
            borderRadius: 5,
          },
          typography: {
            fontFamily,
            fontSize: 16, // Will be expressed in rem in the body
            // htmlFontSize: 16, // Used to calculate ^ (body font-size in rem)
          },
          // overrides: {
          //   MuiTypography: // Prefer the usage of the custom lib/theme/Typography.js
          // },
        },
        theme,
      ) as ThemeOptions,
    );

    augmentTheme(muiTheme);

    if (process.env.NODE_ENV !== 'production') {
      window.MUI_THEME = muiTheme;
    }

    return muiTheme;
  },
);

function getFontFamily(): string {
  if (!fontFamily) {
    getTheme();
  }
  return fontFamily;
}

export { getTheme, getFontFamily, augmentTheme };
