import ReactGA from 'react-ga';
import { version } from '../../../package.json';

const TRACKING_CODE = 'UA-12368436-15'; // FormTraction TV

export const init = (): void => {
  ReactGA.initialize(TRACKING_CODE, { debug: false, titleCase: false });
  ReactGA.set({
    appName: 'FormTraction TV',
    appVersion: version,
    // dimension1: CONFIG.resaKey,
  });
};
export default init;
