import React, { useCallback, useState, useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Color from 'color';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import {
  AppBar,
  CircularProgress,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';

import Menu from './Menu';
import Searchbar from '../Searchbar/Searchbar';

import { useIsScrolledTop } from '../../hooks/use-is-scrolled-top';

import logo from '../../img/svg/logo.svg';

const useStyles = makeStyles<
  Theme,
  { onHomePage: boolean; isScrolledTop: boolean }
>(theme => {
  const logoSizeDiff = theme.spacing(2);

  const toolbarHeight = +(theme.mixins.toolbar.height ?? 0);

  return createStyles({
    // ##### Toolbar #####
    root: {
      boxShadow: 'none',
      transition: theme.transitions.create(['background-color', 'color']),
      backgroundColor: ({ onHomePage, isScrolledTop }) =>
        Color(theme.palette.background.paper)
          .alpha(onHomePage && !isScrolledTop ? 1 : 0.75)
          .toString(),
    },
    rootFixedPlaceHolder: {
      ...(theme.mixins.toolbar as Record<string, CSSProperties>),
      minHeight: theme.mixins.toolbar.height,
      backgroundColor: 'transparent',
      zIndex: theme.zIndex.appBar - 1,
    },
    hideAllButSearchbar: {
      '& $logoWrapper, & $menuButton': {
        opacity: 0,
      },
    },
    content: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      transition: theme.transitions.create('height'),
    },

    // ##### Logo #####
    logoWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      overflow: 'hidden',

      // On searchbox expand, transition logo hidding:
      maxWidth: 200,
      transform: 'translateX(0)',
      transition: theme.transitions.create([
        'max-width',
        'opacity',
        'transform',
      ]),
    },
    logoWrapperHidden: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 0,
        opacity: '0',
        transform: `translateX(-${theme.spacing(1)}px)`,
        zIndex: -1,
      },
    },

    logo: {
      height: toolbarHeight - logoSizeDiff,
      transition: theme.transitions.create('height'),
    },
    [`${theme.breakpoints.up('xs')}`]: {
      logoNotScrolled: {
        height: toolbarHeight - theme.spacing(1) - logoSizeDiff,
      },
    },
    [theme.breakpoints.up('sm')]: {
      logoNotScrolled: {
        height: toolbarHeight - logoSizeDiff,
      },
    },
    logoScrolled: {
      height: toolbarHeight - theme.spacing(1) - logoSizeDiff,
    },

    // ##### tooltip
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      width: 1000,
      minWidth: '70vw',
      maxWidth: `calc(100vw - ${theme.spacing(2) * 2}px)`,
      border: 0,
      padding: theme.spacing(2),
    },
    tooltipArrow: {
      color: theme.palette.primary.main,
    },
    tooltipTitle: {
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
    tooltipText: {
      color: theme.palette.text.contrastText,
    },

    // ##### Menu #####
    rightSide: {
      display: 'flex',
      alignItems: 'center',
    },

    menuButton: {
      marginRight: -12,
      transition: theme.transitions.create('opacity'),
    },

    loading: {
      padding: theme.spacing(1),
    },
  });
});

type HeaderProps = {
  loading?: boolean;
  onHomePage?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  loading = false,
  onHomePage = false,
}: HeaderProps) => {
  const isScrolledTop = useIsScrolledTop();

  const classes = useStyles({ onHomePage, isScrolledTop });
  const refHeader = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const [hideLogo, setHideLogo] = useState(false);
  const [hideAllButSearchbar, setHideAllButSearchbar] = useState(false);
  const handleSearchbarExpandToggle = useCallback(
    (isExpanded: boolean): void => {
      setHideLogo(isExpanded);
    },
    [],
  );
  const handleSearchbarPopoverToggle = useCallback((isOpen: boolean): void => {
    setHideAllButSearchbar(isOpen);
  }, []);

  return (
    <>
      <div className={classes.rootFixedPlaceHolder} />
      <AppBar
        ref={refHeader}
        position="fixed"
        color={isScrolledTop ? 'default' : 'secondary'}
        classes={{
          root: clsx(classes.root, {
            [classes.hideAllButSearchbar]: hideAllButSearchbar,
          }),
        }}
      >
        <Toolbar
          variant={isScrolledTop ? 'regular' : 'dense'}
          classes={{ root: classes.content }}
        >
          <IconButton
            className={classes.menuButton}
            color="default"
            onClick={handleToggleMenu}
          >
            <i className="sncf-icons-menu-burger sncf-icons-size-20px" />
          </IconButton>
          <Menu open={menuOpen} toggleMenu={handleToggleMenu} />
          <Searchbar
            contrastColor={isScrolledTop ? 'inherit' : 'primary'}
            onExpandToggle={handleSearchbarExpandToggle}
            onPopoverToggle={handleSearchbarPopoverToggle}
            popoverAnchor={refHeader.current}
          />
          <div className={classes.rightSide}>
            {loading && (
              <CircularProgress
                className={classes.loading}
                variant="indeterminate"
                color="secondary"
              />
            )}
            <Tooltip
              arrow
              title={
                <div>
                  <Typography variant="h2" className={classes.tooltipTitle}>
                    Qui sommes nous ?
                  </Typography>
                  <Typography className={classes.tooltipText}>
                    FORM TRACTION TV est la Web TV de la Formation Traction
                    élaborée par le Centre d’Ingénierie Formation Traction
                    (CIFT) avec l’aide de son partenaire « PHILEOG ELEVEN ».
                  </Typography>
                  <Typography className={classes.tooltipText}>
                    Véritable outil de formation pour tous les agents de
                    conduite SNCF, FORM TRACTION TV offre la possibilité de
                    personnaliser son parcours de formation et de maintien de
                    compétences. Grâce aux nouvelles fonctionnalités associées
                    aux vidéos d’études de lignes et à la carte de France
                    interactive des lignes ferroviaires, il est désormais
                    possible d’ajouter ses notes, de zoomer et de visionner la
                    progression de son train en temps réel sur la carte. Le
                    conducteur peut également accéder à une large bibliothèque
                    de films pédagogiques, de tutos sur les engins moteurs et
                    les matériels remorqués et à une base de données complète de
                    la signalisation ferroviaire en lien avec les parcours de
                    formation en e-learning développés sur la plateforme LMS de
                    la Formation Traction. Des archives documentaires et des REX
                    d’événements conduite sont également mises à disposition.
                  </Typography>
                  <Typography className={classes.tooltipText}>
                    Il est également possible de mettre à disposition ses
                    propres vidéos et de visionner les Actualités de la
                    Formation Traction. Une campagne de réalisation d’études de
                    ligne de haute qualité sera menée courant 2021 pour étoffer
                    petit à petit cette carte des vidéos, unique en France,
                    destinée exclusivement aux conducteurs de trains SNCF de
                    toutes les Activités.
                  </Typography>
                </div>
              }
              placement="bottom-end"
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <Link
                to="/home"
                className={clsx(classes.logoWrapper, {
                  [classes.logoWrapperHidden]: hideLogo,
                })}
              >
                <img
                  src={logo}
                  alt="Form-Traction"
                  className={clsx(classes.logo, {
                    [classes.logoNotScrolled]: isScrolledTop,
                    [classes.logoScrolled]: !isScrolledTop,
                  })}
                />
              </Link>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default React.memo(Header, (prevProps, nextProps) => {
  return (
    prevProps.onHomePage === nextProps.onHomePage &&
    prevProps.loading === nextProps.loading
  );
});
