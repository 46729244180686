import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import _throttle from 'lodash/throttle';
import _now from 'lodash/now';

import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  FRAGMENT_FULL_SUFFIX,
  MutationUpdateNode,
  QueryNodes,
} from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import {
  User,
  Video as VideoType,
  MutationUsrUpdateMeArgs,
  MutationUsrViewVideoArgs,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import VideogPlayer, { ForwardedPlayerRef } from 'videog-player';

import { usePrevious } from '../../hooks/use-previous';

import Description from '../Video/Description';
import Comments from '../Video/Comments';

import useDimensions from '../../hooks/use-dimensions';

import config from '../../config';
import { getVideoPosterImageogConfig, getPoster } from '../../utils/poster';
import {
  VideoTimesActionTypes,
  VideoTimesContext,
  TIME,
  COMPLETED,
} from '../../context/videoTimes';
import {
  MUTATION_UPDATE_ME,
  MUTATION_VIEW_VIDEO,
} from '../../gql/customMutations';
import { QUERY_ME, QUERY_ME_NAME } from '../../gql/customQueries';

const MIN_VIEW_TIME = 10000; // ms

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    videoRoot: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    background: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      // /!\ No bottom, height is calculated on didMount
      maskImage:
        'linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.99) 84%, rgba(255,255,255,0.9) 86%, transparent)',

      filter: 'brightness(30%) blur(5px)',
      transform: 'scale(1.3)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    playerBloc: {
      flex: '0 0 auto',
      display: 'flex',
      flexFlow: 'row nowrap',
      '& > *': {
        flex: '1 1 0%',
      },
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column nowrap',
        '& > *': {
          flex: '0 0 auto',
        },
      },
    },
    player: {
      zIndex: 1,
      alignSelf: 'center',
      flexGrow: 1.5,
      margin: 0,
      transition: theme.transitions.create('margin'),
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        margin: '0 10vw',
        flexGrow: 0,
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    description: {
      zIndex: 1,
    },
    comments: {
      flex: '1 1 300px',
      minHeight: '300px',
      overflow: 'hidden',

      position: 'relative',
      marginTop: theme.spacing(4),
    },
  }),
);

const Video: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const apolloClient = useApolloClient();

  const { data: dataMe, error: errorMe, loading: loadingMe } = useQuery<
    QueryNodes<User>
  >(QUERY_ME, { fetchPolicy: 'cache-only' });
  const meReady = !errorMe && !loadingMe;

  const videoResourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
  const {
    query: { name: videoQueryName },
    fragments: { full: videoFragmentFull, name: videoFragmentName },
  } = videoResourceInfos;

  const { data: dataVideo, error: errorVideo } = useQuery<
    QueryNodes<VideoType>
  >(
    gql`
    query {
      ${videoQueryName}(id: "${id}")  {
        ...${videoFragmentName}${FRAGMENT_FULL_SUFFIX}
      }
    }
    ${videoFragmentFull}
  `,
    { fetchPolicy: 'cache-only' },
  );
  if (errorVideo) {
    console.error(`Error querying video #${id} ${errorVideo.message}`);
  }

  const video = dataVideo?.[videoQueryName];
  const prevVideo = usePrevious(video);

  const [videoReady, setVideoReady] = useState(false);
  const [refPlayerBloc, { height: playerBlocHeight }] = useDimensions<
    HTMLDivElement
  >(
    {
      liveMeasure: true,
    },
    [videoReady],
  );
  const handleLoadedMetadata = useCallback(() => {
    setVideoReady(true);
  }, []);
  useEffect(() => {
    if (prevVideo?.id !== video?.id) {
      setVideoReady(false);
    }
  }, [prevVideo?.id, video?.id]);
  const refPlayerBackground = useRef<HTMLDivElement>(null);
  const posterOptions = useMemo(
    () =>
      typeof playerBlocHeight === 'number'
        ? {
            resize: {
              width: Math.ceil(playerBlocHeight * config.thumbRatio),
              height: Math.ceil(playerBlocHeight),
              fit: 'cover',
            },
          }
        : null,
    [playerBlocHeight],
  );

  const posterUrl = useMemo(() => {
    if (!video) return '';
    return getPoster(video, { width: 960 });
  }, [video]);
  useLayoutEffect(() => {
    if (playerBlocHeight && refPlayerBackground.current) {
      refPlayerBackground.current.style.height = `${
        +(theme.mixins.toolbar.height ?? 0) +
        +(theme.mixins.navbar.height ?? 0) +
        (theme.shape.topPadding ?? 0) +
        playerBlocHeight
      }px`;
    }
  }, [playerBlocHeight, theme]);

  const refPlayer = useRef<ForwardedPlayerRef>(null);

  const [videoDuration, setVideoDuration] = useState<number>();
  useEffect(() => {
    const player = refPlayer.current;
    if (videoReady && player) {
      setVideoDuration(player.getDuration());
    }
  }, [videoReady]);
  const minViewTime = useMemo(() => {
    return videoDuration
      ? Math.min(MIN_VIEW_TIME, (videoDuration * 1000) / 2) // Viewing half of it makes it viewed -> If a video is really short, MIN_VIEW_TIME might never be reached
      : MIN_VIEW_TIME;
  }, [videoDuration]);

  /* Viewed status */
  const [viewed, setViewed] = useState<boolean>();
  useEffect(() => {
    if (typeof viewed === 'undefined' && meReady) {
      const me = dataMe?.[QUERY_ME_NAME];
      if (me) {
        apolloClient
          .query<{ [k: string]: { userViews: { edges: Array<any> } } }>({
            query: gql`
              query($ID: ID!) {
                ${videoQueryName}(id: "${id}")  {
                  id
                  userViews(where: { id: $ID }) @connection(key: "userViews", filter: ["where"] ) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              ID: me.id,
            },
          })
          .then(videoViewedInfo => {
            setViewed(
              videoViewedInfo.data[videoQueryName].userViews.edges.length > 0,
            );
          });
      }
    }
  }, [apolloClient, dataMe, id, meReady, videoQueryName, viewed]);
  const viewedStartTime = useRef<number | null>(null);
  const viewedCumulTime = useRef(0);
  const checkingVideoViewedMinTime = useRef<NodeJS.Timeout>();
  const checkVideoViewedMinTime = useCallback(() => {
    if (viewed === false && typeof videoDuration !== 'undefined') {
      const currentViewedTimeMs = viewedStartTime.current
        ? _now() - viewedStartTime.current
        : 0;
      if (viewedCumulTime.current + currentViewedTimeMs >= minViewTime) {
        setViewed(true);
        if (checkingVideoViewedMinTime.current) {
          clearInterval(checkingVideoViewedMinTime.current);
        }
      }
    } else if (checkingVideoViewedMinTime.current) {
      clearInterval(checkingVideoViewedMinTime.current);
    }
  }, [minViewTime, videoDuration, viewed]);
  const checkVideoViewedOnPlay = useCallback(() => {
    if (viewed === false) {
      viewedStartTime.current = _now();
      checkingVideoViewedMinTime.current = setInterval(
        checkVideoViewedMinTime,
        1000,
      );
    }
  }, [checkVideoViewedMinTime, viewed]);
  // Careful: this get called on video load
  const checkVideoViewedOnPause = useCallback(() => {
    if (viewed === false && viewedStartTime.current) {
      if (checkingVideoViewedMinTime.current) {
        clearInterval(checkingVideoViewedMinTime.current);
      }
      viewedCumulTime.current =
        viewedCumulTime.current + _now() - viewedStartTime.current;
      viewedStartTime.current = null;
      checkVideoViewedMinTime();
    }
  }, [checkVideoViewedMinTime, viewed]);

  /* Play / pause handlers */
  const handlePlay = useCallback(() => {
    checkVideoViewedOnPlay();
  }, [checkVideoViewedOnPlay]);
  const handlePause = useCallback(() => {
    checkVideoViewedOnPause();
  }, [checkVideoViewedOnPause]);

  /* videoTimes */
  const [videoTimesRaw, dispatchVideoTimes] = useContext(VideoTimesContext);
  const [videoTimes, setVideoTimes] = useState<typeof videoTimesRaw>();
  useEffect(() => {
    if (
      (!videoTimes || prevVideo?.id !== video?.id) &&
      !videoTimesRaw.loading
    ) {
      setVideoTimes(videoTimesRaw);
    }
  }, [prevVideo?.id, video?.id, videoTimesRaw, videoTimes]);

  /* Set player time from videoTimes */
  useEffect(() => {
    // prevId must equal id because of setVideoReady(false) on change
    if (prevVideo?.id === video?.id && video?.id && videoReady && videoTimes) {
      const player = refPlayer.current;
      const foundVideoTime = videoTimes.videos[video.id]?.[TIME];
      const videoCompleted = videoTimes.videos[video.id]?.[COMPLETED];
      if (foundVideoTime && player) {
        if (!videoCompleted) {
          player.setCurrentTime(foundVideoTime);
        }
      }
    }
  }, [prevVideo?.id, video?.id, videoReady, videoTimes]);

  /* Save time as videoTime localstorage */
  const saveVideoTime = useMemo(
    () =>
      _throttle((time: number) => {
        if (video?.id) {
          dispatchVideoTimes({
            type: VideoTimesActionTypes.SET,
            payload: {
              id: video.id,
              [TIME]: time,
            },
          });
        }
      }, 1000),
    [dispatchVideoTimes, video?.id],
  );
  /* During play (and on mount if videoTime was found),
   * save currentTime as videoTime + Update geosync */
  const handleTimeUpdate = useCallback(
    (event?: React.SyntheticEvent<HTMLVideoElement, Event> | undefined) => {
      if (event) {
        saveVideoTime(event.currentTarget.currentTime);
      }
    },
    [saveVideoTime],
  );
  /** At the end of the video, flush throttling of saveVideoTime + Save it as completed */
  const handleVideoEnded = useCallback(() => {
    saveVideoTime.flush();
    if (video?.id) {
      setViewed(true); // If user goes straight to the end he wont see the video for enough time, but make it viewed anyway
      dispatchVideoTimes({
        type: VideoTimesActionTypes.COMPLETED,
        payload: {
          id: video.id,
        },
      });
    }
  }, [dispatchVideoTimes, saveVideoTime, video?.id]);

  // Update user/video connection on views + update viewCount on video
  const [updateMe] = useMutation<
    MutationUpdateNode<User>,
    MutationUsrUpdateMeArgs
  >(MUTATION_UPDATE_ME.mutation);
  const [viewVideo] = useMutation<
    MutationUpdateNode<VideoType>,
    MutationUsrViewVideoArgs
  >(MUTATION_VIEW_VIDEO.mutation);
  const previousViewed = usePrevious(viewed);
  useEffect(() => {
    if (video && previousViewed === false && viewed) {
      const me = dataMe?.[QUERY_ME_NAME];
      if (meReady && me) {
        const updateMeData = { videoViews: { connect: [video.id] } };
        updateMe({
          variables: { data: updateMeData },
          update: MUTATION_UPDATE_ME.update(apolloClient),
          optimisticResponse: MUTATION_UPDATE_ME.optimisticResponse(
            me,
            updateMeData,
            { [video.id]: video },
          ),
        });
        viewVideo({
          variables: { id: video.id },
          update: MUTATION_VIEW_VIDEO.update(apolloClient),
        });
      }
    }
  }, [
    previousViewed,
    viewed,
    video,
    dataMe,
    meReady,
    updateMe,
    apolloClient,
    viewVideo,
  ]);

  if (!video) {
    return null;
  }

  const posterImageogConfig = getVideoPosterImageogConfig(video);

  return (
    <div className={classes.videoRoot}>
      <div ref={refPlayerBloc} className={classes.playerBloc}>
        {posterUrl && (
          <div
            className={classes.background}
            ref={refPlayerBackground}
            style={{
              backgroundImage: `url("${posterUrl}")`,
            }}
          />
        )}
        {video.videoKey && (
          <>
            <VideogPlayer
              ref={refPlayer}
              serverUrl={config.formTractionVideoUrl}
              id={video.videoKey}
              presumedDuration={video.duration}
              posterImageogConfig={posterImageogConfig}
              posterImageogOptions={posterOptions}
              preload="auto"
              className={classes.player}
              onLoadedMetadata={handleLoadedMetadata}
              onPlay={handlePlay}
              onPause={handlePause}
              onTimeUpdate={handleTimeUpdate}
              onVideoEnded={handleVideoEnded}
              forceMuted={video.mute}
              minBitrate={2000}
              debug
            />
            <Description resource={video} className={classes.description} />
          </>
        )}
      </div>
      <Comments
        video={video}
        refPlayer={refPlayer}
        className={classes.comments}
      />
    </div>
  );
};

export default React.memo(Video);
