/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';

import { ID, PageInfo } from 'phicomas-client';
import { usePrevious } from '../../hooks/use-previous';
import { QUERY_USR_COMMENTS } from '../../gql/customQueries';

type Comment = {
  id: ID;
  text: string;
  time: number;
  video: {
    edges: Array<{
      node: {
        id: ID;
      };
    }>;
  };
};
type CommentsResult = {
  [k in 'usrMe']: {
    id: ID;
    comments: {
      edges: Array<{
        node: Comment;
      }>;
      pageInfo: PageInfo;
    };
  };
};

type UseCommentsOptions = { videoId: string };
type UseCommentsResult = {
  comments: Array<Comment> | undefined;
  /** Ready once some data are available */
  ready: boolean;
  /** Loaded once full data is available */
  loaded: boolean;
};

const BATCH_NB = 100;

export function useComments({
  videoId,
}: UseCommentsOptions): UseCommentsResult {
  const [ready, setReady] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { loading, data, fetchMore: fetchMoreRaw } = useQuery<CommentsResult>(
    QUERY_USR_COMMENTS,
    {
      variables: { first: BATCH_NB },
      errorPolicy: 'all',
      fetchPolicy: 'cache-first',
    },
  );
  const prevLoading = usePrevious(loading);

  const fetchMore = useCallback(
    (endCursor: string) =>
      fetchMoreRaw({
        variables: { first: BATCH_NB, after: endCursor },
        updateQuery: (prev, { fetchMoreResult: next }) => ({
          ...(prev ?? {}),
          usrMe: {
            ...(prev?.usrMe ?? {}),
            comments: {
              ...(prev?.usrMe.comments ?? {}),
              edges: [
                ...(prev?.usrMe.comments.edges ?? []),
                ...(next?.usrMe.comments.edges ?? []),
              ],
              pageInfo: next?.usrMe.comments.pageInfo ?? {
                hasNextPage: false,
                hasPreviousPage: false,
              },
            },
          },
        }),
      }),
    [fetchMoreRaw],
  );

  const checkForMore = useCallback(
    ({
      data: moreData,
    }: {
      data: ApolloQueryResult<CommentsResult>['data'];
    }) => {
      const { hasNextPage, endCursor } =
        moreData?.usrMe.comments.pageInfo ?? {};
      if (moreData && hasNextPage && endCursor) {
        fetchMore(endCursor).then(checkForMore);
      } else {
        setLoaded(true);
      }
    },
    [fetchMore],
  );

  useEffect(() => {
    if (prevLoading && !loading && data) {
      setReady(true);
      checkForMore({ data });
    }
  }, [checkForMore, data, loading, prevLoading]);

  return {
    comments: data?.usrMe.comments.edges
      .filter(edge =>
        edge.node.video.edges.some(({ node }) => node.id === videoId),
      )
      .map(({ node }) => node),
    ready,
    loaded,
  };
}
