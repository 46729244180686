import React from 'react';
import { Link } from 'react-router-dom';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.5, 0),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  }),
);

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2" color="inherit">
        <a href="mailto:cellule.management.cift@sncf.fr">Contacts</a> -{' '}
        <Link to="/legal">Mentions légales</Link>
      </Typography>
    </div>
  );
};

export default Footer;
