import React from 'react';
import clsx from 'clsx';
import Player from 'videog-player';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import ResourceList from '../ResourceList/ResourceList';

import lists from '../../customization/home';
import config from '../../config';

const useStyles = makeStyles<Theme>(theme => {
  const heroHeight = 45;
  return createStyles({
    hero: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: `${heroHeight}vh`,
      [theme.breakpoints.down('xs')]: {
        height: `calc(${heroHeight}vh - ${+(
          theme.mixins.navbar.height ?? 0
        )}px)`,
      },

      '@supports (-webkit-mask-image: -webkit-linear-gradient(right, transparent, #000)) or (mask-image: linear-gradient(right, transparent, #000))': {
        height: `calc(${heroHeight}vh + 70px)`,
        [theme.breakpoints.down('xs')]: {
          height: `calc(${heroHeight}vh + 70px - ${+(
            theme.mixins.navbar.height ?? 0
          )}px)`,
        },
        maskImage:
          'linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0.99) 75%,rgba(255,255,255,0.9) 80%, transparent)',
      },
    },
    heroPlaceholder: {
      height: `calc(${heroHeight}vh -
        ${+(theme.mixins.toolbar.height ?? 0)}px -
        ${+(theme.mixins.navbar.height ?? 0)}px)`,
      marginBottom: theme.shape.topPadding ?? 0,
    },
  });
});

const Home: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.hero)}>
        <Player
          id="a4d016dcaa28b53346d3920d1cfeeb521d7ab145"
          serverUrl={config.formTractionVideoUrl}
          asCover
          asCoverVertical="bottom"
          preload="auto"
          minBitrate={4000}
        />
      </div>
      <div className={clsx(classes.heroPlaceholder)} />
      {lists.map(section => {
        return <ResourceList key={section.title} list={section} sneakPeak />;
      })}
    </>
  );
};

export default React.memo(Home);
