import routes from './routes';

import { Order, Filter } from './filters';
import { stringifySearch } from '../utils/search';

export type List = Partial<Filter> & {
  title: string;
  shortTitle?: string;
  url?: string;
  emptyListMessage?: string;
  lastUnfinished?: boolean;
  favorites?: boolean;
};

export function getListUrl({ url, tags, terms, orderBy, order }: List): string {
  if (url) return url;

  return `${routes.search}?${stringifySearch({ tags, terms, orderBy, order })}`;
}

export const continueWatching: List = {
  title: 'Mes vidéos en cours',
  shortTitle: 'En cours',
  url: routes.continueWatching,
  emptyListMessage: "Vous n'avez aucune vidéo en cours de visionnage...",
  lastUnfinished: true,
};

export const favorites: List = {
  title: 'Mes favoris',
  url: routes.favorites,
  emptyListMessage: "Vous n'avez aucune vidéo en favoris...",
  favorites: true,
};

export const search: List = {
  title: 'Recherche',
  emptyListMessage: 'Aucun résultat ne correspond à votre recherche...',
};

export const all: List = {
  title: 'Toutes les ressources',
  url: routes.all,
  orderBy: ['createdAt'],
  order: [Order.DESC],
};

export const lineVideos: List = {
  title: 'Vidéos de Ligne',
  tags: ['5fc6684c-0bbb-4404-8be9-f259eec5dd2e'],
};

export const lineStudies: List = {
  title: 'Études de Ligne',
  tags: ['5fc664d4-3a61-4ac2-8a06-a1e4fb5083e6'],
};

export const lastPublished: List = {
  title: 'Les plus récentes',
  orderBy: ['createdAt'],
  order: [Order.DESC],
};
