import React, { useCallback } from 'react';
import clsx from 'clsx';

import { useApolloClient, useMutation } from '@apollo/client';

import { IconButton } from '@material-ui/core';

import { MutationDeleteNode } from 'phicomas-client';
import { Comment } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { ReactComponent as Delete } from '../../../img/svg/delete.svg';

import { Classes, ClassName } from '../../../types/styles';
import { MUTATION_DELETE_COMMENT } from '../../../gql/customMutations';

type DeleteCommentProps = {
  id: Comment['id'];
  disabled?: boolean;
  deleting: (id: Comment['id']) => void;
  deleted: (id: Comment['id']) => void;
  className?: ClassName;
  classes?: Classes;
};

const DeleteComment: React.FC<DeleteCommentProps> = ({
  id,
  disabled,
  deleting,
  deleted,
  className,
  classes,
}: DeleteCommentProps) => {
  const apolloClient = useApolloClient();

  const [deleteComment] = useMutation<MutationDeleteNode<Comment>>(
    MUTATION_DELETE_COMMENT.mutation,
  );
  const handleClickDelete = useCallback(() => {
    deleting(id);
    deleteComment({
      variables: { id },
      update: MUTATION_DELETE_COMMENT.update(apolloClient),
    }).finally(() => {
      deleted(id);
    });
  }, [apolloClient, deleteComment, deleted, deleting, id]);

  return (
    <IconButton
      onClick={handleClickDelete}
      className={clsx(className, classes?.root)}
      disabled={disabled}
    >
      <Delete className={classes?.svg} />
    </IconButton>
  );
};

export default React.memo(DeleteComment);
