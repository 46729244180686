import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import _throttle from 'lodash/throttle';
import { FRAGMENT_FULL_SUFFIX, ID, QueryNodes } from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import { Video } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import Player, { ForwardedPlayerRef } from 'videog-player';
import GeolibMap, { useGeoSync } from 'traction-geolib';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import useDimensions from '../../hooks/use-dimensions';
import { useDebounce } from '../../hooks/use-debounce';

import {
  COMPLETED,
  TIME,
  VideoTimesActionTypes,
  VideoTimesContext,
} from '../../context/videoTimes';

import config from '../../config';
import { getVideoPosterImageogConfig } from '../../utils/poster';

const useStyles = makeStyles<Theme, { height: number | undefined }>(theme =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      backgroundColor: `rgba(255, 255, 255, 0.5)`,
      position: 'relative',
    },
    playerWrapper: {
      flex: ({ height }) =>
        height ? `0 1 ${height * config.thumbRatio}px` : '1 1 auto',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      borderRight: `1px solid ${theme.palette.common.black}`,
    },
    player: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    mapWrapper: {
      flex: '1 0 15vw',
      height: '100%',
    },
    map: {
      width: '100%',
      height: '100%',
    },
    error: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      position: 'absolute',
      bottom: '0',
      left: '10vw',
      transform: `translateY(calc(100% + 2vh))`,
      fontSize: '2rem',
      maxWidth: 1000,
      color: theme.palette.text.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      [`@media (max-width: 1300px)`]: {
        left: 0,
      },
    },
    lili: {
      position: 'absolute',
      bottom: '0',
      right: theme.spacing(4),
      transform: `translateY(calc(100% + 2vh))`,
      fontSize: '2rem',
      color: theme.palette.text.contrastText,
    },
  }),
);

type MapLineProps = {
  id: ID;
};

const MapLine: React.FC<MapLineProps> = ({ id }: MapLineProps) => {
  const [refRoot, { height }] = useDimensions<HTMLDivElement>({
    liveMeasure: true,
  });
  const debouncedHeight = useDebounce(height, 500);
  const classes = useStyles({ height });

  const resourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
  const {
    query: { name: queryName },
    fragments: { full: fragmentFull, name: fragmentName },
  } = resourceInfos;

  const { data, loading: loadingVideos, error } = useQuery<
    QueryNodes<Video>
  >(gql`
    query {
      ${queryName}(id: "${id}")  {
        ...${fragmentName}${FRAGMENT_FULL_SUFFIX}
      }
    }
    ${fragmentFull}
  `);
  if (error) {
    console.error(`Error querying video #${id} ${error.message}`);
  }

  const video = data?.[queryName];

  const posterOptions = useMemo(
    () =>
      typeof height === 'number'
        ? {
            resize: {
              width: Math.ceil(height * config.thumbRatio),
              height: Math.ceil(height),
              fit: 'cover',
            },
          }
        : undefined,
    [height],
  );

  const refPlayer = useRef<ForwardedPlayerRef>(null);
  const [videoReady, setVideoReady] = useState(false);
  const handleLoadedMetadata = useCallback(() => {
    setVideoReady(true);
  }, []);

  const {
    geoSync,
    geoState,
    loading: loadingGeo,
    error: errorGeo,
  } = useGeoSync(video?.videoKey ?? '', true);

  // self-destruction function
  const debounce = useRef<() => void | undefined>();
  const lastCurrentTime = useRef<number | undefined>();

  const handleClick = useCallback(
    (t: number) => {
      const player = refPlayer.current;
      if (debounce.current) debounce.current();
      if (geoSync && player) {
        geoSync.setTime(t);
        player.setCurrentTime(t / 1000);
      }
    },
    [geoSync],
  );

  const [videoTimes, dispatchVideoTimes] = useContext(VideoTimesContext);
  /** On mount retrieve localstorage videoTime and set player time */
  useEffect(() => {
    if (video && videoReady) {
      const player = refPlayer.current;
      const foundVideoTime = videoTimes.videos[video.id]?.[TIME];
      const videoCompleted = videoTimes.videos[video.id]?.[COMPLETED];
      if (foundVideoTime && player && !videoCompleted) {
        player.setCurrentTime(foundVideoTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    video,
    videoReady,
    // not videoTimes because we only want the on mount value
  ]);
  /** Save time as videoTime localstorage */
  const saveVideoTime = useMemo(
    () =>
      _throttle((time: number) => {
        if (video) {
          dispatchVideoTimes({
            type: VideoTimesActionTypes.SET,
            payload: {
              id: video.id,
              [TIME]: time,
            },
          });
        }
      }, 1000),
    [dispatchVideoTimes, video],
  );
  /** During play (and on mount if videoTime was found),
   * save currentTime as videoTime + Update geosync */
  const handleTimeUpdate = useCallback(
    (event?: React.SyntheticEvent<HTMLVideoElement, Event> | undefined) => {
      const currentTime =
        (event && event.currentTarget.currentTime) || lastCurrentTime.current;
      if (currentTime === undefined) return;
      if (!debounce.current) {
        lastCurrentTime.current = undefined;
        saveVideoTime(currentTime);
        if (geoSync) {
          geoSync.setTime(currentTime * 1000);
        }
        // and init debounce
        let tO: NodeJS.Timeout | undefined;
        const raf = requestAnimationFrame(() => {
          tO = setTimeout(() => {
            if (debounce.current) {
              debounce.current();
              handleTimeUpdate();
            }
          }, 1000); // 1 sec
        });
        debounce.current = () => {
          cancelAnimationFrame(raf);
          if (tO) clearTimeout(tO);
          debounce.current = undefined;
        };
      } else {
        // save for exit
        lastCurrentTime.current = currentTime;
      }
    },
    [saveVideoTime, geoSync],
  );
  /** At the end of the video, flush throttling of saveVideoTime + Save it as completed */
  const handleVideoEnded = useCallback(() => {
    saveVideoTime.flush();
    if (video) {
      dispatchVideoTimes({
        type: VideoTimesActionTypes.COMPLETED,
        payload: {
          id: video.id,
        },
      });
    }
  }, [dispatchVideoTimes, saveVideoTime, video]);

  const { title, lili } = geoState?.segment ?? {};

  const posterImageogConfig = video
    ? getVideoPosterImageogConfig(video)
    : undefined;

  return (
    <div className={classes.root} ref={refRoot}>
      {!loadingVideos &&
        !loadingGeo &&
        posterOptions &&
        (video && video.videoKey && geoSync && !errorGeo ? (
          <>
            <div className={classes.playerWrapper}>
              <Player
                serverUrl={config.formTractionVideoUrl}
                id={video.videoKey}
                presumedDuration={video.duration}
                preload="auto"
                posterImageogConfig={posterImageogConfig}
                posterImageogOptions={posterOptions}
                className={classes.player}
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                onVideoEnded={handleVideoEnded}
                ref={refPlayer}
                minBitrate={2000}
              />
            </div>
            <div className={classes.mapWrapper}>
              <GeolibMap
                key={debouncedHeight}
                geoSync={geoSync}
                onClick={handleClick}
                className={classes.map}
              />
            </div>
          </>
        ) : (
          <div className={classes.error}>
            <Typography color="error">
              {errorGeo
                ? 'Erreur au chargement de la carte'
                : 'Vidéo ou carte non trouvée...'}
            </Typography>
          </div>
        ))}
      {title && <div className={classes.title}>{title}</div>}
      {lili && <div className={classes.lili}>{lili}</div>}
    </div>
  );
};

export default React.memo(MapLine);
