import gql from 'graphql-tag';
import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import Fuse from 'fuse.js';
import _orderBy from 'lodash/orderBy';

import {
  FRAGMENT_FULL_SUFFIX,
  Level,
  QueryAllNodes,
  QueryNodes,
} from 'phicomas-client';
import projectInfos from 'phicomas-client/dist/projects/sncfFormTraction/projectInfos';
import {
  Post,
  Status,
  User,
  Video,
} from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import ResourceListCarousel from './ResourceListCarousel';
import ResourceListGrid from './ResourceListGrid';

import { List, getListUrl } from '../../customization/list';
import { MAX_PATTERN_LENGTH } from '../../utils/search';

import {
  COMPLETED,
  VideoTimesContext,
  VideoTimeInfos,
  TIME,
  VIEWED_DATE,
} from '../../context/videoTimes';

import { QUERY_ME, QUERY_ME_NAME } from '../../gql/customQueries';
import config from '../../config';
import { Resource } from '../../types/resource';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    emptyListMessage: {
      margin: 'O auto',
      textAlign: 'center',
    },
  }),
);

type DescriptionOwnProps = {
  nbResources: number;
};

interface ResourceListProps<T extends DescriptionOwnProps> {
  list: List;
  sneakPeak?: boolean;
  emptyListMessage?: string;
  Description?: React.FC<T>;
  descriptionProps?: Omit<T, keyof DescriptionOwnProps>;
}

function ResourceList<T extends DescriptionOwnProps>({
  list,
  sneakPeak = false,
  emptyListMessage: emptyListMessageRaw,
  Description: Resume,
  descriptionProps: resumeProps,
}: ResourceListProps<T>): React.ReactElement | null {
  const classes = useStyles();
  const {
    title,
    tags,
    terms,
    order,
    orderBy,
    lastUnfinished,
    favorites,
    emptyListMessage: emptyListMessageList,
  } = list;
  const emptyListMessage =
    emptyListMessageRaw || emptyListMessageList || 'Aucune vidéo trouvée...';
  const url = getListUrl(list);

  const videoResourceInfos = projectInfos.resourcesInfos.sncfFormTractionVideo;
  const {
    query: { allName: videoQueryName },
    fragments: { full: videoFragmentFull, name: videoFragmentName },
  } = videoResourceInfos;
  const { data: videosData, error: videosError } = useQuery<
    QueryAllNodes<Video>
  >(
    gql`
      query {
        ${videoQueryName}  {
          edges {
            node {
              ...${videoFragmentName}${FRAGMENT_FULL_SUFFIX}
            }
          }
        }
      }
      ${videoFragmentFull}
    `,
    { fetchPolicy: 'cache-only' },
  );
  if (videosError) {
    console.error(`Error querying videos ${videosError.message}`);
  }

  const postResourceInfos = projectInfos.resourcesInfos.sncfFormTractionPost;
  const {
    query: { allName: postQueryName },
    fragments: { full: postFragmentFull, name: postFragmentName },
  } = postResourceInfos;
  const { data: postsData, error: postsError } = useQuery<QueryAllNodes<Post>>(
    gql`
      query {
        ${postQueryName}  {
          edges {
            node {
              ...${postFragmentName}${FRAGMENT_FULL_SUFFIX}
            }
          }
        }
      }
      ${postFragmentFull}
    `,
    { fetchPolicy: 'cache-only' },
  );
  if (postsError) {
    console.error(`Error querying posts ${postsError.message}`);
  }

  const { data: dataMe } = useQuery<QueryNodes<User>>(QUERY_ME, {
    fetchPolicy: 'cache-only',
  });

  const favoriteVideosIds = useMemo(
    () =>
      dataMe?.[QUERY_ME_NAME]?.videoBookmarks.edges.map(
        ({ node }) => node.id,
      ) ?? [],
    [dataMe],
  );

  const [{ videos: videoTimesVideos }] = useContext(VideoTimesContext);
  const unfinishedVideosIdsSorted = useMemo(
    () =>
      Object.keys(videoTimesVideos)
        .filter(videoId => {
          const videoTimeInfos = videoTimesVideos[videoId] as VideoTimeInfos;
          return videoTimeInfos[COMPLETED] === false && videoTimeInfos[TIME];
        })
        .sort((aId, bId) => {
          const aTime = (videoTimesVideos[aId] as VideoTimeInfos)[VIEWED_DATE];
          const bTime = (videoTimesVideos[bId] as VideoTimeInfos)[VIEWED_DATE];
          return aTime > bTime ? -1 : aTime < bTime ? 1 : 0; // eslint-disable-line no-nested-ternary
        }),
    [videoTimesVideos],
  );

  const resources = useMemo(() => {
    let resourcesTemp = [
      ...(videosData?.[videoQueryName].edges?.map(({ node: video }) => video) ??
        []),
      ...(postsData?.[postQueryName].edges
        ?.map(({ node: post }) => post)
        .filter(post => post.attachments.edges.length > 0) ?? []),
    ];

    // Both default order + Mix videos and posts
    resourcesTemp = resourcesTemp.sort((a, b) =>
      a.title && b.title
        ? a.title
            .trim()
            .toLowerCase()
            .localeCompare(b.title.trim().toLowerCase(), 'fr-FR', {
              numeric: true,
              sensitivity: 'base',
            })
        : 0,
    );

    if (config.environment.level === Level.PROD) {
      resourcesTemp = resourcesTemp.filter(
        node => node.status === Status.PUBLISHED,
      );
    }

    if (lastUnfinished) {
      resourcesTemp = unfinishedVideosIdsSorted.reduce<typeof resourcesTemp>(
        (acc, id) => {
          const video = resourcesTemp.find(({ id: vId }) => vId === id);
          if (video) {
            acc.push(video);
          }
          return acc;
        },
        [],
      );
    }

    if (favorites) {
      resourcesTemp = resourcesTemp.filter(resource =>
        favoriteVideosIds.includes(resource.id),
      );
    }

    if (tags?.length) {
      resourcesTemp = resourcesTemp.filter(resource =>
        resource.tags.edges.some(tag => tags.includes(tag.node.id)),
      );
    }

    if (terms) {
      const fuseOptions: Fuse.IFuseOptions<Resource> = {
        isCaseSensitive: false,
        minMatchCharLength: 2, // Minimum of 3 chars match
        findAllMatches: true, // Search all results, even if perfect result have been found

        threshold: 0.2, // Precision of the search : 0 perfect match / 1 everything
        // location: 0, // Approximation of where the text should be found
        // distance: 100, // How close the match must be to the fuzzy location
        ignoreLocation: true,

        shouldSort: true,
        includeScore: true,

        keys: [
          { name: 'title', weight: 0.8 },
          { name: 'body', weight: 0.2 },
        ],
      };
      resourcesTemp = new Fuse(resourcesTemp, fuseOptions)
        .search(terms.substring(0, MAX_PATTERN_LENGTH))
        .map(({ item }) => item);
    }

    if (orderBy?.length) {
      resourcesTemp = _orderBy(resourcesTemp, orderBy, order);
    }

    if (sneakPeak) {
      resourcesTemp = resourcesTemp.slice(0, config.sneakpickSize);
    }

    return resourcesTemp;
  }, [
    videosData,
    videoQueryName,
    postsData,
    postQueryName,
    lastUnfinished,
    favorites,
    tags,
    terms,
    orderBy,
    sneakPeak,
    unfinishedVideosIdsSorted,
    favoriteVideosIds,
    order,
  ]);

  /** Sneakpeak display */

  if (sneakPeak) {
    if (resources.length === 0) return null;
    return (
      <ResourceListCarousel title={title} url={url} resources={resources} />
    );
  }

  /** Grid display */

  const rProps = {
    ...resumeProps,
    nbResources: resources.length,
  } as T;

  return (
    <>
      {Resume && (
        <Resume
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rProps}
        />
      )}
      {resources.length === 0 ? (
        <div className={classes.emptyListMessage}>{emptyListMessage}</div>
      ) : (
        <ResourceListGrid resources={resources} />
      )}
    </>
  );
}
ResourceList.defaultProps = {
  sneakPeak: undefined,
};

const typedMemo: <T>(c: T) => T = React.memo;
export default typedMemo(ResourceList);
