import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

// import { Video } from 'phicomas-client/dist/projects/sncfFormTraction/schema';

import {
  Theme,
  makeStyles,
  createStyles,
  fade,
} from '@material-ui/core/styles';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SmartBackground from '../SmartBackground';

import { ClassName, Style } from '../../types/styles';
// import { replacePublicUri } from '../../lib/utils/uri';

import { ReactComponent as SvgMap } from '../../img/svg/map-icon.svg';
import { ReactComponent as SvgMapOff } from '../../img/svg/map-icon-off.svg';
import { ReactComponent as SvgFavorites } from '../../img/svg/favorites.svg';
import { ReactComponent as SvgPlay } from '../../img/svg/play.svg';
import VideoInfo from '../VideoInfo';

import { getPresumedDuration } from '../../utils/video';
import { getPoster } from '../../utils/poster';

import config from '../../config';
import { COMPLETED, TIME, VideoTimesContext } from '../../context/videoTimes';
import routes from '../../customization/routes';
import map from '../../customization/map';
import { Resource, resourceIsVideo } from '../../types/resource';
import { getFontAwesomeIconFromMimeType } from '../../utils/fontawesome';

const useStyles = makeStyles<Theme, { progress?: number }>(theme =>
  createStyles({
    thumbRoot: {
      position: 'relative',
      outline: 'none',
      '&:hover': {
        color: theme.palette.primary.main,

        '& $imagePlay': {
          opacity: 1,
          transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.long ?? 400 - 200,
          }),
          transitionDelay: '200ms',
        },

        '& $image': {
          transform: 'perspective(500px) translate3d(0, 0, 25px)',
          '&::after': {
            opacity: 0.2,
          },
        },

        '& $seeAll::before': {
          opacity: 1,
        },
        '& $seeAllImg': {
          transform: `translateY(-${theme.spacing(1)}px)`,
        },

        '& $seeAllText': {
          transform: `translateY(-${theme.spacing(1)}px)`,
          opacity: 1,
        },
      },
    },
    wrapper: {
      overflow: 'hidden',
      paddingBottom: '1px',
      position: 'relative',
    },

    imageWrapper: {
      position: 'relative',
      padding: `0 0 ${100 / config.thumbRatio}%`, // Sets the image size
      cursor: 'pointer',
      overflow: 'hidden',
      transform: 'perspective(500px) translate3d(0, 0, 0)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.long,
      }),

      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.common.white,
        opacity: 0,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.instant,
        }),
      },
    },
    imagePlay: {
      position: 'absolute', // Needs to be positionned absolutely because of image sizing by padding
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '25%',
      transition: theme.transitions.create('opacity'),
      opacity: 0,
      color: theme.palette.primary.main,
    },

    iconBackground: {
      backgroundColor: theme.palette.background.paper,
    },
    icon: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      width: '100% !important',
      height: '80%',
      color: theme.palette.augmentColor({
        main: theme.palette.primary.main,
      }).dark,
    },

    progress: {
      opacity: 0.7,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: '2px',
      backgroundColor: theme.palette.specials.sncfDarkGrey,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: ({ progress }) => `${progress}%`,
        backgroundColor: theme.palette.specials.sncfRed,
      },
    },
    viewed: {
      position: 'absolute',
      right: 0,
      top: '6px',
      margin: '0 !important',

      padding: '1px 1px',
      // border: `1px solid ${theme.palette.background.paper}`,
      // borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.background.paper, 0.25),

      lineHeight: 1,
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      textShadow: `1px 1px 2px ${fade(theme.palette.text.contrastText, 0.5)}`,
    },

    title: {
      marginTop: theme.spacing(1),
      fontSize: 'inherit',
      lineHeight: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      cursor: 'pointer',

      '@supports (-webkit-line-clamp: 2)': {
        overflow: 'hidden',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        whiteSpace: 'normal',
        height: 'calc(2em + 4px)',
        lineHeight: 1,
      },
    },

    infos: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      margin: theme.spacing(1, -1, 0),

      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },

    seeAll: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primary.contrastText,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
      },
    },
    seeAllImg: {
      fontSize: '3em',
      color: theme.palette.primary.light,
      transition: theme.transitions.create('transform'),
    },
    seeAllText: {
      position: 'absolute',
      bottom: theme.spacing(0),
      color: theme.palette.primary.main,
      opacity: 0,
      transform: `translateY(${theme.spacing(1)}px)`,
      transition: theme.transitions.create(['transform', 'opacity']),
    },
  }),
);

type ResourceThumbProps = {
  resource?: Resource;
  isFavorite?: boolean;
  linkUrl?: string;
  linkTitle?: string;
  className?: ClassName;
  style?: Style;
  imageRef?: React.Ref<HTMLDivElement>;
  shown?: boolean;
};

const ResourceThumb: React.FC<ResourceThumbProps> = ({
  resource,
  isFavorite,
  className = '',
  style = {},
  imageRef,
  shown = true,
  linkUrl,
  linkTitle = '',
  ...reactSlickSlideProps
}: ResourceThumbProps) => {
  const { id, title, tags } = resource || {};

  const hasMap = useMemo(
    () => tags?.edges.some(({ node }) => node.id === map.tagId),
    [tags],
  );

  const posterUrl = useMemo(() => {
    if (!resource || !shown) return undefined;
    return getPoster(resource, {
      width: 309,
      contain: !resourceIsVideo(resource),
    });
  }, [shown, resource]);

  const mimeType = useMemo(() => {
    if (!resource || !shown || resourceIsVideo(resource)) return undefined;
    return resource.attachments.edges[0]?.node.type;
  }, [resource, shown]);

  const [videoTimes] = useContext(VideoTimesContext);

  const percentageViewed = useMemo(() => {
    if (resource && resourceIsVideo(resource) && resource.duration) {
      if (videoTimes.videos[resource.id]?.[COMPLETED]) {
        return COMPLETED;
      }
      const presumedDuration = getPresumedDuration(resource);
      const viewedTime = videoTimes.videos[resource.id]?.[TIME];
      if (viewedTime && presumedDuration > 0) {
        return viewedTime / presumedDuration;
      }
    }
    return undefined;
  }, [resource, videoTimes.videos]);
  const styleProps = useMemo(() => {
    let progress = 0;
    if (percentageViewed) {
      if (percentageViewed === COMPLETED) {
        progress = 100;
      } else {
        progress = percentageViewed * 100;
      }
    }
    return { progress };
  }, [percentageViewed]);

  const classes = useStyles(styleProps);

  if (resource && resourceIsVideo(resource) && !resource.videoKey) {
    return null;
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...reactSlickSlideProps}
      className={clsx(className, classes.thumbRoot)}
      style={{
        ...style,
        lineHeight: 1.5,
      }}
    >
      {resource
        ? shown && (
            <div>
              <Link
                to={`${
                  resourceIsVideo(resource) ? routes.video : routes.post
                }/${id}`}
              >
                {(posterUrl || mimeType) && (
                  <div className={classes.wrapper}>
                    {posterUrl ? (
                      <SmartBackground
                        ref={imageRef}
                        key={`smartBackground-${id}`}
                        src={posterUrl}
                        className={classes.imageWrapper}
                        contained={!resourceIsVideo(resource)}
                      >
                        {resourceIsVideo(resource) && (
                          <SvgPlay className={classes.imagePlay} />
                        )}
                      </SmartBackground>
                    ) : (
                      mimeType && (
                        <div
                          className={clsx(
                            classes.imageWrapper,
                            classes.iconBackground,
                          )}
                        >
                          <FontAwesomeIcon
                            icon={getFontAwesomeIconFromMimeType(mimeType)}
                            className={classes.icon}
                          />
                        </div>
                      )
                    )}
                    {!!percentageViewed && <div className={classes.progress} />}
                    {!!percentageViewed && (
                      <Typography
                        component="div"
                        variant="body2"
                        className={classes.viewed}
                      >
                        Vue
                      </Typography>
                    )}
                  </div>
                )}
                <div>
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    classes={{ root: classes.title }}
                  >
                    {title}
                  </Typography>
                </div>
              </Link>
              <div className={classes.infos}>
                {hasMap && (
                  <VideoInfo
                    Svg={SvgMapOff}
                    SvgOn={SvgMap}
                    to={`${routes.linesMap}/${id}`}
                  />
                )}
                {isFavorite && (
                  <VideoInfo Svg={SvgFavorites} iconPrimaryColored />
                )}
              </div>
            </div>
          )
        : !!linkUrl && (
            <Link to={linkUrl}>
              <div className={classes.wrapper}>
                <div className={classes.imageWrapper}>
                  <div className={classes.seeAll}>
                    <i className={clsx(classes.seeAllImg, 'sncf-icons-add')} />
                    <Typography className={classes.seeAllText}>
                      {linkTitle}
                    </Typography>
                  </div>
                </div>
              </div>
            </Link>
          )}
    </div>
  );
};

export default React.memo(ResourceThumb, (prevProps, nextProps) => {
  return _isEqual(prevProps, nextProps);
});
